import { Box } from "@mui/system";
import React from "react";

type TextSeparatorProps = {};

const TextSeparator: React.FC<TextSeparatorProps> = () => {
	return (
		<Box
			component="div"
			color="text.primary"
			sx={{
				marginLeft: "14px",
				marginRight: "14px",
				borderLeft: "1px solid #ffffff",
				width: "0",
				height: "20px",
			}}
		>
			&nbsp;
		</Box>
	);
};

export default TextSeparator;
