import { Box, Typography } from "@mui/material";
import * as React from "react";
import { ReactComponent as WarningTriangleSvg } from "../../../images/icons/WarningTriangle.svg";
import Texts from "../../../resources/Texts";

type DeleteFileDialogContentProps = { fileName: string };

const DeleteFileDialogContent: React.FC<DeleteFileDialogContentProps> = ({ fileName }) => {
	return (
		<Box display="flex" flexDirection="row">
			<Box width="20px" marginRight="1em">
				<WarningTriangleSvg />
			</Box>

			<Box width="428px">
				<Typography sx={{ marginBottom: "1em" }}>
					{Texts.Dialogs.DeleteFileDialog.WarningContent1}{" "}
					<Box component="span" sx={{ fontWeight: 600 }}>
						{fileName}
					</Box>{" "}
					{Texts.Dialogs.DeleteFileDialog.WarningContent2}
				</Typography>

				<Typography>{Texts.Dialogs.DeleteFileDialog.DeleteConfirmation}</Typography>
			</Box>
		</Box>
	);
};

export default DeleteFileDialogContent;
