import { ThemeOptions } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import DialogsContainer from "../dialogs/DialogsContainer";
import AlertOverlay from "./AlertOverlay";
import AppBar from "./appBar/AppBar";
import SideBar from "./sideBar/SideBar";
import { SideBarState } from "./sideBar/SideBarStore";

type LayoutProps = SideBarState & { children?: React.ReactNode };

const Layout: React.FC<LayoutProps> = ({ children, open }) => {
	const theme: ThemeOptions = useTheme();

	const layoutWidth: string = open ? "calc(100% - 280px)" : "calc(100% - 110px)";

	return (
		<Box display="flex" flex={1} width="calc(100vw - 42px)">
			<SideBar />

			<Box display="flex" flexDirection="column" flex={1} width={layoutWidth}>
				<AppBar />

				<Box
					display="flex"
					flexDirection="column"
					flex={1}
					sx={{
						backgroundColor: "#000000",
						marginLeft: "-42px",
						zIndex: theme.zIndex.drawer + 1,
						borderTopLeftRadius: "20px",
						paddingLeft: "20px",
						paddingTop: "20px",
						paddingRight: "20px",
						paddingBottom: "20px",
						boxSizing: "border-box",
					}}
				>
					{children}
				</Box>
			</Box>

			<DialogsContainer />

			<AlertOverlay />
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return { ...state.sideBar };
};

export default connect(mapStateToProps)(Layout);
