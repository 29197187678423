import React from "react";
import UserManagementHeader from "../../components/userManagement/UserManagementHeader";
import UserManagementTableContainer from "../../components/userManagement/UserManagementTableContainer";

type UserManagementViewProps = {};

const UserManagementView: React.FC<UserManagementViewProps> = () => {
	return (
		<>
			<UserManagementHeader />

			<UserManagementTableContainer />
		</>
	);
};

export default UserManagementView;
