import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource, Method } from "axios";
import { v4 as uuidv4 } from "uuid";
import {
	IAccessControlItem,
	IAccessControlUser,
	IAddNotificationReuqest,
	IAdminDocumentsRequest,
	IAdminTab,
	ICompany,
	IDocumentsRequest,
	IDocumentsResponse,
	IDocumentType,
	IEmailTemplate,
	IFileDownloadData,
	IFileDownloadDataResult,
	IFinalizeFileUploadRequest,
	IKeyword,
	INavSection,
	INotification,
	IUploader,
	IUploadFileRequest,
	IUser,
	IUserApp,
	IUserManagementDataRequest,
	IUserManagementResponse,
	IViewingStatsItem,
} from "../resources/Contracts";

let baseUrl: string;

if (window.location.host === "localhost:3000") {
	baseUrl = "https://localhost:5001";
} else {
	baseUrl = window.location.origin;
}

const axiosInstance = axios.create({
	baseURL: baseUrl,
	withCredentials: true,
	//headers: {
	//	'Access-Control-Allow-Origin': '*',
	//	'Access-Control-Allow-Credentials': true,
	//	'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	//},
});

const chunkSize = 1048576 * 3;

export class Service {
	private static async callApi<TResponse>(
		apiUrl: string,
		method: Method,
		payload?: any,
		onUploadProgress?: (progressEvent: ProgressEvent) => void,
		cancelTokenSource?: CancelTokenSource
	): Promise<TResponse> {
		let response: AxiosResponse<TResponse>;
		const url: string = `/api${apiUrl}`;

		const requestConfig: AxiosRequestConfig = {
			onUploadProgress: onUploadProgress,
			cancelToken: cancelTokenSource?.token,
		};

		switch (method) {
			case "GET":
				response = await axiosInstance.get(url, requestConfig);
				break;

			case "POST":
				response = await axiosInstance.post(url, payload, requestConfig);
				break;

			case "PUT":
				response = await axiosInstance.put(url, payload, requestConfig);
				break;

			case "DELETE":
				response = await axiosInstance.delete(url, requestConfig);
				break;
		}

		return response.data;
	}

	public static async getNavSections(): Promise<INavSection[]> {
		return Service.callApi<INavSection[]>("/Files/getSections", "GET");
	}

	public static async getAdminTabs(): Promise<IAdminTab[]> {
		return Service.callApi<IAdminTab[]>("/admin/Documents/gettabs", "GET");
	}

	public static async getEmailTemplates(): Promise<IEmailTemplate[]> {
		return Service.callApi<IEmailTemplate[]>("/admin/Notifications/gettemplates", "GET");
	}

	public static async getUsers(searchValue: string): Promise<IUploader[]> {
		return Service.callApi<IUploader[]>(
			`/Filters/getusersbyemail?searchTerm=${searchValue}`,
			"GET"
		);
	}

	public static async getCompanies(searchValue: string): Promise<ICompany[]> {
		return Service.callApi<ICompany[]>(
			`/Filters/getcompanies?searchTerm=${searchValue}`,
			"GET"
		);
	}

	public static async getKeywords(): Promise<IKeyword[]> {
		return Service.callApi<IKeyword[]>("/Files/getKeywords", "GET");
	}

	public static async getDocumentTypes(): Promise<IDocumentType[]> {
		return Service.callApi<IDocumentType[]>("/Files/getTypes", "GET");
	}

	public static async getUploaders(searchValue: string): Promise<IUploader[]> {
		return Service.callApi<IUploader[]>(
			`/Filters/getUploaders?searchTerm=${searchValue}`,
			"GET"
		);
    }

	public static async getDocuments(request: IDocumentsRequest): Promise<IDocumentsResponse> {
		return Service.callApi<IDocumentsResponse>("/Files/getList", "POST", request);
	}

	public static async getAdminDocuments(
		request: IAdminDocumentsRequest
	): Promise<IDocumentsResponse> {
		return Service.callApi<IDocumentsResponse>("/admin/Documents/getList", "POST", request);
	}

	public static async getUserManagementData(
		request: IUserManagementDataRequest
	): Promise<IUserManagementResponse> {
		return Service.callApi<IUserManagementResponse>(
			"/UsersManagement/getusermngtlist",
			"POST",
			request
		);
	}

	public static async getUsersByCompanyId(searchValue: string, companyId: number): Promise<IUploader[]> {
		return Service.callApi(`/Filters/GetUsersByCompany?searchTerm=${searchValue}&companyId=${companyId}`, "GET");
    }

	public static async addUser(user: IUploader): Promise<void> {
		return Service.callApi("/UsersManagement/AddUser", "POST", user);
    }

	public static async deleteUser(email: string): Promise<IUserManagementResponse> {
		return Service.callApi<IUserManagementResponse>(
			`/UsersManagement/deleteaccessbyemail/${email}`,
			"GET"
		);
	}

	public static async getCurrentUser(): Promise<IUser> {
		return Service.callApi<IUser>("/auth/islogin", "GET");
	}

	public static async getAppsByUserId(guid: string): Promise<IUserApp[]> {
		return Service.callApi<IUserApp[]>(`/HomeB2C/getappsbyuserid/${guid}`, "GET");
	}

	public static async getFileDownloadLinks(
		uniqueIds: string[],
		action: string
	): Promise<IFileDownloadData[]> {
		return Service.callApi<IFileDownloadData[]>(`/Files/getLinks`, "POST", {
			uniqueIds,
			action,
		});
	}

	public static async getPdfFile(uniqueId: string, action: string): Promise<IFileDownloadDataResult> {
		return Service.callApi<IFileDownloadDataResult>(`/Files/getPdf`, "POST", {
			uniqueId,
			action
        });
	}

	public static async getBytesPdfFile(fileUniqueId: string): Promise<Uint8Array> {
		return Service.callApi<Uint8Array>(`/Files/getBytesPdf/${fileUniqueId}`, "POST");
	}

	public static async getFileAccessList(fileId: number): Promise<IAccessControlItem[]> {
		return Service.callApi<IAccessControlItem[]>(
			`/admin/Access/getfileaccesslist/${fileId}`,
			"GET"
		);
	}

	public static async getViewingStats(fileId: number): Promise<IViewingStatsItem[]> {
		return Service.callApi<IViewingStatsItem[]>(`/Statistics/getstatsbyid/${fileId}`, "GET");
	}

	public static async addFileAccessList(fileId: number, user: IAccessControlUser): Promise<void> {
		await Service.callApi("/admin/Access/addfileaccess", "POST", {
			fileId: fileId,
			user: user,
		});
	}

	public static async finalizeFileUpload(request: IFinalizeFileUploadRequest): Promise<void> {
		await Service.callApi("/Files/fileUploadComplete", "POST", request);
	}

	public static async uploadFileByChunks(
		request: IUploadFileRequest,
		onSuccess: () => void,
		onError: () => void
	): Promise<void> {
		const totalCount: number =
			request.file.size % chunkSize === 0
				? request.file.size / chunkSize
				: Math.floor(request.file.size / chunkSize) + 1;
		const fileUuid = uuidv4();
		const fileName = request.file.name;

		let counter: number = 1;
		let startOfChunk: number = 0;
		let endOfChunk: number = chunkSize;

		async function uploadChunk() {
			try {
				const chunk = request.file.slice(startOfChunk, endOfChunk);
				const response = await axiosInstance.put("/api/Files/uploadFileChunk", chunk, {
					params: {
						id: counter,
						fileUniqueId: fileUuid,
						fileName: fileName,
					},
					headers: { "Content-Type": "application/json" },
				});

				if (response.data.isSuccess) {
					if (counter === totalCount) {
						const finalizeRequest: IFinalizeFileUploadRequest = {
							fileUnique: fileUuid,
							publishedByEmail: request.publishedByEmail,
							publishedByName: request.publishedByName,
							sectionId: request.sectionId,
							title: request.title,
							keywords: request.keywords,
							permitted: request.permitted,
							fileName: request.file.name,
						};

						await Service.finalizeFileUpload(finalizeRequest);
					} else {
						startOfChunk = endOfChunk;
						endOfChunk = endOfChunk + chunkSize;
						counter += 1;

						await uploadChunk();
					}
				}
			} catch (error) {
				onError();
			}
		}

		await uploadChunk();

		onSuccess();
	}

	public static async getNotifications(userEmail: string): Promise<INotification[]> {
		return Service.callApi<INotification[]>(
			`/admin/Notifications/getmessages/${userEmail}`,
			"GET"
		);
	}

	public static async sendNotification(request: IAddNotificationReuqest): Promise<void> {
		await Service.callApi("/admin/Notifications/send", "POST", request);
	}

	public static async deleteFileAccess(id: number): Promise<void> {
		await Service.callApi<IAccessControlItem[]>(`/admin/Access/deletefileaccess/${id}`, "GET");
	}

	public static async deleteFiles(uniqueIds: string[]): Promise<void> {
		await Service.callApi<IAccessControlItem[]>("/Files/deleteFiles", "POST", uniqueIds);
	}

	public static async setSearchFile(uniqueId: string): Promise<void> {
		await Service.callApi("/Files/SetSearchFile", "POST", uniqueId);
    }

	public static loginInternal(uniqueId?: string, sectionId?: number): void {
		window.location.href = baseUrl + `/api/homead/?uniqueId=${uniqueId}&sectionId=${sectionId}`;
	}

	public static loginExternal(uniqueId?: string, sectionId?: number): void {
		window.location.href = baseUrl + `/api/homeb2c/?uniqueId=${uniqueId}&sectionId=${sectionId}`;
	}

	public static async updateCurrentUser(): Promise<void> {
		await Service.callApi("/Auth/updateCurrentUser", "GET");
    }

	public static logout(): void {
		window.location.href = baseUrl + "/api/Auth/logout";
	}
}
