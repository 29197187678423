import { Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { SideBarState } from "../../layout/sideBar/SideBarStore";
import { DocumentsState } from "./DocumentsStore";

type DocumentsTableTitleProps = SideBarState & DocumentsState & {};

const DocumentsTableTitle: React.FC<DocumentsTableTitleProps> = ({
	selectedNavItem,
	documentsCount,
}) => {
	let title: string = selectedNavItem?.text;

	return (
		<Typography variant="h3">
			{title}

			{documentsCount ? ` (${documentsCount})` : null}
		</Typography>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return { ...state.sideBar, ...state.documents };
};

export default connect(mapStateToProps)(DocumentsTableTitle);
