import { Box } from "@mui/system";
import * as React from "react";
import AppBarNotifications from "./AppBarNotifications";
import AppsMenu from "./AppsMenu";
import UserMenu from "./UserMenu";

type AppBarActionsProps = {};

const AppBarActions: React.FC<AppBarActionsProps> = () => {
	return (
		<Box display="flex" flexDirection="row" alignItems="center">
			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				sx={{
					marginRight: "25px",
				}}
			>
				<AppBarNotifications />

				<UserMenu />
			</Box>

			{/*<AppsMenu />*/}
		</Box>
	);
};

export default AppBarActions;
