import { Box, TextField } from "@mui/material";
import * as React from "react";
import { IAccessControlItem, IRecipientDetails } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import Dropdown from "../../shared/Dropdown";

type RecipientFormProps = {
	availableRecipients: IAccessControlItem[];
	alreadySelectedRecipients: IAccessControlItem[];
	recipient: IRecipientDetails;
	isLast: boolean;
	onChange: (value: IRecipientDetails) => void;
};

const RecipientForm: React.FC<RecipientFormProps> = ({
	availableRecipients,
	alreadySelectedRecipients,
	recipient,
	isLast,
	onChange,
}) => {
	const handleChange = (value: IAccessControlItem): void => {
		const recipientCopy: IRecipientDetails = {
			...recipient,
			email: value.email,
			companyName: value.companyName,
			firstName: value.firstName,
			lastName: value.lastName,
			displayName: value.displayName,
		};

		onChange(recipientCopy);
	};

	let nameValue: string;

	if (recipient.firstName && recipient.lastName) {
		nameValue = `${recipient.firstName} ${recipient.lastName}`;
	} else if (recipient.displayName) {
		nameValue = recipient.displayName;
	}

	return (
		<Box
			display="flex"
			flexDirection="column"
			sx={{
				borderBottom: !isLast && "1px solid #334162",
				paddingBottom: "23px",
				"& .MuiInputBase-root": {
					width: "100%",
				},
			}}
		>
			<Box
				display="flex"
				sx={{
					marginBottom: "20px",
					marginTop: "13px",
				}}
			>
				<Box
					display="flex"
					sx={{
						paddingRight: "10px",
						width: "270px",
						boxSizing: "border-box",
					}}
				>
					<Dropdown
						items={availableRecipients?.filter(
							(ar) =>
								!alreadySelectedRecipients?.some((asr) => asr.email === ar.email)
						)}
						selectedItem={availableRecipients?.find(
							(ar) => ar.email === recipient.email
						)}
						textField="email"
						valueField="email"
						showFilterInput={false}
						label={Texts.Dialogs.NotificationDialog.FormLabels.Recipient}
						onChange={handleChange}
					/>
				</Box>

				{recipient.email && (
					<>
						<Box
							display="flex"
							flex={1}
							sx={{
								paddingRight: "10px",
								width: "175px",
								boxSizing: "border-box",
							}}
						>
							<TextField
								value={recipient.companyName || ""}
								fullWidth
								size="small"
								label={Texts.Dialogs.NotificationDialog.FormLabels.Customer}
								InputLabelProps={{ shrink: true }}
							/>
						</Box>

						<Box
							display="flex"
							flex={1}
							sx={{
								paddingRight: "10px",
								width: "175px",
								boxSizing: "border-box",
							}}
						>
							<TextField
								value={nameValue || ""}
								fullWidth
								size="small"
								label={Texts.Dialogs.NotificationDialog.FormLabels.Name}
								InputLabelProps={{ shrink: true }}
							/>
						</Box>
					</>
				)}
			</Box>
		</Box>
	);
};

export default RecipientForm;
