import { Button, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as UploadSvg } from "../../../images/icons/Upload.svg";
import { Routing } from "../../../resources/Routes";
import Texts from "../../../resources/Texts";
import TextSeparator from "../../shared/TextSeparator";
import DateRangeOptionSelector from "../DateRangeOptionSelector";
import CompanyTabs from "./CompanyTabs";

type DocumentsHeaderProps = {};

const DocumentsHeader: React.FC<DocumentsHeaderProps> = () => {
	const theme = useTheme();

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
			height="100px"
			sx={{
				marginTop: "-20px",
				marginLeft: "-20px",
				marginRight: "-20px",
				marginBottom: "20px",
				paddingLeft: "20px",
				paddingRight: "20px",
				paddingTop: "19px",
				boxShadow: "0px 5px 10px #25293D",
				boxSizing: "border-box",
			}}
		>
			<Box display="flex" alignItems="center" justifyContent="space-between" height="28px">
				<Box display="flex" alignItems="center">
					<Typography variant="h2">{Texts.ViewTitle.Documents}</Typography>

					<TextSeparator />

					<Typography variant="body1">{Texts.ViewTitle.ShowDocumentsFrom}</Typography>

					<DateRangeOptionSelector />
				</Box>

				<Link
					to={Routing.getUploadViewUrl()}
					style={{
						color: theme.palette.text.secondary,
						textDecoration: "none",
					}}
				>
					<Button
						variant="contained"
						sx={{
							color: theme.palette.text.secondary,
							backgroundColor: "#FFFFFF",
							fontWeight: "600",
							"&:hover": {
								backgroundColor: "#9AA0AF",
							},
							"&.Mui-disabled": {
								backgroundColor: "#9AA0AF",
							},
						}}
					>
						<UploadSvg
							style={{
								marginRight: "8px",
							}}
						/>
						{Texts.DocumentsTable.UploadNewDocument}
					</Button>
				</Link>
			</Box>

			<CompanyTabs />
		</Box>
	);
};

export default DocumentsHeader;
