import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as BackButtonSvg } from "../../images/icons/BackButton.svg";
import { Routing } from "../../resources/Routes";
import Texts from "../../resources/Texts";

type UploadDocumentHeaderProps = {};

const UploadDocumentHeader: React.FC<UploadDocumentHeaderProps> = () => {
	return (
		<Box
			display="flex"
			alignItems="center"
			height="76px"
			sx={{
				marginTop: "-20px",
				marginLeft: "-20px",
				marginRight: "-20px",
				marginBottom: "20px",
				paddingLeft: "20px",
				boxShadow: "0px 5px 10px #25293D",
				boxSizing: "border-box",
			}}
		>
			<Link
				to={Routing.getHomeViewUrl()}
				style={{
					height: "21px",
				}}
			>
				<Box
					sx={{
						mr: "14px",
					}}
				>
					<BackButtonSvg />
				</Box>
			</Link>
			<Typography variant="h2">{Texts.ViewTitle.UploadDocuments}</Typography>
		</Box>
	);
};

export default UploadDocumentHeader;
