import {
	Box,
	Button,
	CircularProgress,
	SxProps,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as React from "react";
import { useQuery, useQueryClient } from "react-query";
import { connect } from "react-redux";
import { ReactComponent as TrashSvg } from "../../../images/icons/Trash.svg";
import { IAccessControlItem } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import { handleErrorMessage } from "../../../utils/Utils";
import TitleColumn from "./TitleColumn";

const tableCellStyle: SxProps = {
	fontWeight: "normal",
	paddingLeft: 0,
	paddingTop: "12px",
	paddingBottom: "12px",
	fontSize: "14px",
};

type AccessControlTableProps = typeof AlertStoreActionCreators & { fileId: number };

const AccessControlTable: React.FC<AccessControlTableProps> = ({ fileId, addErrorAlert }) => {
	const queryClient = useQueryClient();

	const loadAccessControlData = async (): Promise<IAccessControlItem[]> => {
		if (!fileId) {
			return [];
		}

		return await Service.getFileAccessList(fileId);
	};
	const { isLoading, data } = useQuery(["accessControlData", fileId], loadAccessControlData);

	const handleDelete = async (id: number): Promise<void> => {
		try {
			await Service.deleteFileAccess(id);

			queryClient.invalidateQueries("accessControlData");
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	if (isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				flex={1}
				sx={{
					color: "#ffffff",
				}}
			>
				<CircularProgress color="inherit" />
			</Box>
		);
	}

	return (
		<Box
			sx={{
				backgroundColor: "#25293D",
				borderRadius: "8px",
				padding: "12px 16px",
			}}
		>
			{`${Texts.Dialogs.AccessControl.TableTitle} (${data && data.length})`}
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow
							sx={{
								"& td, & th": {
									borderColor: "#576079",
								},
							}}
						>
							<TableCell sx={tableCellStyle}>
								{Texts.Dialogs.AccessControl.Table.ColumnTitles.Email}
							</TableCell>
							<TableCell sx={tableCellStyle}>
								{Texts.Dialogs.AccessControl.Table.ColumnTitles.CustomerName}
							</TableCell>
							<TableCell sx={tableCellStyle}>
								{Texts.Dialogs.AccessControl.Table.ColumnTitles.LastAction}
							</TableCell>
							<TableCell sx={tableCellStyle}>&nbsp;</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.map((row) => (
							<TableRow
								key={row.email}
								sx={{
									"& td, & th": {
										borderColor: "#576079",
									},
									"&:last-child td, &:last-child th": { border: 0 },
								}}
							>
								<TableCell component="th" scope="row" sx={tableCellStyle}>
									<TitleColumn data={row} columnWidth="265px" />
								</TableCell>
								<TableCell sx={tableCellStyle}>{row.companyName}</TableCell>
								<TableCell sx={tableCellStyle}>{row.lastAction}</TableCell>
								<TableCell align="right" sx={tableCellStyle}>
									<Button
										sx={{
											padding: "5px",
										}}
										onClick={() => handleDelete(row.id)}
									>
										<TrashSvg
											style={{
												marginRight: "8px",
											}}
										/>
										{Texts.Dialogs.AccessControl.Table.DeleteAction}
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(AlertStoreActionCreators, dispatch);
};

export default connect(null, mapDispatchToProps)(AccessControlTable);
