import { Action, Reducer } from "redux";
import { ICompany, IDocument, IDocumentType, IKeyword, IUploader } from "../../../resources/Contracts";
import { DocumentsSortBy } from "../../../resources/Enums";

export interface AdminDocumentsState {
	searchText: string;
	selectedTabId: number;
	keywordFilter: IKeyword[];
	documentTypeFilter: IDocumentType[];
	uploaderFilter: IUploader[];
	permissionFilter: ICompany[];
	maxViewsFilter: number;
	sortBy: DocumentsSortBy;
	sortDir: "ASC" | "DESC" | null;
	documentsCount: number;
	documentsItems: IDocument[];
	rowsPerPage: number;
	selectedPage: number;
	selectedBlobUniques: string[];
	isRefreshing: boolean;
}

interface SetSearchTextValueAction {
	type: "SET_ADMIN_SEARCH_VALUE";
	payload: string;
}

interface SetSelectedTabIdAction {
	type: "SET_SELECTED_TAB_ID";
	payload: number;
}

interface SetKeywordFilterAction {
	type: "SET_ADMIN_KEYWORD_FILTER";
	payload: IKeyword[];
}

interface SetDocumentTypeFilterAction {
	type: "SET_ADMIN_DOCUMENT_TYPE_FILTER";
	payload: IDocumentType[];
}

interface SetUploaderFilterAction {
	type: "SET_ADMIN_UPLOADER_FILTER";
	payload: IUploader[];
}

interface SetPermissionFilterAction {
	type: "SET_ADMIN_PERMISSION_FILTER";
	payload: ICompany[];
}

interface SetDownloadsFilterAction {
	type: "SET_ADMIN_DOWNLOADS_FILTER";
	payload: number;
}

interface SetSortByAction {
	type: "SET_ADMIN_SORT_BY";
	payload: DocumentsSortBy;
}

interface SetSortDirAction {
	type: "SET_ADMIN_SORT_DIR";
	payload: "ASC" | "DESC" | null;
}

interface SetDocumentsCountAction {
	type: "SET_ADMIN_DOCUMENTS_COUNT";
	payload: number;
}

interface SetDocumentsItemsAction {
	type: "SET_ADMIN_DOCUMENTS_ITEMS";
	payload: IDocument[];
}

interface SetRowsPerPageAction {
	type: "SET_ADMIN_ROWS_PER_PAGE";
	payload: number;
}

interface SetSelectedPageAction {
	type: "SET_ADMIN_SELECTED_PAGE";
	payload: number;
}

interface SetSelectedPageAction {
	type: "SET_ADMIN_SELECTED_PAGE";
	payload: number;
}

interface SetSelectedBlobUniquesAction {
	type: "SET_ADMIN_SELECTED_BLOB_UNIQUES";
	payload: string[];
}

interface SetDocumentsRefreshingAction {
	type: "SET_ADMIN_DOCUMENTS_REFRESHING";
	payload: boolean;
}

type KnownAction =
	| SetSearchTextValueAction
	| SetSelectedTabIdAction
	| SetKeywordFilterAction
	| SetDocumentTypeFilterAction
	| SetUploaderFilterAction
	| SetPermissionFilterAction
	| SetDownloadsFilterAction
	| SetSortByAction
	| SetSortDirAction
	| SetDocumentsCountAction
	| SetDocumentsItemsAction
	| SetRowsPerPageAction
	| SetSelectedPageAction
	| SetDocumentsRefreshingAction
	| SetSelectedBlobUniquesAction;

export const actionCreators = {
	setSearchTextValue: (value: string) =>
		({ type: "SET_ADMIN_SEARCH_VALUE", payload: value } as SetSearchTextValueAction),
	setSelectedTabId: (value: number) =>
		({ type: "SET_SELECTED_TAB_ID", payload: value } as SetSelectedTabIdAction),
	setKeywordFilter: (value: IKeyword[]) =>
		({ type: "SET_ADMIN_KEYWORD_FILTER", payload: value } as SetKeywordFilterAction),
	setDocumentTypeFilter: (value: IDocumentType[]) =>
		({ type: "SET_ADMIN_DOCUMENT_TYPE_FILTER", payload: value } as SetDocumentTypeFilterAction),
	setUploaderFilter: (value: IUploader[]) =>
		({ type: "SET_ADMIN_UPLOADER_FILTER", payload: value } as SetUploaderFilterAction),
	setPermissionFilter: (value: ICompany[]) =>
		({ type: "SET_ADMIN_PERMISSION_FILTER", payload: value } as SetPermissionFilterAction),
	setDownloadsFilter: (value: number) =>
		({ type: "SET_ADMIN_DOWNLOADS_FILTER", payload: value } as SetDownloadsFilterAction),
	setSortBy: (value: DocumentsSortBy) =>
		({ type: "SET_ADMIN_SORT_BY", payload: value } as SetSortByAction),
	setSortDir: (value: "ASC" | "DESC" | null) =>
		({ type: "SET_ADMIN_SORT_DIR", payload: value } as SetSortDirAction),
	setDocumentsCount: (value: number) =>
		({ type: "SET_ADMIN_DOCUMENTS_COUNT", payload: value } as SetDocumentsCountAction),
	setDocumentsItems: (value: IDocument[]) =>
	({
		type: "SET_ADMIN_DOCUMENTS_ITEMS",
		payload: value
    } as SetDocumentsItemsAction),
	setRowsPerPage: (value: number) =>
		({ type: "SET_ADMIN_ROWS_PER_PAGE", payload: value } as SetRowsPerPageAction),
	setSelectedPage: (value: number) =>
		({ type: "SET_ADMIN_SELECTED_PAGE", payload: value } as SetSelectedPageAction),
	setSelectedBlobUniques: (value: string[]) =>
		({
			type: "SET_ADMIN_SELECTED_BLOB_UNIQUES",
			payload: value,
	} as SetSelectedBlobUniquesAction),
	setDocumentsRefreshing: (value: boolean) => ({
		type: "SET_ADMIN_DOCUMENTS_REFRESHING",
		payload: value
	} as SetDocumentsRefreshingAction)
};

export const reducer: Reducer<AdminDocumentsState> = (
	state: AdminDocumentsState | undefined,
	incomingAction: Action
) => {
	if (state === undefined) {
		return {
			searchText: "",
			selectedTabId: -1,
			documentTypeFilter: [],
			keywordFilter: [],
			uploaderFilter: [],
			permissionFilter: [],
			maxViewsFilter: null,
			sortBy: DocumentsSortBy.Published,
			sortDir: "DESC",
			documentsCount: 0,
			documentsItems: [],
			rowsPerPage: 25,
			selectedPage: 1,
			selectedBlobUniques: [],
			isRefreshing: true
		};
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "SET_ADMIN_SEARCH_VALUE":
			return { ...state, searchText: action.payload, selectedPage: 1 };

		case "SET_SELECTED_TAB_ID":
			return { ...state, selectedTabId: action.payload, selectedPage: 1 };

		case "SET_ADMIN_KEYWORD_FILTER":
			return { ...state, keywordFilter: action.payload, selectedPage: 1 };

		case "SET_ADMIN_DOCUMENT_TYPE_FILTER":
			return { ...state, documentTypeFilter: action.payload, selectedPage: 1 };

		case "SET_ADMIN_UPLOADER_FILTER":
			return { ...state, uploaderFilter: action.payload, selectedPage: 1 };

		case "SET_ADMIN_PERMISSION_FILTER":
			return { ...state, permissionFilter: action.payload, selectedPage: 1 };

		case "SET_ADMIN_DOWNLOADS_FILTER":
			return { ...state, maxViewsFilter: action.payload };

		case "SET_ADMIN_SORT_BY":
			return { ...state, sortBy: action.payload, selectedPage: 1 };

		case "SET_ADMIN_SORT_DIR":
			return { ...state, sortDir: action.payload, selectedPage: 1 };

		case "SET_ADMIN_DOCUMENTS_COUNT":
			return { ...state, documentsCount: action.payload };

		case "SET_ADMIN_DOCUMENTS_ITEMS":
			return { ...state, documentsItems: action.payload };

		case "SET_ADMIN_ROWS_PER_PAGE":
			return { ...state, rowsPerPage: action.payload };

		case "SET_ADMIN_SELECTED_PAGE":
			return { ...state, selectedPage: action.payload };

		case "SET_ADMIN_SELECTED_BLOB_UNIQUES":
			return { ...state, selectedBlobUniques: action.payload };

		case "SET_ADMIN_DOCUMENTS_REFRESHING":
			return { ...state, isRefreshing: action.payload };

		default:
			return state;
	}
};
