import { AxiosError } from "axios";

export function handleErrorMessage(error: AxiosError): string {
	if (error?.response?.data) {
		const msg: any = error?.response?.data;
		return msg;
    } else if (error.message) {
		return error.message;
	} else if (error.name) {
		return error.name;
	} else {
		return JSON.stringify(error);
	}
}
