import { Box, IconButton } from "@mui/material";
import * as React from "react";
import { ReactComponent as CrossSvg } from "../../images/icons/Cross.svg";

type DialogTitleProps = { title: React.ReactNode; onClose: () => void };

const DialogTitle: React.FC<DialogTitleProps> = ({ title, onClose }) => {
	const handleClose = (): void => {
		onClose();
	};

	return (
		<Box display="flex" justifyContent="space-between">
			<Box display="flex" alignItems="center">
				{title}
			</Box>

			<IconButton onClick={handleClose}>
				<CrossSvg />
			</IconButton>
		</Box>
	);
};

export default DialogTitle;
