import { Box } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { IUploader } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { ApplicationState } from "../../../store";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import { handleErrorMessage } from "../../../utils/Utils";
import Multiselect from "../../shared/Multiselect";
import * as AddUserStore from "./AddUserStore";

type AddUserUserFilterProps = AddUserStore.AddUserState &
	typeof AddUserStore.actionCreators &
	typeof AlertStoreActionCreators;

const AddUserUserFilter: React.FC<AddUserUserFilterProps> = ({
	selectedCompanies,
	selectedUsers,
	setUsersAddUserState,
	addErrorAlert,
}) => {
	React.useEffect(() => {
		if (selectedUsers?.length === 0) setSearchValue("");
	}, [selectedUsers]);

	const [searchValue, setSearchValue] = React.useState<string>("");

	const loadUsers = async (): Promise<IUploader[]> => {
		try {
			if (searchValue.length > 0) {
				let users = await Service.getUsersByCompanyId(searchValue, selectedCompanies[0].id);
				users = users.map((user: IUploader) => {
					user.fullDisplayName = `${user.givenName} ${user.surname} (${user.mail})`;
					return user;
				});

				return users;
			} else {
				return [];
			}
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const { isLoading, data } = useQuery(["addUserUserData", searchValue], loadUsers);

	const handleUserChange = (value: IUploader[]): void => {
		function getDifference<T>(a: T[], b: T[]): T[] {
			return a.filter((element) => {
				return !b.includes(element);
			});
		}
		const diff = getDifference(value, selectedUsers);

		if (diff.length === 0) setUsersAddUserState(value);
		else setUsersAddUserState(diff);
	};

	const renderUserValue = (users: IUploader[]): React.ReactNode => {
		return users?.length === 1 ? (
			<Box>
				{users[0].givenName} {users[0].surname} ({users[0].mail})
			</Box>
		) : (
			<Box>{Texts.Dialogs.AddUserDialog.PlaceHolders.Select}</Box>
		);
	};

	return (
		<Multiselect
			items={data}
			multiple={false}
			selectedItems={selectedUsers}
			textField="fullDisplayName"
			valueField="id"
			onChange={handleUserChange}
			onSearchChange={setSearchValue}
			showFilterInput
			showLoadingIndicator={isLoading}
			hideCloseButton
			renderValue={renderUserValue}
			sx={{ width: "340px", mt: "4px" }}
			disabled={selectedCompanies.length === 0}
		/>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.addUser;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ ...AddUserStore.actionCreators, ...AlertStoreActionCreators },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserUserFilter);
