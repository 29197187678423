import { bindActionCreators } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import { IAddNotificationReuqest } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { handleErrorMessage } from "../../../utils/Utils";
import { ApplicationState } from "../../../store";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import DialogBase from "../DialogBase";
import * as DialogStore from "../DialogStore";
import NotificationRecipients from "./NotificationRecipients";
import TemplateSelector from "./TemplateSelector";
import { Box } from "@mui/material";
import TextSeparator from "../../shared/TextSeparator";
import { IRecipientDetails } from "../../../resources/Contracts";

type NotificationDialogProps = DialogStore.DialogState &
	typeof DialogStore.actionCreators &
	typeof AlertStoreActionCreators & {};

const NotificationDialog: React.FC<NotificationDialogProps> = ({
	notificationDialog,
	setNotificationDialogState,
	addAlert,
	addErrorAlert,
}) => {
	const [recipients, setRecipients] = React.useState<IRecipientDetails[]>([
		{
			email: null,
			companyName: null,
			firstName: null,
			lastName: null,
		},
	]);
	const [selectedTemplateId, setSelectedTemplateId] = React.useState<number>(null);
	const [customEmailContent, setCustomEmailContent] = React.useState<string>();

	const handleSubmit = async (): Promise<void> => {
		try {
			const request: IAddNotificationReuqest = {
				fileId: notificationDialog.fileId,
				emailTemplateId: selectedTemplateId,
				customText: customEmailContent,
				users: recipients,
			};

			await Service.sendNotification(request);

			addAlert({
				type: "success",
				text:
					recipients.length > 1
						? `Success! Notification sent to ${recipients.length} recipients`
						: `Success! Notification sent to ${recipients.length} recipient`,
			});

			handleClose();
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const handleClose = (): void => {
		setRecipients([
			{
				email: null,
				companyName: null,
				firstName: null,
				lastName: null,
			},
		]);

		setNotificationDialogState(null);
		setSelectedTemplateId(null);
		setCustomEmailContent(null);
	};

	const handleRecipientsChange = (recs: IRecipientDetails[]): void => {
		setRecipients(recs);
	};

	const handleTemplateChange = (value: number): void => {
		setSelectedTemplateId(value);
	};

	const handleCustomEmailChange = (value: string): void => {
		setCustomEmailContent(value);
	};

	if (!notificationDialog) {
		return null;
	}

	return (
		<DialogBase
			open={notificationDialog.open}
			dialogTitle={
				<>
					<Box
						component="span"
						sx={{
							fontWeight: "600",
						}}
					>
						{notificationDialog.title}
					</Box>
					<TextSeparator />
					{Texts.Dialogs.NotificationDialog.Title}
				</>
			}
			primaryButtonText={Texts.Dialogs.Actions.Ok}
			onCancel={handleClose}
			onSubmit={handleSubmit}
		>
			<NotificationRecipients
				fileId={notificationDialog.fileId}
				recipients={recipients}
				onChange={handleRecipientsChange}
			/>

			<TemplateSelector
				selectedTemplateId={selectedTemplateId}
				customEmailContent={customEmailContent}
				onChange={handleTemplateChange}
				onCustomEmailChange={handleCustomEmailChange}
			/>
		</DialogBase>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.dialogs;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...DialogStore.actionCreators,
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDialog);
