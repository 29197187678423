import { Box, IconButton, Menu, Slider, Typography } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ReactComponent as CrossSvg } from "../../../../images/icons/Cross.svg";
import Texts from "../../../../resources/Texts";
import { ApplicationState } from "../../../../store";
import * as AdminDocumentsStore from "../AdminDocumentsStore";

type AdminDownloadsFilterProps = AdminDocumentsStore.AdminDocumentsState &
	typeof AdminDocumentsStore.actionCreators & {
		onClose: () => void;
	};

const AdminDownloadsFilter: React.FC<AdminDownloadsFilterProps> = ({
	maxViewsFilter,
	setDownloadsFilter,
	onClose,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open: boolean = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = (): void => {
		setAnchorEl(null);
	};

	const handleClose = (): void => {
		onClose();
	};

	const handleChange = (event: Event, newValue: number | number[]): void => {
		if (newValue !== maxViewsFilter) {
			setDownloadsFilter(newValue as number);
		}
	};

	const handleReset = (): void => {
		setDownloadsFilter(null);
	};

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				onClick={handleClick}
				sx={{
					width: "230px",
					height: "37px",
					cursor: "pointer",
					marginLeft: "12px",
					boxSizing: "border-box",
					borderRadius: "4px",
					border: "1px solid #9AA0AF",
					"&:hover": {
						borderColor: "#ffffff",
					},
				}}
			>
				<IconButton
					onClick={handleClose}
					edge="start"
					sx={{
						borderRadius: 0,
						borderRight: "1px solid #ffffff",
						marginLeft: 0,
						mr: "8px",
					}}
				>
					<CrossSvg />
				</IconButton>

				<Typography variant="body1">
					<Box
						component="span"
						sx={{
							fontWeight: 600,
						}}
					>
						{Texts.DocumentsTable.ViewsFilterLabel}:{" "}
					</Box>
					<Box
						component="span"
						sx={{
							color: "#9AA0AF",
						}}
					>
						{Texts.DocumentsTable.ChooseRange}
					</Box>
				</Typography>
			</Box>

			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleMenuClose}
				sx={{
					"& .MuiPaper-root": {
						width: "230px",
						backgroundColor: "#151724 !important",
					},
					"& .MuiMenu-list": {
						border: 0,
						height: "100%",
					},
				}}
			>
				<Box
					sx={{
						pl: "16px",
						pr: "16px",
						pt: "16px",
					}}
				>
					<Typography
						component="span"
						onClick={handleReset}
						sx={{
							fontSize: "14px",
							textTransform: "uppercase",
							color: "#06A3ED",
							cursor: "pointer",
						}}
					>
						{Texts.DocumentsTable.ViewsFilterReset}
					</Typography>
				</Box>

				<Box
					sx={{
						pl: "16px",
						pr: "16px",
						pt: "28px",
					}}
				>
					<Slider
						value={maxViewsFilter || 100}
						onChange={handleChange}
						valueLabelDisplay="on"
						min={0}
						max={100}
						size="small"
						marks={[
							{
								value: 0,
								label: "0",
							},
							{
								value: 100,
								label: "100",
							},
						]}
					/>
				</Box>
			</Menu>
		</>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.adminDocuments;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(AdminDocumentsStore.actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDownloadsFilter);
