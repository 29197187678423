import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { IUploader } from "../../../../resources/Contracts";
import Texts from "../../../../resources/Texts";
import { Service } from "../../../../services/Service";
import { ApplicationState } from "../../../../store";
import { actionCreators as AlertStoreActionCreators } from "../../../../store/AlertStore";
import { handleErrorMessage } from "../../../../utils/Utils";
import Multiselect from "../../../shared/Multiselect";
import * as AdminDocumentsStore from "../AdminDocumentsStore";

type AdminUploaderFilterProps = AdminDocumentsStore.AdminDocumentsState &
	typeof AdminDocumentsStore.actionCreators &
	typeof AlertStoreActionCreators & {
		onClose: () => void;
	};

const AdminUploaderFilter: React.FC<AdminUploaderFilterProps> = ({
	uploaderFilter,
	setUploaderFilter,
	onClose,
	addErrorAlert,
}) => {
	const [searchValue, setSearchValue] = React.useState<string>("");

	const loadUploaders = async (): Promise<IUploader[]> => {
		try {
			if (searchValue.length > 0) {
				return await Service.getUploaders(searchValue);
			} else {
				return [];
			}
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const { isLoading, data } = useQuery(["uploadersData", searchValue], loadUploaders);

	const handleChange = (value: IUploader[]): void => {
		setUploaderFilter(value);
	};

	const handleClose = (): void => {
		setUploaderFilter([]);

		onClose();
	};

	return (
		<Multiselect
			items={data}
			selectedItems={uploaderFilter}
			textField="displayName"
			valueField="id"
			label={Texts.DocumentsTable.UploadedBy}
			onChange={handleChange}
			onClose={handleClose}
			onSearchChange={setSearchValue}
			showFilterInput
			showLoadingIndicator={isLoading}
			sx={{
				marginLeft: "12px",
			}}
		/>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.adminDocuments;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ ...AdminDocumentsStore.actionCreators, ...AlertStoreActionCreators },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUploaderFilter);
