import * as React from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import Texts from "../../resources/Texts";
import DialogTitle from "./DialogTitle";

type DialogBaseProps = DialogProps & {
	children?: React.ReactNode;
	open: boolean;
	dialogTitle: React.ReactNode;
	primaryButtonText?: string;
	topBorderColor?: string;
	customButtons?: React.ReactNode;
	onCancel: () => void;
	onSubmit?: () => void;
};

const DialogBase: React.FC<DialogBaseProps> = ({
	children,
	open,
	dialogTitle,
	primaryButtonText,
	topBorderColor,
	customButtons,
	onCancel,
	onSubmit,
	...dialogProps
}) => {
	const handleClose = (): void => {
		onCancel();
	};

	const handlePrimaryButtonClick = (): void => {
		onSubmit();
	};

	const descriptionElementRef = React.useRef<HTMLElement>(null);

	React.useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	return (
		<Dialog
			{...dialogProps}
			open={open}
			onClose={handleClose}
			scroll="paper"
			sx={{
				"& .MuiPaper-root": {
					backgroundColor: "#151724",
					border: "0.5px solid #999999",
				},
			}}
			maxWidth={false}
		>
			<MuiDialogTitle
				sx={{
					borderTop: `8px solid ${topBorderColor || "#06A3ED"}`,
					height: "50px",
					boxSizing: "border-box",
					padding: 0,
					paddingLeft: "24px",
					paddingRight: "24px",
					paddingTop: "8px",
				}}
			>
				<DialogTitle onClose={handleClose} title={dialogTitle} />
			</MuiDialogTitle>
			<DialogContent
				dividers={true}
				sx={{
					"&.MuiDialogContent-dividers": {
						borderColor: "#576079",
					},
				}}
			>
				{children}
			</DialogContent>
			<DialogActions>
				{customButtons ? (
					customButtons
				) : (
					<>
						<Button
							onClick={handleClose}
							sx={{
								fontWeight: "600",
							}}
						>
							{Texts.Dialogs.Actions.Cancel}
						</Button>
						{primaryButtonText && (
							<Button
								onClick={handlePrimaryButtonClick}
								sx={{
									background: "#FFFFFF",
									borderRadius: "4px",
									color: "#151724",
									"&:hover": {
										backgroundColor: "#9AA0AF",
									},
								}}
							>
								{primaryButtonText}
							</Button>
						)}
					</>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default DialogBase;
