import { Box } from "@mui/system";
import React from "react";
import TextSeparator from "../../shared/TextSeparator";
import AdminBatchActions from "./AdminBatchActions";
import AdminDocumentSearchInput from "./AdminDocumentSearchInput";
import AdminDocumentsTableTitle from "./AdminDocumentsTableTitle";
import AdminDocumentsTableFilter from "./filter/AdminDocumentsTableFilter";
import AdminDocumentsTable from "./table/AdminDocumentsTable";

type AdminDocumentsTableContainerProps = {};

const AdminDocumentsTableContainer: React.FC<AdminDocumentsTableContainerProps> = () => {
	return (
		<Box
			display="flex"
			flex={1}
			flexDirection="column"
			sx={{
				backgroundColor: "#25293D",
				borderRadius: "8px",
				paddingTop: "20px",
				paddingLeft: "20px",
				paddingRight: "20px",
				paddingBottom: "10px",
				height: "calc(100% - 96px)",
				boxSizing: "border-box",
			}}
		>
			<Box
				display="flex"
				flexDirection="column"
				sx={{
					marginBottom: "5px",
				}}
			>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between"
					height="38px"
					sx={{
						marginBottom: "5px",
					}}
				>
					<Box display="flex" flexDirection="row" alignItems="center">
						<AdminDocumentsTableTitle />

						<TextSeparator />

						<AdminDocumentSearchInput />
					</Box>

					<AdminBatchActions />
				</Box>

				<Box display="flex" flexDirection="row" alignItems="center">
					<AdminDocumentsTableFilter />
				</Box>
			</Box>

			<AdminDocumentsTable />
		</Box>
	);
};

export default AdminDocumentsTableContainer;
