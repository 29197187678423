import { Button, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as DateFunctions from "date-fns";
import React from "react";
import { connect } from "react-redux";
import { ReactComponent as ChevronDownSvg } from "../../images/icons/ChevronDown.svg";
import { DateRangeOption } from "../../resources/Enums";
import Texts from "../../resources/Texts";
import { ApplicationState } from "../../store";
import * as DocumentsStore from "./documentsTable/DocumentsStore";

type DateRangeOptionSelectorProps = DocumentsStore.DocumentsState &
	typeof DocumentsStore.actionCreators & {};

const DateRangeOptionSelector: React.FC<DateRangeOptionSelectorProps> = ({
	selectedStartDate,
	selectedEndDate,
	setStartDate,
	setEndDate,
}) => {
	const [selectedDateRangeOption, setDateRangeOption] = React.useState<DateRangeOption>(
		DateRangeOption.All
	);
	const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement>(null);
	const [startDateOpen, setStartDateOpen] = React.useState<boolean>(false);
	const [endDateOpen, setEndDateOpen] = React.useState<boolean>(false);

	const rangeOptionSelectorOpen: boolean = Boolean(menuAnchorEl);

	React.useEffect(() => {
		let startDate: string;
		let endDate: string;
		startDate = DateFunctions.formatISO(new Date(DateFunctions.minTime));
		endDate = DateFunctions.formatISO(new Date(DateFunctions.maxTime));

		setStartDate(startDate);
		setEndDate(endDate);
	}, [setEndDate, setStartDate]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleClose = (): void => {
		setMenuAnchorEl(null);
	};

	const handleRangeOptionSelect = (rangeOption: DateRangeOption): void => {
		setMenuAnchorEl(null);

		const now: Date = new Date();
		const thirtyDaysAgo: Date = DateFunctions.addDays(now, -30);
		const sixtyDaysAgo: Date = DateFunctions.addDays(now, -60);

		let startDate: string;
		let endDate: string;

		switch (rangeOption) {
			case DateRangeOption.All:
				startDate = DateFunctions.formatISO(new Date(DateFunctions.minTime));
				endDate = DateFunctions.formatISO(new Date(DateFunctions.maxTime));

				setStartDate(startDate);
				setEndDate(endDate);
				break;

			case DateRangeOption.Custom:
			case DateRangeOption.LastThirtyDays:
				startDate = DateFunctions.formatISO(thirtyDaysAgo);
				endDate = DateFunctions.formatISO(now);

				setStartDate(startDate);
				setEndDate(endDate);
				break;

			case DateRangeOption.LastSixtyDays:
				startDate = DateFunctions.formatISO(sixtyDaysAgo);
				endDate = DateFunctions.formatISO(now);

				setStartDate(startDate);
				setEndDate(endDate);
				break;
		}

		setDateRangeOption(rangeOption);
	};

	const handleStartCustomChange = (value: Date): void => {
		const formatedValue: string = DateFunctions.formatISO(value);

		setStartDate(formatedValue);
	};

	const handleEndCustomChange = (value: Date): void => {
		const formatedValue: string = DateFunctions.formatISO(value);

		setEndDate(formatedValue);
	};

	const now: Date = new Date();
	const thirtyDaysAgo: Date = DateFunctions.addDays(now, -30);
	const sixtyDaysAgo: Date = DateFunctions.addDays(now, -60);

	const todayString: string = DateFunctions.format(now, "d MMM");
	const thirtyDaysAgoString: string = DateFunctions.format(thirtyDaysAgo, "d MMM");
	const sixtyDaysAgoString: string = DateFunctions.format(sixtyDaysAgo, "d MMM");

	let dateSelectorValueText: string = "";
	let indicatorStartDateValue: string = "";
	let indicatorEndDateValue: string = "";
	let showIndicator: boolean = false;

	switch (selectedDateRangeOption) {
		case DateRangeOption.All:
			dateSelectorValueText = Texts.DateRangeOptions.All;
			indicatorStartDateValue = "";
			indicatorEndDateValue = "";
			showIndicator = false;
			break;

		case DateRangeOption.LastThirtyDays:
			dateSelectorValueText = Texts.DateRangeOptions.ThirtyDays;
			indicatorStartDateValue = DateFunctions.format(thirtyDaysAgo, "d MMM yyyy");
			indicatorEndDateValue = DateFunctions.format(now, "d MMM yyyy");
			showIndicator = true;
			break;

		case DateRangeOption.LastSixtyDays:
			dateSelectorValueText = Texts.DateRangeOptions.SixtyDays;
			indicatorStartDateValue = DateFunctions.format(sixtyDaysAgo, "d MMM yyyy");
			indicatorEndDateValue = DateFunctions.format(now, "d MMM yyyy");
			showIndicator = true;
			break;

		case DateRangeOption.Custom:
			dateSelectorValueText = Texts.DateRangeOptions.Custom;
			indicatorStartDateValue = DateFunctions.format(
				new Date(selectedStartDate),
				"d MMM yyyy"
			);
			indicatorEndDateValue = DateFunctions.format(new Date(selectedEndDate), "d MMM yyyy");
			showIndicator = false;
			break;
	}

	const popperSx: SxProps = {
		inset: "-35px auto auto 0 !important",
		"& .MuiPaper-root": {
			backgroundColor: "#151724",
		},
		"& .MuiCalendarPicker-root": {
			backgroundColor: "#151724",
		},
		"& .MuiPickersDay-dayWithMargin": {
			color: "#ffffff",
			backgroundColor: "#151724",
			"&:hover": {
				backgroundColor: "rgba(87, 96, 121, 0.6)",
			},
		},
		"& .MuiSvgIcon-root": {
			color: "#ffffff",
		},
		"& .MuiTypography-caption": {
			color: "#ffffff",
		},
	};

	return (
		<>
			<Button
				onClick={handleClick}
				disableRipple
				sx={{
					textDecoration: rangeOptionSelectorOpen && "underline",
					color: "#06A3ED",
					fontWeight: 600,
					"&:hover": {
						textDecoration: "underline",
					},
				}}
			>
				{dateSelectorValueText}
				<ChevronDownSvg
					style={{
						marginLeft: "7px",
					}}
				/>
			</Button>

			{showIndicator && (
				<Typography
					variant="body1"
					component="div"
					sx={{
						padding: "5px 8px",
						lineHeight: "17px",
						backgroundColor: "#2f3446",
						borderRadius: "4px",
					}}
				>
					{`${indicatorStartDateValue} - ${indicatorEndDateValue}`}
				</Typography>
			)}

			{selectedDateRangeOption === DateRangeOption.Custom && (
				<>
					<Box
						display="flex"
						alignItems="center"
						sx={{
							padding: "5px 8px",
							lineHeight: "17px",
							backgroundColor: "#2f3446",
							borderRadius: "4px",
							mr: "8px",
							color: "#ffffff",
						}}
					>
						<DatePicker
							inputFormat="dd/MM/yyyy"
							value={DateFunctions.format(new Date(selectedStartDate), "yyyy-MM-dd")}
							onChange={handleStartCustomChange}
							renderInput={(params) => (
								<>
									<TextField
										{...params}
										sx={{
											visibility: "hidden",
											width: 0,
											height: 0,
											overflow: "hidden",
										}}
									/>

									<Typography
										variant="body1"
										component="div"
										sx={{
											padding: "5px 8px",
											lineHeight: "17px",
											backgroundColor: "#2f3446",
											borderRadius: "4px",
											cursor: "pointer",
										}}
										onClick={() => setStartDateOpen(true)}
									>
										{indicatorStartDateValue}
									</Typography>
								</>
							)}
							closeOnSelect
							onClose={() => setStartDateOpen(false)}
							PopperProps={{
								sx: popperSx,
							}}
							open={startDateOpen}
						/>
						-
						<DatePicker
							value={DateFunctions.format(new Date(selectedEndDate), "yyyy-MM-dd")}
							onChange={handleEndCustomChange}
							renderInput={(params) => (
								<>
									<TextField
										{...params}
										sx={{
											visibility: "hidden",
											width: 0,
											height: 0,
											overflow: "hidden",
										}}
									/>

									<Typography
										variant="body1"
										component="div"
										sx={{
											padding: "5px 8px",
											lineHeight: "17px",
											backgroundColor: "#2f3446",
											borderRadius: "4px",
											cursor: "pointer",
										}}
										onClick={() => setEndDateOpen(true)}
									>
										{indicatorEndDateValue}
									</Typography>
								</>
							)}
							closeOnSelect
							PopperProps={{
								sx: popperSx,
							}}
							onClose={() => setEndDateOpen(false)}
							open={endDateOpen}
						/>
					</Box>
				</>
			)}

			<Menu anchorEl={menuAnchorEl} open={rangeOptionSelectorOpen} onClose={handleClose}>
				<MenuItem
					onClick={() => handleRangeOptionSelect(DateRangeOption.All)}
					sx={{
						padding: "6px 8px",
						"&:hover": {
							backgroundColor: "#57607999",
						},
					}}
				>
					{`${Texts.DateRangeOptions.All}`}
				</MenuItem>

				<MenuItem
					onClick={() => handleRangeOptionSelect(DateRangeOption.LastThirtyDays)}
					sx={{
						padding: "6px 8px",
						"&:hover": {
							backgroundColor: "#57607999",
						},
					}}
				>
					{`${Texts.DateRangeOptions.ThirtyDays} (${todayString} - ${thirtyDaysAgoString})`}
				</MenuItem>

				<MenuItem
					onClick={() => handleRangeOptionSelect(DateRangeOption.LastSixtyDays)}
					sx={{
						padding: "6px 8px",
						"&:hover": {
							backgroundColor: "#57607999",
						},
					}}
				>
					{`${Texts.DateRangeOptions.SixtyDays} (${todayString} - ${sixtyDaysAgoString})`}
				</MenuItem>

				<MenuItem
					onClick={() => handleRangeOptionSelect(DateRangeOption.Custom)}
					sx={{
						padding: "6px 8px",
						"&:hover": {
							backgroundColor: "#57607999",
						},
					}}
				>
					{Texts.DateRangeOptions.Custom}
				</MenuItem>
			</Menu>
		</>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.documents;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(DocumentsStore.actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DateRangeOptionSelector);
