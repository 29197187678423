import { Box, CircularProgress } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import {
    IAdminDocumentsRequest,
	IDocument, IDocumentsResponse,
} from "../../../../resources/Contracts";
import { ApplicationState } from "../../../../store";
import * as AdminDocumentsStore from "../AdminDocumentsStore";
import AdminDocumentsTableRow from "./AdminDocumentsTableRow";
import * as UserStore from "../../../../store/UserStore";
import * as DialogStore from "../../../dialogs/DialogStore";
import { useQuery } from "react-query";
import { Service } from "../../../../services/Service";

type AdminDocumentsTableRowsProps = AdminDocumentsStore.AdminDocumentsState &
	DialogStore.DialogState &
	typeof AdminDocumentsStore.actionCreators & {
		selectedStartDate: string;
		selectedEndDate: string;
	} &
	UserStore.UserState & typeof UserStore.actionCreators & {};

const AdminDocumentsTableRows: React.FC<AdminDocumentsTableRowsProps> = ({
	directFile,
	setFile,
	setDocumentsCount,
	setDocumentsItems,
	setDocumentsRefreshing,
	selectedStartDate,
	selectedEndDate,
	documentTypeFilter,
	keywordFilter,
	permissionFilter,
	uploaderFilter,
	maxViewsFilter,
	searchText,
	sortBy,
	sortDir,
	rowsPerPage,
	selectedPage,
	selectedTabId,
	selectedBlobUniques,
	documentsItems,
	setSelectedBlobUniques,
	isRefreshing,
}) => {

	const loadDocuments = async (): Promise<IDocument[]> => {
		setDocumentsRefreshing(true);

		if (!rowsPerPage || !selectedPage || !selectedStartDate || !selectedEndDate) {
			return [];
		}

		const request: IAdminDocumentsRequest = {
			itemsPerPage: rowsPerPage,
			pageNumber: selectedPage,
			orderBy: sortBy,
			order: sortDir || "ASC",
			searchString: searchText?.length ? searchText : null,
			filters: {
				publishedFrom: selectedStartDate,
				publishedTo: selectedEndDate,
				keywords: keywordFilter?.length > 0 ? keywordFilter.map((k) => k.id) : null,
				typeIds:
					documentTypeFilter?.length > 0 ? documentTypeFilter.map((dt) => dt.id) : null,
				tabId: selectedTabId,
				permissions:
					permissionFilter?.length > 0 ? permissionFilter.map((c) => c.id) : null,
				uploadedBy: uploaderFilter?.length > 0 ? uploaderFilter.map((u) => u.mail) : null,
				maxViews: maxViewsFilter,
			},
		};

		const response: IDocumentsResponse = await Service.getAdminDocuments(request);

		setDocumentsCount(response.itemsCount);
		setDocumentsItems(response.itemsData);
		setDocumentsRefreshing(false);

		return response.itemsData;
	};

	const { data, isLoading } = useQuery(
		[
			"adminDocumentsData",
			selectedStartDate,
			selectedEndDate,
			documentTypeFilter,
			keywordFilter,
			permissionFilter,
			uploaderFilter,
			maxViewsFilter,
			searchText,
			sortBy,
			sortDir,
			rowsPerPage,
			selectedPage,
			selectedTabId,
		],
		loadDocuments
	);

	if (isRefreshing || isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				flex={1}
				sx={{
					color: "#ffffff",
				}}
			>
				<CircularProgress color="inherit" />
			</Box>
		);
	}

	const handleRowSelect = (doc: IDocument, selected: boolean): void => {
		let selectedDocumentIdsCopy: string[] = selectedBlobUniques.slice();

		if (selected) {
			selectedDocumentIdsCopy.push(doc.blobUnique);
		} else {
			selectedDocumentIdsCopy = selectedDocumentIdsCopy.filter(
				(sid) => sid !== doc.blobUnique
			);
		}

		setSelectedBlobUniques(selectedDocumentIdsCopy);
	};

	return (
		<Box
			display="block"
			sx={{
				overflowY: "auto",
				height: "calc(100% - 38px)",
			}}
		>
			<Box display="flex" flexDirection="column" flex={1}>
				{documentsItems?.map((d) => (
					<AdminDocumentsTableRow
						key={d.id}
						data={d}
						selected={selectedBlobUniques.some((sid) => sid === d.blobUnique)}
						onSelect={handleRowSelect}
					/>
				))}
			</Box>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return {
		...state.adminDocuments,
		selectedStartDate: state.documents.selectedStartDate,
		selectedEndDate: state.documents.selectedEndDate,
		...state.userState,
		...state.dialogs,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...AdminDocumentsStore.actionCreators,
			...UserStore.actionCreators
		}
		,
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDocumentsTableRows);
