const Texts = {
	AppTitle: "Security Intelligence Portal",
	ViewTitle: {
		IASO: "IASO",
		ShowDocumentsFrom: "Show documents from",
		Documents: "Documents",
		UploadDocuments: "Upload Documents",
		UserManagement: "User Management",
	},
	UserMenu: {
		Logout: "Logout",
	},
	DateRangeOptions: {
		All: "All items",
		ThirtyDays: "Most recent 30 days",
		SixtyDays: "Most recent 60 days",
		Custom: "Custom",
	},
	NotificationList: {
		Title: "Notifications",
		NewDocument: "New document {title} was uploaded.",
		BeforeExpiration:
			"Document {title} expires in {expiration} {unit}. Please make sure it is backed up in DMS.",
		ExpiredDocument: "Document {title} expired {expiration} ago.",
		ExpirationNote: "Expiration Note",
	},
	SideBarNavigation: {
		Items: {
			Astro: "Astro",
			GlobalVideoPiracy: "Global Video Piracy",
			GlobalHackingTrends: "Global Hacking Trends",
			Documents: "Documents",
			UserManagement: "User Management",
		},
	},
	LoginView: {
		LoginInternal: "Login internal",
		LoginExternal: "Login external",
		Login: "Login",
	},
	DocumentsTable: {
		Titles: {
			Documents: "Documents",
		},
		TabTitles: {
			All: "All",
			General: "General",
		},
		ColumnTitles: {
			DocumentNameAndType: "Document Name & Type",
			CreationDate: "Creation Date",
			Keywords: "Keywords",
			Category: "Section",
			UploadedBy: "Uploaded By",
			AccessPermissions: "Access Permissions",
			MaxViews: "Views",
			SentNotifications: "Sent Notifications",
		},
		DocumentTags: {
			New: "New",
			Old: "Old",
		},
		BatchActions: {
			Print: "Print",
			Download: "Download",
		},
		RowMenuOptions: {
			ViewingStats: "Viewing Stats",
			NotifyClient: "Notify Customer",
			AccessControl: "Access Control",
			Delete: "Delete",
		},
		SearchFieldLabel: "Search",
		AddFilter: "Add filter",
		CategoryFilterLabel: "Category",
		KeywordFilterLabel: "Keywords",
		DocumentTypeFilterLabel: "Document Type",
		UploadedBy: "Uploaded By",
		AccessPermission: "Access Permissions",
		ViewsFilterLabel: "Views",
		ViewsFilterReset: "Reset",
		ChooseRange: "Choose range...",
		ClearFilter: "Clear",
		RowsPerPage: "Rows per page:",
		Download: "Download",
		UploadNewDocument: "Upload document",
	},
	UploadView: {
		UploadDocumentTitle: "Upload Document",
		AccessControlTitle: "Access Control",
		FileUploadTitle: "File",
		Fields: {
			NameTitle: "Name The Document",
			NamePlaceholder: "Name document...",
			KeywordsLabel: "Add Keywords",
			KeywordsPlaceholder: "Add Keywords...",
			KeywordsHint: "Separate keywords by a comma",
			RadioButtonGeneral: "General (Allow Everyone):",
			RadioButtonPermittedViewers: "Permitted Viewers:",
			SectionPlaceholder: "Choose section...",
			CompanyPlaceholder: "Choose customer...",
			UserPlaceHolder: "Choose user...",
			SelectFileLabel: "Select File",
			FileUploadLabel: "Drag and drop one file here",
		},
		Buttons: {
			Back: "Back",
			Upload: "Upload",
		},
	},
	UserManagement: {
		TableTitle: "All Users",
		Actions: {
			AddUser: "Add User",
			Export: "Export",
		},
		ColumnTitles: {
			Name: "Name",
			Customer: "Customer",
			EmailAddress: "Email Address",
			AccessPermission: "Access Permission",
			LastAction: "Last Action",
		},
		RowMenu: {
			RemoveUser: "Remove User",
		},
	},
	MultiselectInput: {
		Select: "Select...",
		SelectAll: "Select all",
		Clear: "Clear",
	},
	Dialogs: {
		Actions: {
			Cancel: "Cancel",
			Close: "Close",
			Ok: "OK",
			Add: "Add",
			Print: "Print",
			Download: "Download",
			View: "View"
		},
		AccessControl: {
			Title: "Access Control",
			TableTitle: "Permitted Viewers",
			Table: {
				ColumnTitles: {
					Email: "Email",
					CustomerName: "Customer Name",
					LastAction: "Last Action",
				},
				DeleteAction: "Delete",
			},
			AddInput: {
				AddPermitedViewer: "add permitted viewer",
				Label: "Grant Access to This Email Address",
				Placeholder: "Choose email address...",
			},
		},
		NotificationDialog: {
			Title: "Notify Customer",
			FormTitle: "Recipient Details",
			TemplatesTitle: "Email Template",
			CustomTemplatePlaceholder: "Enter Your message...",
			FormLabels: {
				Customer: "Customer Name",
				Name: "Name",
				Recipient: "Recipient",
			},
			AddRecipient: "add recipient",
		},
		DeleteFileDialog: {
			Title: "Delete Document",
			WarningContent1: "You are about to delete the document",
			WarningContent2:
				"from the system. This means it will no longer be available for clients to view and download. Please make sure the document is backed up in DMS.",
			DeleteConfirmation: "Are you sure you want to proceed?",
		},
		FilePreviewDialog: {
			Title: "Preview",
			PageSelectotLabel: "Page",
		},
		FailedUploadDialog: {
			Title: "Document Upload Failed",
			Content1: "There was a problem uploading this document.",
			Content2: "Please refresh the page and try again.",
			Content3: "Please select viewers!",
			Content4: "Invalid input file format.",
		},
		FailedLoginDialog: {
			Title: "Login User Failed",
			Content1: "Access denied.",
		},
		UserDeleteDialog: {
			Title: "Delete User",
			WarningContent1: 'You have requested to remove the user "',
			WarningContent2: '" from the system.',
			DeleteConfirmation: " Are you sure?",
		},
		ViewingStatsDialog: {
			Title: "Viewing Stats",
			Table: {
				ColumnTitles: {
					AccessedBy: "Accessed By",
					DateTime: "Date & Time",
					Actions: "Actions",
				},
			},
		},
		AddUserDialog: {
			Title: "Add User",
			FormLabels: {
				Customer: "Select Customer:",
				User: "Select User:",
			},
			PlaceHolders: {
				Select: "Select...",
			},
		},
	},
};

export default Texts;
