import { Box, CircularProgress } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { IDocument, IDocumentsRequest, IDocumentsResponse } from "../../../../resources/Contracts";
import { Service } from "../../../../services/Service";
import { ApplicationState } from "../../../../store";
import { SideBarState } from "../../../layout/sideBar/SideBarStore";
import * as DocumentsStore from "../DocumentsStore";
import DocumentsTableRow from "./DocumentsTableRow";

import * as UserStore from "../../../../store/UserStore";

type DocumentsTableRowsProps = DocumentsStore.DocumentsState &
	SideBarState &
	typeof DocumentsStore.actionCreators &
	UserStore.UserState &
	typeof UserStore.actionCreators;

const DocumentsTableRows: React.FC<DocumentsTableRowsProps> = ({
	directFile,
	setFile,
	selectedStartDate,
	selectedEndDate,
	documentTypeFilter,
	keywordFilter,
	searchText,
	sortBy,
	sortDir,
	rowsPerPage,
	selectedPage,
	selectedBlobUniques,
	selectedNavItem,
	setDocumentCount,
	setSelectedBlobUniques,
	setSearchTextValue
}) => {
	React.useEffect(() => {
		if (typeof directFile != "undefined" && directFile && directFile.length === 36) {
			setSearchTextValue(directFile);
			setFile(undefined);
		}
	}, []);

	const loadDocuments = async (): Promise<IDocument[]> => {
		if (
			!rowsPerPage ||
			!selectedPage ||
			!selectedStartDate ||
			!selectedEndDate ||
			!selectedNavItem
		) {
			return [];
		}

		const request: IDocumentsRequest = {
			itemsPerPage: rowsPerPage,
			pageNumber: selectedPage,
			orderBy: sortBy,
			order: sortDir || "ASC",
			searchString: searchText?.length ? searchText : null,
			sectionId: selectedNavItem.id,
			filters: {
				publishedFrom: selectedStartDate,
				publishedTo: selectedEndDate,
				keywords: keywordFilter?.length > 0 ? keywordFilter.map((k) => k.id) : null,
				typeIds:
					documentTypeFilter?.length > 0 ? documentTypeFilter.map((dt) => dt.id) : null,
			},
		};

		const response: IDocumentsResponse = await Service.getDocuments(request);

		setDocumentCount(response.itemsCount);

		return response.itemsData;
	};

	const { isLoading, data, isFetching } = useQuery(
		[
			"documentsData",
			selectedStartDate,
			selectedEndDate,
			documentTypeFilter,
			keywordFilter,
			searchText,
			sortBy,
			sortDir,
			rowsPerPage,
			selectedPage,
			selectedNavItem,
		],
		loadDocuments
	);

	if (isLoading || isFetching) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				flex={1}
				sx={{
					color: "#ffffff",
				}}
			>
				<CircularProgress color="inherit" />
			</Box>
		);
	}

	const handleRowSelect = (doc: IDocument, selected: boolean): void => {
		let selectedDocumentIdsCopy: string[] = selectedBlobUniques.slice();

		if (selected) {
			selectedDocumentIdsCopy.push(doc.blobUnique);
		} else {
			selectedDocumentIdsCopy = selectedDocumentIdsCopy.filter(
				(sid) => sid !== doc.blobUnique
			);
		}

		setSelectedBlobUniques(selectedDocumentIdsCopy);
	};

	return (
		<Box
			display="block"
			sx={{
				overflowY: "auto",
				height: "calc(100% - 38px)",
			}}
		>
			<Box display="flex" flexDirection="column" flex={1}>
				{data?.map((d) => (
					<DocumentsTableRow
						key={d.id}
						data={d}
						selected={selectedBlobUniques.some((sid) => sid === d.blobUnique)}
						onSelect={handleRowSelect}
					/>
				))}
			</Box>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return { ...state.documents, ...state.sideBar, ...state.userState };
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...DocumentsStore.actionCreators,
			...UserStore.actionCreators
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTableRows);
