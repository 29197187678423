import { Box, Menu, MenuItem, Pagination, Typography } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import Texts from "../../../resources/Texts";
import { ApplicationState } from "../../../store";
import * as UserManagementStore from "../UserManagementStore";

type UserManagementTablePaginationProps = UserManagementStore.UserManagementState &
	typeof UserManagementStore.actionCreators & {};

const UserManagementTablePagination: React.FC<UserManagementTablePaginationProps> = ({
	usersCount,
	rowsPerPage,
	selectedPage,
	setRowsPerPage,
	setSelectedPage,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setSelectedPage(value);
	};

	const handleRowsPerPageChange = (value: number) => {
		setRowsPerPage(value);

		handleClose();
	};

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const rowsPerPageOptions: number[] = [10, 25, 50, 100];

	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			sx={{
				marginTop: "10px",
			}}
		>
			<Box
				display="flex"
				alignItems="center"
				aria-controls={open ? "rowsPerPage-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				sx={{
					cursor: "pointer",
					height: "32px",
				}}
			>
				<Typography
					variant="tableItem"
					sx={{
						color: "#9AA0AF",
					}}
				>
					{Texts.DocumentsTable.RowsPerPage}{" "}
					<Box component="span" sx={{ color: "#ffffff" }}>
						{rowsPerPage}
					</Box>
				</Typography>
			</Box>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{rowsPerPageOptions.map((opt, index) => (
					<MenuItem key={`opt-${index}`} onClick={() => handleRowsPerPageChange(opt)}>
						{opt}
					</MenuItem>
				))}
			</Menu>

			<Pagination
				count={Math.ceil(usersCount / rowsPerPage)}
				page={selectedPage}
				onChange={handlePageChange}
				sx={{
					".Mui-selected.MuiButtonBase-root": {
						backgroundColor: "#57607999",
					},
					".MuiButtonBase-root:hover": {
						backgroundColor: "#57607999",
					},
				}}
			/>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.userManagement;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(UserManagementStore.actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementTablePagination);
