import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Texts from "../../../resources/Texts";
import DateRangeOptionSelector from "../DateRangeOptionSelector";

type DocumentsHeaderProps = {};

const DocumentsHeader: React.FC<DocumentsHeaderProps> = () => {
	return (
		<Box
			display="flex"
			alignItems="center"
			height="76px"
			sx={{
				marginTop: "-20px",
				marginLeft: "-20px",
				marginRight: "-20px",
				marginBottom: "20px",
				paddingLeft: "20px",
				boxShadow: "0px 5px 10px #25293D",
				boxSizing: "border-box",
			}}
		>
			<Typography variant="body1">{Texts.ViewTitle.ShowDocumentsFrom}</Typography>

			<DateRangeOptionSelector />
		</Box>
	);
};

export default DocumentsHeader;
