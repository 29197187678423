import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { Routing } from "./resources/Routes";
import DocumentsView from "./views/documentsView/DocumentsView";
import AdminDocumentsView from "./views/adminViews/AdminDocumentsView";
import DocumentUploadView from "./views/adminViews/DocumentUploadView";
import UserManagementView from "./views/adminViews/UserManagementView";
import LoginView from "./views/loginView/LoginView";
import Layout from "./components/layout/Layout";
import { connect } from "react-redux";
import { ApplicationState } from "./store";
import * as UserStore from "./store/UserStore";
//import * as SideBarStore from "./components/layout/sideBar/SideBarStore";
import { bindActionCreators } from "@reduxjs/toolkit";

type AppProps = UserStore.UserState & typeof UserStore.actionCreators; // & typeof SideBarStore.actionCreators;

const App: React.FC<AppProps> = ({ user, setFile, setSectionId }) => {
	const getUrlParam = (name: string): any => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const result = urlParams.get(name);
		if (result == null) {
			return null;
		} else {
			return decodeURIComponent(result) || 0;
		}
	};
	const fileUniqueId = getUrlParam("file");

	if (fileUniqueId && fileUniqueId.length === 36) {
		setFile(fileUniqueId);
		const fileSectionId = getUrlParam("section");
		if (fileSectionId) {
			setSectionId(Number.parseInt(fileSectionId));
		}
	}

	if (!user?.allowed) {
		return (
			<Routes>
				<Route path={Routing.Routes.HomePage} element={<LoginView />} />
				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
		);
	}

	if (user.isAdmin) {
		return (
			<Layout>
				<Routes>
					<Route path={Routing.Routes.HomePage} element={<AdminDocumentsView />} />
					<Route path={Routing.Routes.Admin.Upload} element={<DocumentUploadView />} />
					<Route
						path={Routing.Routes.Admin.UserManagement}
						element={<UserManagementView />}
					/>
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</Layout>
		);
	}

	return (
		<Layout>
			<Routes>
				<Route path={Routing.Routes.HomePage} element={<DocumentsView />} />
				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
		</Layout>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.userState;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(UserStore.actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
