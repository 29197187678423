import { Button, CircularProgress } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as React from "react";
import { useQueryClient } from "react-query";
import { connect } from "react-redux";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { ApplicationState } from "../../../store";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import { handleErrorMessage } from "../../../utils/Utils";
import DialogBase from "../DialogBase";
import * as DialogStore from "../DialogStore";
import AddUserContent from "./AddUserContent";
import * as AddUserStore from "./AddUserStore";

type AddUserDialogProps = DialogStore.DialogState &
	typeof DialogStore.actionCreators &
	AddUserStore.AddUserState &
	typeof AddUserStore.actionCreators &
	typeof AlertStoreActionCreators;

const AddUserDialog: React.FC<AddUserDialogProps> = ({
	addUserDialog,
	selectedUsers,
	setAddUserDialogState,
	clearAddUserState,
	addErrorAlert,
}) => {
	const queryClient = useQueryClient();
	const [addUserInProgress, setAddUserInProgress] = React.useState<boolean>(false);

	const handleClose = (): void => {
		setAddUserDialogState(null);
		clearAddUserState();
	};

	const handleSubmit = async (): Promise<void> => {
		setAddUserInProgress(true);
		try {
			await Service.addUser(selectedUsers[0]);

			queryClient.invalidateQueries("userManagementData");

			handleClose();
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}

		setAddUserInProgress(false);
	};

	if (!addUserDialog) {
		return null;
	}

	return (
		<DialogBase
			open={addUserDialog?.open}
			dialogTitle={Texts.Dialogs.AddUserDialog.Title}
			onCancel={handleClose}
			customButtons={
				<>
					<Button
						onClick={handleClose}
						sx={{
							fontWeight: "600",
						}}
					>
						{Texts.Dialogs.Actions.Cancel}
					</Button>

					<Button
						onClick={handleSubmit}
						sx={{
							background: "#FFFFFF",
							borderRadius: "4px",
							color: "#151724",
							"&:hover": {
								backgroundColor: "#9AA0AF",
							},
						}}
					>
						{Texts.Dialogs.Actions.Ok}

						{addUserInProgress && (
							<CircularProgress color="inherit" size="15px" sx={{ ml: "4px" }} />
						)}
					</Button>
				</>
			}
		>
			<AddUserContent />
		</DialogBase>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return {
		...state.dialogs,
		...state.addUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...AddUserStore.actionCreators,
			...DialogStore.actionCreators,
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserDialog);
