import { Box, IconButton, Typography } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { ReactComponent as SelectedSortAscSvg } from "../../../../images/icons/SelectedSortAsc.svg";
import { ReactComponent as SelectedSortDescSvg } from "../../../../images/icons/SelectedSortDesc.svg";
import { ReactComponent as UnselectedSortSvg } from "../../../../images/icons/UnselectedSort.svg";
import {
	IAdminDocumentsRequest,
	IDocument,
	IDocumentsResponse,
} from "../../../../resources/Contracts";
import { DocumentsSortBy } from "../../../../resources/Enums";
import Texts from "../../../../resources/Texts";
import { Service } from "../../../../services/Service";
import { ApplicationState } from "../../../../store";
import Checkbox from "../../../shared/Checkbox";
import * as AdminDocumentsStore from "../AdminDocumentsStore";
import * as DialogStore from "../../../../components/dialogs/DialogStore";

const actionColumnWidth: string = "40px";
const nameColumnWidth: string = "20%";
const categoryColumnWidth: string = "190px";
const creationColumnWidth: string = "170px";
const uploadedByColumnWidth: string = "150px";
const permissionColumnWidth: string = "200px";
const downloadsColumnWidth: string = "89px";
const notificationsColumnWidth: string = "137px";

type AdminDocumentsTableHeaderProps = AdminDocumentsStore.AdminDocumentsState &
	DialogStore.DialogState &
	typeof AdminDocumentsStore.actionCreators & {
		selectedStartDate: string;
		selectedEndDate: string;
	};

const AdminDocumentsTableHeader: React.FC<AdminDocumentsTableHeaderProps> = ({
	selectedStartDate,
	selectedEndDate,
	documentTypeFilter,
	keywordFilter,
	permissionFilter,
	uploaderFilter,
	maxViewsFilter,
	searchText,
	sortBy,
	sortDir,
	rowsPerPage,
	selectedPage,
	selectedBlobUniques,
	selectedTabId,
	setSortBy,
	setSortDir,
	setSelectedBlobUniques,
	setDocumentsCount,
	setDocumentsItems,
	documentsItems,
	setDocumentsRefreshing,
	accessControlDialog,
}) => {
	React.useEffect(() => {
		if (accessControlDialog === null) {
			setDocumentsRefreshing(true);
			setDocumentsItems([]);
			setDocumentsCount(0);
			loadDocuments();
		}
	}, [accessControlDialog]);

	const loadDocuments = async (): Promise<IDocument[]> => {
		if (!rowsPerPage || !selectedPage || !selectedStartDate || !selectedEndDate) {
			return [];
		}

		const request: IAdminDocumentsRequest = {
			itemsPerPage: rowsPerPage,
			pageNumber: selectedPage,
			orderBy: sortBy,
			order: sortDir || "ASC",
			searchString: searchText?.length ? searchText : null,
			filters: {
				publishedFrom: selectedStartDate,
				publishedTo: selectedEndDate,
				keywords: keywordFilter?.length > 0 ? keywordFilter.map((k) => k.id) : null,
				typeIds:
					documentTypeFilter?.length > 0 ? documentTypeFilter.map((dt) => dt.id) : null,
				tabId: selectedTabId,
				permissions:
					permissionFilter?.length > 0 ? permissionFilter.map((c) => c.id) : null,
				uploadedBy: uploaderFilter?.length > 0 ? uploaderFilter.map((u) => u.mail) : null,
				maxViews: maxViewsFilter,
			},
		};

		const response: IDocumentsResponse = await Service.getAdminDocuments(request);

		setDocumentsCount(response.itemsCount);
		setDocumentsItems(response.itemsData);
		setDocumentsRefreshing(false);

		return response.itemsData;
	};

	const { data, isLoading } = useQuery(
		[
			"adminDocumentsData",
			selectedStartDate,
			selectedEndDate,
			documentTypeFilter,
			keywordFilter,
			permissionFilter,
			uploaderFilter,
			maxViewsFilter,
			searchText,
			sortBy,
			sortDir,
			rowsPerPage,
			selectedPage,
			selectedTabId,
		],
		loadDocuments
	);

	React.useEffect(() => {
		setSelectedBlobUniques([]);
	}, [selectedTabId, setSelectedBlobUniques]);

	const handleSortChange = (newSortBy: DocumentsSortBy): void => {
		if (sortBy === newSortBy) {
			if (sortDir === "DESC") {
				setSortBy(null);
				setSortDir(null);
			} else {
				setSortDir("DESC");
			}
		} else {
			setSortBy(newSortBy);
			setSortDir("ASC");
		}
	};

	const handleSelectAll = (selectedValue: boolean): void => {
		if (selectedValue) {
			const unselectedDocuments: IDocument[] = data.filter(
				(d) => !selectedBlobUniques.some((sid) => sid === d.blobUnique)
			);

			setSelectedBlobUniques(
				selectedBlobUniques.concat(unselectedDocuments.map((d) => d.blobUnique))
			);
		} else {
			setSelectedBlobUniques(
				selectedBlobUniques.filter((sid) => !data.some((d) => d.blobUnique === sid))
			);
		}
	};

	const sortByNameSelected: boolean = sortBy === DocumentsSortBy.Title;
	const sortByDateSelected: boolean = sortBy === DocumentsSortBy.Published;

	return (
		<Box
			display="flex"
			flexDirection="row"
			alignItems="center"
			sx={{
				height: "34px",
				border: "1px solid #576079",
				borderRadius: "8px",
				marginBottom: "4px",
				boxSizing: "border-box",
			}}
		>
			<Box display="flex" justifyContent="end" width={actionColumnWidth}>
				<Checkbox
					disabled={isLoading}
					checked={
						(documentsItems?.length > 0 &&
							documentsItems.every((d) =>
								selectedBlobUniques.some((sid) => sid === d.blobUnique)
							)) ||
						false
					}
					onChange={handleSelectAll}
				/>
			</Box>

			<Box display="flex" justifyContent="start" alignItems="center" width={nameColumnWidth}>
				<Typography
					variant="body2"
					sx={{
						marginRight: "5px",
					}}
				>
					{Texts.DocumentsTable.ColumnTitles.DocumentNameAndType}
				</Typography>

				<IconButton onClick={() => handleSortChange(DocumentsSortBy.Title)}>
					{sortByNameSelected ? (
						sortDir === "ASC" ? (
							<SelectedSortAscSvg />
						) : (
							<SelectedSortDescSvg />
						)
					) : (
						<UnselectedSortSvg />
					)}
				</IconButton>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={categoryColumnWidth}
			>
				<Typography
					variant="body2"
					sx={{
						marginRight: "5px",
					}}
				>
					{Texts.DocumentsTable.ColumnTitles.Category}
				</Typography>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={creationColumnWidth}
			>
				<Typography
					variant="body2"
					sx={{
						marginRight: "5px",
					}}
				>
					{Texts.DocumentsTable.ColumnTitles.CreationDate}
				</Typography>

				<IconButton onClick={() => handleSortChange(DocumentsSortBy.Published)}>
					{sortByDateSelected ? (
						sortDir === "ASC" ? (
							<SelectedSortAscSvg />
						) : (
							<SelectedSortDescSvg />
						)
					) : (
						<UnselectedSortSvg />
					)}
				</IconButton>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={uploadedByColumnWidth}
			>
				<Typography
					variant="body2"
					sx={{
						marginRight: "5px",
					}}
				>
					{Texts.DocumentsTable.ColumnTitles.UploadedBy}
				</Typography>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={permissionColumnWidth}
			>
				<Typography
					variant="body2"
					sx={{
						marginRight: "5px",
					}}
				>
					{Texts.DocumentsTable.ColumnTitles.AccessPermissions}
				</Typography>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={`calc(100% - ${actionColumnWidth} - ${nameColumnWidth} - ${categoryColumnWidth} - ${creationColumnWidth} - ${uploadedByColumnWidth} - ${permissionColumnWidth} - ${downloadsColumnWidth} - ${notificationsColumnWidth})`}
			>
				<Typography variant="body2">
					{Texts.DocumentsTable.ColumnTitles.Keywords}
				</Typography>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={downloadsColumnWidth}
			>
				<Typography
					variant="body2"
					sx={{
						marginRight: "5px",
					}}
				>
					{Texts.DocumentsTable.ColumnTitles.MaxViews}
				</Typography>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={notificationsColumnWidth}
			>
				<Typography
					variant="body2"
					sx={{
						marginRight: "5px",
					}}
				>
					{Texts.DocumentsTable.ColumnTitles.SentNotifications}
				</Typography>
			</Box>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return {
		...state.adminDocuments,
		selectedStartDate: state.documents.selectedStartDate,
		selectedEndDate: state.documents.selectedEndDate,
		...state.dialogs,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(AdminDocumentsStore.actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDocumentsTableHeader);
