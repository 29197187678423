import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as DateFunctions from "date-fns";
import * as React from "react";
import { ReactComponent as ExpirationWarningSvg } from "../../../images/icons/ExpirationWarning.svg";
import { INotification } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";

type NotificationProps = {
	notification: INotification;
};

const Notification: React.FC<NotificationProps> = ({ notification }) => {
	let notificationText: string;

	if (notification.isNew) {
		notificationText = Texts.NotificationList.NewDocument.replace(
			"{title}",
			notification.fileTitle
		);
	} else if (notification.isBeforeExpiration) {
		const now: Date = new Date();
		const expirationDate: Date = DateFunctions.addYears(new Date(notification.fileCreated), 2);
		const difference: number = DateFunctions.differenceInCalendarDays(expirationDate, now);

		notificationText = Texts.NotificationList.BeforeExpiration.replace(
			"{title}",
			notification.fileTitle
		)
			.replace("{expiration}", difference.toString())
			.replace("{unit}", difference > 1 ? "days" : "day");
	} else if (notification.isExpired) {
		const now: Date = new Date();
		const expirationDate: Date = DateFunctions.addYears(new Date(notification.fileCreated), 2);
		const difference: number = DateFunctions.differenceInCalendarDays(now, expirationDate);

		notificationText = Texts.NotificationList.ExpiredDocument.replace(
			"{title}",
			notification.fileTitle
		).replace("{expiration}", difference.toString());
	}

	return (
		<Box
			display="flex"
			flexDirection="column"
			width="100%"
			sx={{
				padding: "14px 0",
				borderBottom: "1px solid rgba(87, 96, 121, 0.6)",
			}}
		>
			<Box
				display="flex"
				sx={{
					mb: "4px",
				}}
			>
				<Typography variant="body1" sx={{ color: "#9AA0AF" }}>
					{DateFunctions.format(new Date(notification.fileCreated), "d MMM, hh:mm a")}
				</Typography>

				{(notification.isBeforeExpiration || notification.isExpired) && (
					<Typography
						variant="body1"
						sx={{
							color: "#FF3333",
							ml: "15px",
						}}
					>
						<ExpirationWarningSvg style={{ marginRight: "4px" }} />
						{Texts.NotificationList.ExpirationNote}
					</Typography>
				)}
			</Box>

			<Box display="flex" flex={1}>
				<Typography
					variant="body1"
					sx={{
						fontWeight: 600,
					}}
				>
					{notificationText}
				</Typography>
			</Box>
		</Box>
	);
};

export default Notification;
