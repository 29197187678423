import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { ICompany } from "../../../../resources/Contracts";
import Texts from "../../../../resources/Texts";
import { Service } from "../../../../services/Service";
import { ApplicationState } from "../../../../store";
import { actionCreators as AlertStoreActionCreators } from "../../../../store/AlertStore";
import { handleErrorMessage } from "../../../../utils/Utils";
import Multiselect from "../../../shared/Multiselect";
import * as AdminDocumentsStore from "../AdminDocumentsStore";

type AdminAccessPermissionFilterProps = AdminDocumentsStore.AdminDocumentsState &
	typeof AdminDocumentsStore.actionCreators &
	typeof AlertStoreActionCreators & {
		onClose: () => void;
	};

const AdminAccessPermissionFilter: React.FC<AdminAccessPermissionFilterProps> = ({
	permissionFilter,
	setPermissionFilter,
	onClose,
	addErrorAlert,
}) => {
	const [searchValue, setSearchValue] = React.useState<string>("");

	const loadPermissions = async (): Promise<ICompany[]> => {
		try {
			if (searchValue.length > 0) {
				return await Service.getCompanies(searchValue);
			} else {
				return [];
			}
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const { isLoading, data } = useQuery(["accessPermissionData", searchValue], loadPermissions);

	const handleChange = (value: ICompany[]): void => {
		setPermissionFilter(value);
	};

	const handleClose = (): void => {
		setPermissionFilter([]);

		onClose();
	};

	return (
		<Multiselect
			items={data}
			selectedItems={permissionFilter}
			textField="companyName"
			valueField="id"
			label={Texts.DocumentsTable.AccessPermission}
			onChange={handleChange}
			onClose={handleClose}
			onSearchChange={setSearchValue}
			showFilterInput
			showLoadingIndicator={isLoading}
			sx={{
				marginLeft: "12px",
			}}
		/>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.adminDocuments;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ ...AdminDocumentsStore.actionCreators, ...AlertStoreActionCreators },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminAccessPermissionFilter);
