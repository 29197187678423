import { Menu, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { ReactComponent as UserSvg } from "../../../images/icons/User.svg";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { ApplicationState } from "../../../store";
import { UserState } from "../../../store/UserStore";

type UserMenuProps = UserState;

const UserMenu: React.FC<UserMenuProps> = ({ user }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open: boolean = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
	};

	const handleLogout = (): void => {
		Service.logout();
	};

	return (
		<>
			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				sx={{
					cursor: "pointer",
				}}
				onClick={handleClick}
			>
				<Box
					sx={{
						width: "24px",
						height: "24px",
						marginRight: "3px",
					}}
				>
					<UserSvg />
				</Box>

				<Typography variant="body1" component="span">
					{user.username}
				</Typography>
			</Box>

			<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<MenuItem onClick={handleLogout}>{Texts.UserMenu.Logout}</MenuItem>
			</Menu>
		</>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.userState;
};

export default connect(mapStateToProps)(UserMenu);
