import { Box, IconButton, Typography } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { UsersSortBy } from "../../../resources/Enums";
import Texts from "../../../resources/Texts";
import { ApplicationState } from "../../../store";
import * as UserManagementStore from "../UserManagementStore";
import { ReactComponent as SelectedSortAscSvg } from "../../../images/icons/SelectedSortAsc.svg";
import { ReactComponent as SelectedSortDescSvg } from "../../../images/icons/SelectedSortDesc.svg";
import { ReactComponent as UnselectedSortSvg } from "../../../images/icons/UnselectedSort.svg";

type UserManagementTableHeaderProps = UserManagementStore.UserManagementState &
	typeof UserManagementStore.actionCreators & {};

const UserManagementTableHeader: React.FC<UserManagementTableHeaderProps> = ({
	sortBy,
	sortDir,
	setSortBy,
	setSortDir,
}) => {
	const handleSortChange = (newSortBy: UsersSortBy): void => {
		if (sortBy === newSortBy) {
			if (sortDir === "DESC") {
				setSortBy(null);
				setSortDir(null);
			} else {
				setSortDir("DESC");
			}
		} else {
			setSortBy(newSortBy);
			setSortDir("ASC");
		}
	};

	const sortByNameSelected: boolean = sortBy === UsersSortBy.Name;
	const sortByEmailSelected: boolean = sortBy === UsersSortBy.Email;

	const nameColumnWidth: string = "30%";
	const clientColumnWidth: string = "170px";
	const emailColumnWidth: string = "320px";
	const accessPermissionColumnWidth: string = "330px";
	const lastActionColumnWidth: string = "260px";

	return (
		<Box
			display="flex"
			flexDirection="row"
			alignItems="center"
			sx={{
				height: "34px",
				border: "1px solid #576079",
				borderRadius: "8px",
				marginBottom: "4px",
				boxSizing: "border-box",
				paddingRight: "35px",
				paddingLeft: "10px",
			}}
		>
			<Box display="flex" justifyContent="start" alignItems="center" width={nameColumnWidth}>
				<Typography
					variant="body2"
					sx={{
						marginRight: "5px",
					}}
				>
					{Texts.UserManagement.ColumnTitles.Name}
				</Typography>

				<IconButton onClick={() => handleSortChange(UsersSortBy.Name)}>
					{sortByNameSelected ? (
						sortDir === "ASC" ? (
							<SelectedSortAscSvg />
						) : (
							<SelectedSortDescSvg />
						)
					) : (
						<UnselectedSortSvg />
					)}
				</IconButton>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={clientColumnWidth}
			>
				<Typography
					variant="body2"
					sx={{
						marginRight: "5px",
					}}
				>
					{Texts.UserManagement.ColumnTitles.Customer}
				</Typography>
			</Box>

			<Box display="flex" justifyContent="start" alignItems="center" width={emailColumnWidth}>
				<Typography
					variant="body2"
					sx={{
						marginRight: "5px",
					}}
				>
					{Texts.UserManagement.ColumnTitles.EmailAddress}
				</Typography>

				<IconButton onClick={() => handleSortChange(UsersSortBy.Email)}>
					{sortByEmailSelected ? (
						sortDir === "ASC" ? (
							<SelectedSortAscSvg />
						) : (
							<SelectedSortDescSvg />
						)
					) : (
						<UnselectedSortSvg />
					)}
				</IconButton>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={accessPermissionColumnWidth}
			>
				<Typography
					variant="body2"
					sx={{
						marginRight: "5px",
					}}
				>
					{Texts.UserManagement.ColumnTitles.AccessPermission}
				</Typography>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={lastActionColumnWidth}
			>
				<Typography
					variant="body2"
					sx={{
						marginRight: "5px",
					}}
				>
					{Texts.UserManagement.ColumnTitles.LastAction}
				</Typography>
			</Box>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.userManagement;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(UserManagementStore.actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementTableHeader);
