import { Box, CircularProgress, IconButton } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { connect } from "react-redux";
import { ReactComponent as ArrowLeftSvg } from "../../../images/icons/ArrowLeft_small.svg";
import { ReactComponent as ArrowRightSvg } from "../../../images/icons/ArrowRight_small.svg";
import { IFileDownloadDataResult } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import * as AlertStore from "../../../store/AlertStore";
import { handleErrorMessage } from "../../../utils/Utils";

type FilePreviewDialogContentProps = typeof AlertStore.actionCreators & {
	fileUniqueId: string;
};

const FilePreviewDialogContent: React.FC<FilePreviewDialogContentProps> = ({
	fileUniqueId,
	addErrorAlert,
}) => {
	const [numPages, setNumPages] = React.useState(0);
	const [pageNumber, setPageNumber] = React.useState(1);
	const [fileLink, setFileLink] = React.useState<string>(null);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);

	const loadFileLink = React.useCallback(async (): Promise<void> => {
		try {
			const fileDownloadData: IFileDownloadDataResult = await Service.getPdfFile(
				fileUniqueId,
				Texts.Dialogs.Actions.View
			);

			if (fileDownloadData?.fileLink.length > 0) {
				setFileLink(fileDownloadData.fileLink);
			}
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}

		setIsLoading(false);
	}, [fileUniqueId, addErrorAlert]);

	React.useEffect(() => {
		loadFileLink();
	}, [loadFileLink]);

	const handleDocumentLoadSuccess = ({ numPages }): void => {
		setNumPages(numPages);
	};

	const handlePrevPageClick = (): void => {
		setPageNumber((v) => v - 1);
	};

	const handleNextPageClick = (): void => {
		setPageNumber((v) => v + 1);
	};

	const onHandleCallback = (callback, password): void => {
		// Cancel button handler
		if (password === null) {
			// this.props.onCancel();
			return null;
		}

		callback(password);
	};

	Document.defaultProps = {
		error: "Failed to load PDF file.",
		loading: "Loading PDF...",
		noData: "No PDF file specified.",
		onPassword: (callback, reason) => {
			const { PasswordResponses } = pdfjs;
			switch (reason) {
				case PasswordResponses.NEED_PASSWORD: {
					// eslint-disable-next-line no-alert
					const password = prompt("Enter the password to open this PDF file.");
					onHandleCallback(callback, password);
					break;
				}
				case PasswordResponses.INCORRECT_PASSWORD: {
					// eslint-disable-next-line no-alert
					const password = prompt("Invalid password. Please try again.");
					onHandleCallback(callback, password);
					break;
				}
				default:
			}
		},
	};

	if (isLoading) {
		return (
			<Box display="flex" alignItems="center" flexDirection="column" height="100%">
				<Box
					sx={{
						width: "532px",
						height: "654px",
						color: "#ffffff",
					}}
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<CircularProgress color="inherit" />
				</Box>
			</Box>
		);
	}

	return (
		<Box display="flex" alignItems="center" flexDirection="column" height="100%">
			<Box
				display="flex"
				justifyContent="center"
				sx={{
					minWidth: "640px",
					height: "654px",
					overflow: "auto",
				}}
			>
				<Document
					file={fileLink}
					onLoadSuccess={handleDocumentLoadSuccess}
					onLoadError={console.error}
				>
					<Page pageNumber={pageNumber} height={640} />
				</Document>
			</Box>

			<Box display="flex" flexDirection="row" justifyContent="center" flex={1}>
				<Box
					component="span"
					sx={{
						color: "#9AA0AF",
						fontSize: "14px",
					}}
				>
					{Texts.Dialogs.FilePreviewDialog.PageSelectotLabel}:
				</Box>

				<IconButton disabled={pageNumber === 1} onClick={handlePrevPageClick}>
					<ArrowLeftSvg />
				</IconButton>

				<Box
					component="span"
					sx={{
						color: "#FFFFFF",
						fontSize: "14px",
					}}
				>
					{`${pageNumber} / ${numPages}`}
				</Box>

				<IconButton disabled={pageNumber >= numPages} onClick={handleNextPageClick}>
					<ArrowRightSvg />
				</IconButton>
			</Box>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(AlertStore.actionCreators, dispatch);
};

export default connect(null, mapDispatchToProps)(FilePreviewDialogContent);
