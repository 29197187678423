import { Box, CircularProgress } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { IDocumentType } from "../../../../resources/Contracts";
import Texts from "../../../../resources/Texts";
import { Service } from "../../../../services/Service";
import { ApplicationState } from "../../../../store";
import Multiselect from "../../../shared/Multiselect";
import * as DocumentsStore from "../../documentsTable/DocumentsStore";

type DocumentTypeFilterProps = DocumentsStore.DocumentsState &
	typeof DocumentsStore.actionCreators & {
		onClose: () => void;
	};

const DocumentTypeFilter: React.FC<DocumentTypeFilterProps> = ({
	documentTypeFilter,
	setDocumentTypeFilter,
	onClose,
}) => {
	const { isLoading, data } = useQuery("documentTypeData", () => Service.getDocumentTypes());

	const handleChange = (value: IDocumentType[]): void => {
		setDocumentTypeFilter(value);
	};

	const handleClose = (): void => {
		setDocumentTypeFilter([]);

		onClose();
	};

	if (isLoading) {
		return (
			<Box
				sx={{
					marginLeft: "12px",
					color: "#ffffff",
				}}
			>
				<CircularProgress size={15} color="inherit" />
			</Box>
		);
	}

	return (
		<Multiselect
			items={data}
			selectedItems={documentTypeFilter}
			textField="typeExtension"
			valueField="id"
			label={Texts.DocumentsTable.DocumentTypeFilterLabel}
			onChange={handleChange}
			onClose={handleClose}
			showFilterInput={false}
			sx={{
				marginLeft: "12px",
			}}
		/>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.documents;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(DocumentsStore.actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypeFilter);
