import React from "react";
import { ThemeProvider } from "@mui/material";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import { Service } from "./services/Service";
import createStore from "./store/configureStore";
import { theme } from "./theme/Theme";
import { pdfjs } from "react-pdf";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { actionCreators as DialogStoreActionCreators } from "./components/dialogs/DialogStore";
import { actionCreators as UserStoreActionCreators } from "./store/UserStore";

import Texts from "./resources/Texts";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const store = createStore();

const startApp = async (): Promise<void> => {
	let user: any;

	try {
		user = await Service.getCurrentUser();
	} catch (error) {
		console.error(error);
	}

	if (user && !user.allowed) {
		let errorText = Texts.Dialogs.FailedLoginDialog.Content1;

		store.dispatch(
			DialogStoreActionCreators
				.setFailedLoginDialogState({
					errorMessage: errorText,
					open: true
				})
		);

		store.dispatch(UserStoreActionCreators.setUser(null));
		user = null;

		console.log(errorText);
	}

	if (user) {
		if (user.fileUniqueId && user.fileUniqueId.length === 36) {
			store.dispatch(UserStoreActionCreators.setFile(user.fileUniqueId));
			store.dispatch(UserStoreActionCreators.setSectionId(Number.parseInt(user.sectionId)));
			user.fileUniqueId = null;
			user.sectionId = null;
			await Service.updateCurrentUser();
		}
		store.dispatch(UserStoreActionCreators.setUser(user));
	}

	const reactQueryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
			},
		},
	});

	const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
	root.render(
		<BrowserRouter>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Provider store={store}>
					<ThemeProvider theme={theme}>
						<QueryClientProvider client={reactQueryClient}>
							<App />
						</QueryClientProvider>
					</ThemeProvider>
				</Provider>
			</LocalizationProvider>
		</BrowserRouter>
	);
};

startApp();
