import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQueryClient } from "react-query";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IUploadFileRequest } from "../../resources/Contracts";
import { Routing } from "../../resources/Routes";
import Texts from "../../resources/Texts";
import { Service } from "../../services/Service";
import { ApplicationState } from "../../store";
import { UserState } from "../../store/UserStore";
import { actionCreators as DialogStoreActionCreators } from "../dialogs/DialogStore";
import AccessControl from "./AccessControl";
import DocumentTitleAndKeywords from "./DocumentTitleAndKeywords";
import * as DocumentUploadStore from "./DocumentUploadStore";
import FileUpload from "./FileUpload";

type UploadDocumentContainerProps = DocumentUploadStore.DocumentUploadState &
	UserState &
	typeof DialogStoreActionCreators &
	typeof DocumentUploadStore.actionCreators;

const UploadDocumentContainer: React.FC<UploadDocumentContainerProps> = ({
	documentName,
	keywords,
	permittedUsers,
	sectionId,
	file,
	user,
	setFailedUploadDialogState,
	clearDocument,
}) => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [uploadInProgress, setUploadInProgress] = React.useState<boolean>(false);

	const uploadButtonDisabled: boolean = !documentName || !sectionId || !file;

	React.useEffect(() => {
		return () => {
			clearDocument();
		};
	}, [clearDocument]);

	const handleUpload = async (): Promise<void> => {
		setUploadInProgress(true);

		let isError: boolean = false;

		if (sectionId === 1 && (!permittedUsers || permittedUsers.length < 1)) {
			setFailedUploadDialogState({
				errorMessage: Texts.Dialogs.FailedUploadDialog.Content3,
				open: true,
			});

			setUploadInProgress(false);

			return;
		}

		try {
			const request: IUploadFileRequest = {
				file: file,
				publishedByEmail: user.loginName,
				publishedByName: user.username,
				sectionId: sectionId,
				title: documentName,
				keywords: keywords?.split(","),
				permitted: permittedUsers,
			};

			await Service.uploadFileByChunks(
				request,
				() => {
					queryClient.invalidateQueries("adminDocumentsData");
				},
				() => {
					setFailedUploadDialogState({
						errorMessage: Texts.Dialogs.FailedUploadDialog.Content4,
						open: true,
					});

					setUploadInProgress(false);

					isError = true;
				}
			);
		} catch (error) {
			setFailedUploadDialogState({
				errorMessage: error,
				open: true,
			});

			setUploadInProgress(false);

			isError = true;
		}

		if (!isError) navigate(-1);
	};

	return (
		<Box display="flex" flexDirection="column" flex={1}>
			<DocumentTitleAndKeywords />

			<AccessControl />

			<FileUpload />

			<Box
				display="flex"
				justifyContent="end"
				sx={{
					mt: "14px",
				}}
			>
				<Link to={Routing.getHomeViewUrl()}>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						sx={{
							fontWeight: "600",
							height: "36px",
							padding: "0 8px",
							color: "#ffffff",
							border: "1px solid #FFFFFF",
							borderRadius: "4px",
							mr: "24px",
						}}
					>
						{Texts.UploadView.Buttons.Back}
					</Box>
				</Link>

				<Button
					onClick={handleUpload}
					sx={{
						background: "#FFFFFF",
						borderRadius: "4px",
						color: "#151724",
						"&:hover": {
							backgroundColor: "#9AA0AF",
						},
					}}
					disabled={uploadButtonDisabled || uploadInProgress}
				>
					{Texts.UploadView.Buttons.Upload}

					{uploadInProgress && (
						<CircularProgress color="inherit" size="15px" sx={{ ml: "4px" }} />
					)}
				</Button>
			</Box>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return { ...state.documentUpload, ...state.userState };
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ ...DialogStoreActionCreators, ...DocumentUploadStore.actionCreators },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumentContainer);
