import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as DateFunctions from "date-fns";
import React from "react";
import { IDocument } from "../../../../resources/Contracts";
import Checkbox from "../../../shared/Checkbox";
import KeywordsColumn from "../../documentsTable/table/KeywordsColumn";
import TitleColumn from "../../documentsTable/table/TitleColumn";
import PermissionsColumn from "./PermissionsColumn";
import RowMenu from "./RowMenu";

const actionColumnWidth: string = "40px";
const nameColumnWidth: string = "20%";
const categoryColumnWidth: string = "190px";
const creationColumnWidth: string = "170px";
const uploadedByColumnWidth: string = "150px";
const permissionColumnWidth: string = "200px";
const downloadsColumnWidth: string = "89px";
const notificationsColumnWidth: string = "137px";

type AdminDocumentsTableRowProps = {
	data: IDocument;
	selected: boolean;
	onSelect: (doc: IDocument, selected: boolean) => void;
};

const AdminDocumentsTableRow: React.FC<AdminDocumentsTableRowProps> = ({
	data,
	selected,
	onSelect,
}) => {
	const handleSelect = (selected: boolean): void => {
		onSelect(data, selected);
	};

	return (
		<Box
			display="flex"
			flexDirection="row"
			alignItems="center"
			sx={{
				height: "46px",
				border: "1px solid transparent",
				borderRadius: "8px",
				boxSizing: "border-box",
				marginTop: "8px",
				backgroundColor: selected ? "#57607999" : "#57607933",
				"&:hover": {
					borderColor: "#005A85",
				},
			}}
		>
			<Box display="flex" justifyContent="end" width={actionColumnWidth}>
				<Checkbox checked={selected || false} onChange={handleSelect} />
			</Box>

			<TitleColumn data={data} columnWidth={nameColumnWidth} />

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={categoryColumnWidth}
			>
				<Typography variant="tableItem" component="span">
					{data.section?.sectionName}
				</Typography>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={creationColumnWidth}
			>
				<Typography variant="tableItem" component="span">
					{DateFunctions.format(new Date(data.uploaded), "d MMM Y, hh:mm:ss a")}
				</Typography>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={uploadedByColumnWidth}
			>
				<Typography variant="tableItem" component="span">
					{data.uploadedByName}
				</Typography>
			</Box>

			<PermissionsColumn
				id={data.id}
				data={data?.permissions}
				columnWidth={permissionColumnWidth}
			/>

			<KeywordsColumn
				id={data.id}
				keywords={data.keywords}
				columnWidth={`calc(100% - ${actionColumnWidth} - ${nameColumnWidth} - ${categoryColumnWidth} - ${creationColumnWidth} - ${uploadedByColumnWidth} - ${permissionColumnWidth} - ${downloadsColumnWidth} - ${notificationsColumnWidth})`}
			/>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={downloadsColumnWidth}
			>
				<Typography variant="tableItem" component="span">
					{data.maxViews}
				</Typography>
			</Box>

			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				width={notificationsColumnWidth}
				sx={{
					boxSizing: "border-box",
					paddingRight: "20px",
				}}
			>
				<Typography variant="tableItem" component="span">
					{data.notifications}
				</Typography>

				<RowMenu data={data} />
			</Box>
		</Box>
	);
};

export default AdminDocumentsTableRow;
