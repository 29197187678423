import React from "react";
import DocumentsHeader from "../../components/documents/documentsTable/DocumentsHeader";
import DocumentsTableContainer from "../../components/documents/documentsTable/DocumentsTableContainer";

type DocumentsViewProps = {};

const DocumentsView: React.FC<DocumentsViewProps> = () => {
	return (
		<>
			<DocumentsHeader />

			<DocumentsTableContainer />
		</>
	);
};

export default DocumentsView;
