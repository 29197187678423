import { Drawer as MuiDrawer } from "@mui/material";
import { CSSObject, styled, Theme } from "@mui/material/styles";

const sideBarOpenedWidth: number = 280;
const sideBarClosedWidth: number = 110;

const openedMixin = (theme: Theme): CSSObject => ({
	width: sideBarOpenedWidth,
	overflowX: "hidden",
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
});

const closedMixin = (theme: Theme): CSSObject => ({
	width: sideBarClosedWidth,
	overflowX: "hidden",
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
});

export const SideBarDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
	({ theme, open }) => ({
		flexShrink: 0,
		whiteSpace: "nowrap",
		boxSizing: "border-box",
		...(open && {
			...openedMixin(theme),
			"& .MuiDrawer-paper": openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			"& .MuiDrawer-paper": closedMixin(theme),
		}),
	})
);
