import { Action, Reducer } from "redux";
import { ICompany, IUploader } from "../../../resources/Contracts";

export interface AddUserState {
	selectedCompanies: ICompany[];
	selectedUsers: IUploader[];
}

interface SetCompaniesAddUserStateAction {
	type: "SET_COMPANIES_ADD_USER_STATE";
	payload: ICompany[];
}

interface SetUsersAddUserStateAction {
	type: "SET_USERS_ADD_USER_STATE";
	payload: IUploader[];
}

interface ClearAddUserStateAction {
	type: "CLEAR_ADD_USER_STATE";
}

type KnownAction =
	| SetCompaniesAddUserStateAction
	| SetUsersAddUserStateAction
	| ClearAddUserStateAction;

export const actionCreators = {
	setCompaniesAddUserState: (value: ICompany[]) =>
	({
		type: "SET_COMPANIES_ADD_USER_STATE",
		payload: value,
	} as SetCompaniesAddUserStateAction),

	setUsersAddUserState: (value: IUploader[]) =>
	({
		type: "SET_USERS_ADD_USER_STATE",
		payload: value,
	} as SetUsersAddUserStateAction),

	clearAddUserState: () =>
	({
		type: "CLEAR_ADD_USER_STATE",
	} as ClearAddUserStateAction),
};

export const reducer: Reducer<AddUserState> = (
	state: AddUserState | undefined,
	incomingAction: Action
) => {
	if (state === undefined) {
		return {
			selectedCompanies: [],
			selectedUsers: []
		};
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "SET_COMPANIES_ADD_USER_STATE":
			return { ...state, selectedCompanies: action.payload };

		case "SET_USERS_ADD_USER_STATE":
			return { ...state, selectedUsers: action.payload };

		case "CLEAR_ADD_USER_STATE":
			return {
				selectedCompanies: [],
				selectedUsers: []
			};

		default:
			return state;
	}
};
