import { Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import Texts from "../../resources/Texts";
import { UserManagementState } from "./UserManagementStore";

type UserManagementTableTitleProps = UserManagementState & {};

const UserManagementTableTitle: React.FC<UserManagementTableTitleProps> = ({ usersCount }) => {
	return (
		<Typography variant="h3">
			{Texts.UserManagement.TableTitle}

			{usersCount ? ` (${usersCount})` : null}
		</Typography>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.userManagement;
};

export default connect(mapStateToProps)(UserManagementTableTitle);
