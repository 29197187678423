import { CircularProgress, Tab, Tabs } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { IAdminTab } from "../../../resources/Contracts";
import { Service } from "../../../services/Service";
import { ApplicationState } from "../../../store";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import { handleErrorMessage } from "../../../utils/Utils";
import * as AdminDocumentsStore from "./AdminDocumentsStore";

type CompanyTabsProps = AdminDocumentsStore.AdminDocumentsState &
	typeof AdminDocumentsStore.actionCreators &
	typeof AlertStoreActionCreators;

const tabStyle: SxProps = {
	color: "#ffffff",
	fontSize: "14px",
	"&.Mui-selected": {
		color: "#ffffff",
	},
};

const CompanyTabs: React.FC<CompanyTabsProps> = ({
	selectedTabId,
	setSelectedTabId,
	addErrorAlert,
}) => {
	const loadTabs = async (): Promise<IAdminTab[]> => {
		try {
			return await Service.getAdminTabs();
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const { isLoading, data } = useQuery("adminCompanyTabs", loadTabs);

	if (isLoading) {
		return (
			<Box
				sx={{
					marginLeft: "12px",
					color: "#ffffff",
				}}
			>
				<CircularProgress size={15} color="inherit" />
			</Box>
		);
	}

	const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
		setSelectedTabId(newValue);
	};

	return (
		<Box
			display="flex"
			alignItems="bottom"
			sx={{
				maxWidth: "100%",
			}}
		>
			<Tabs
				value={selectedTabId}
				onChange={handleTabChange}
				variant="scrollable"
				scrollButtons="auto"
				TabIndicatorProps={{
					sx: {
						backgroundColor: "#ffffff",
					},
				}}
				TabScrollButtonProps={{
					sx: {
						color: "#ffffff",
					},
				}}
			>
				{data?.map((tab, index) => (
					<Tab
						id={`companyTab-${tab.id}`}
						key={`companyTab-${index}`}
						label={`${tab.title} (${tab.count})`}
						value={tab.id}
						sx={tabStyle}
					/>
				))}
			</Tabs>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.adminDocuments;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ ...AdminDocumentsStore.actionCreators, ...AlertStoreActionCreators },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTabs);
