import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as DateFunctions from "date-fns";
import React from "react";
import { connect } from "react-redux";
import { ReactComponent as DownloadSvg } from "../../../../images/icons/Download.svg";
import { IDocument, IFileDownloadData } from "../../../../resources/Contracts";
import Texts from "../../../../resources/Texts";
import { Service } from "../../../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../../../store/AlertStore";
import { handleErrorMessage } from "../../../../utils/Utils";
import Checkbox from "../../../shared/Checkbox";
import KeywordsColumn from "./KeywordsColumn";
import TitleColumn from "./TitleColumn";

type DocumentsTableRowProps = typeof AlertStoreActionCreators & {
	data: IDocument;
	selected: boolean;
	onSelect: (doc: IDocument, selected: boolean) => void;
};

const DocumentsTableRow: React.FC<DocumentsTableRowProps> = ({
	data,
	selected,
	onSelect,
	addErrorAlert,
}) => {
	const handleDownloadClick = async (): Promise<void> => {
		try {
			const fileDownloadData: IFileDownloadData[] = await Service.getFileDownloadLinks(
				[data.blobUnique],
				Texts.DocumentsTable.BatchActions.Download
			);

			fileDownloadData.forEach((link) => {
				if (link.result.fileLink) {
					const aElement: HTMLAnchorElement = document.createElement("a");
					aElement.href = link.result.fileLink;
					aElement.download = link.result.fileName;

					document.body.appendChild(aElement);

					aElement.click();
					aElement.remove();
				}
			});
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const handleSelect = (selected: boolean): void => {
		onSelect(data, selected);
	};

	return (
		<Box
			display="flex"
			flexDirection="row"
			alignItems="center"
			sx={{
				height: "46px",
				border: "1px solid transparent",
				borderRadius: "8px",
				boxSizing: "border-box",
				paddingRight: "20px",
				marginTop: "8px",
				backgroundColor: selected ? "#57607999" : "#57607933",
				"&:hover": {
					borderColor: "#005A85",
				},
			}}
		>
			<Box display="flex" justifyContent="end" width="40px">
				<Checkbox checked={selected || false} onChange={handleSelect} />
			</Box>

			<TitleColumn data={data} columnWidth="36%" />

			<Box display="flex" justifyContent="start" alignItems="center" width="230px">
				<Typography variant="tableItem" component="span">
					{DateFunctions.format(new Date(data.uploaded), "d MMM Y, hh:mm:ss a")}
				</Typography>
			</Box>

			<KeywordsColumn
				id={data.id}
				columnWidth="calc(100% - 40px - 36% - 230px - 134px)"
				keywords={data.keywords}
			/>

			<Box display="flex" justifyContent="end" alignItems="center" width="134px">
				<Box
					component="span"
					display="flex"
					alignItems="center"
					onClick={handleDownloadClick}
					sx={{
						cursor: "pointer",
					}}
				>
					<Box
						component="span"
						sx={{
							marginRight: "6px",
						}}
					>
						<DownloadSvg
							style={{
								fill: "#06A3ED",
							}}
						/>
					</Box>

					<Box
						component="span"
						sx={{
							color: "#06A3ED",
							fontSize: "14px",
							lineHeight: "20px",
							fontWeight: "600",
							fontFamily: "Source Sans Pro",
						}}
					>
						{Texts.DocumentsTable.Download.toUpperCase()}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(AlertStoreActionCreators, dispatch);
};

export default connect(null, mapDispatchToProps)(DocumentsTableRow);
