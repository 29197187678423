import { Box } from "@mui/system";
import React from "react";
import TextSeparator from "../../shared/TextSeparator";
import BatchActions from "./BatchActions";
import DocumentSearchInput from "./DocumentSearchInput";
import DocumentsTableTitle from "./DocumentsTableTitle";
import DocumentsTableFilter from "./filter/DocumentsTableFilter";
import DocumentsTable from "./table/DocumentsTable";

type DocumentsTableContainerProps = {};

const DocumentsTableContainer: React.FC<DocumentsTableContainerProps> = () => {
	return (
		<Box
			display="flex"
			flex={1}
			flexDirection="column"
			sx={{
				backgroundColor: "#25293D",
				borderRadius: "8px",
				paddingTop: "20px",
				paddingLeft: "20px",
				paddingRight: "20px",
				paddingBottom: "10px",
				height: "calc(100% - 96px)",
				boxSizing: "border-box",
			}}
		>
			<Box
				display="flex"
				flexDirection="column"
				sx={{
					marginBottom: "5px",
				}}
			>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between"
					height="38px"
					sx={{
						marginBottom: "5px",
					}}
				>
					<Box display="flex" flexDirection="row" alignItems="center">
						<DocumentsTableTitle />

						<TextSeparator />

						<DocumentSearchInput />
					</Box>

					<BatchActions />
				</Box>

				<Box display="flex" flexDirection="row" alignItems="center">
					<DocumentsTableFilter />
				</Box>
			</Box>

			<DocumentsTable />
		</Box>
	);
};

export default DocumentsTableContainer;
