import { Button, Menu, MenuItem, useTheme } from "@mui/material";
import React from "react";
import { ReactComponent as FilterSvg } from "../../../../images/icons/Filter.svg";
import Texts from "../../../../resources/Texts";
import AdminAccessPermissionFilter from "./AdminAccessPermissionFilter";
import AdminDocumentTypeFilter from "./AdminDocumentTypeFilter";
import AdminDownloadsFilter from "./AdminDownloadsFilter";
import AdminKeywordFilter from "./AdminKeywordFilter";
import AdminUploaderFilter from "./AdminUploaderFilter";

const filterMenuOptions: string[] = [
	"Keywords",
	"Document Type",
	"Uploaded by",
	"Access permissions",
	"Views",
];

type DocumentsTableFilterProps = {};

const AdminDocumentsTableFilter: React.FC<DocumentsTableFilterProps> = () => {
	const theme = useTheme();

	const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement>(null);
	const [selectedFilters, setSelectedFilters] = React.useState<string[]>([]);

	const filterMenuOpen: boolean = Boolean(menuAnchorEl);

	const handleAddFilterClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleFilterMenuClose = (): void => {
		setMenuAnchorEl(null);
	};

	const handleFilterMenuSelect = (filterOption: string): void => {
		if (selectedFilters.some((x) => x === filterOption)) {
			setSelectedFilters((value) => value.filter((x) => x !== filterOption));
		} else {
			const newValue: string[] = selectedFilters.slice();
			newValue.push(filterOption);

			setSelectedFilters(newValue);
		}
	};

	return (
		<>
			<Button
				onClick={handleAddFilterClick}
				disableRipple
				variant="contained"
				sx={{
					color: theme.palette.text.secondary,
					fontWeight: 600,
					fontSize: "14px",
					backgroundColor: filterMenuOpen ? "#9AA0AF" : "#ffffff",
					"&:hover": {
						backgroundColor: "#9AA0AF",
					},
				}}
			>
				<FilterSvg />
				{Texts.DocumentsTable.AddFilter}
			</Button>

			<Menu anchorEl={menuAnchorEl} open={filterMenuOpen} onClose={handleFilterMenuClose}>
				{filterMenuOptions.map((filterOption) => (
					<MenuItem
						key={filterOption}
						onClick={() => handleFilterMenuSelect(filterOption)}
						sx={{
							padding: "6px 8px",
							"&:hover": {
								backgroundColor: "#57607999",
							},
						}}
					>
						{filterOption}
					</MenuItem>
				))}
			</Menu>

			{selectedFilters.some((f) => f === "Keywords") && (
				<AdminKeywordFilter onClose={() => handleFilterMenuSelect("Keywords")} />
			)}

			{selectedFilters.some((f) => f === "Document Type") && (
				<AdminDocumentTypeFilter onClose={() => handleFilterMenuSelect("Document Type")} />
			)}

			{selectedFilters.some((f) => f === "Uploaded by") && (
				<AdminUploaderFilter onClose={() => handleFilterMenuSelect("Uploaded by")} />
			)}

			{selectedFilters.some((f) => f === "Access permissions") && (
				<AdminAccessPermissionFilter onClose={() => handleFilterMenuSelect("Access permissions")} />
			)}

			{selectedFilters.some((f) => f === "Views") && (
				<AdminDownloadsFilter onClose={() => handleFilterMenuSelect("Views")} />
			)}
		</>
	);
};

export default AdminDocumentsTableFilter;
