import { Box, CircularProgress, Typography } from "@mui/material";
import * as React from "react";
import { useQuery } from "react-query";
import { ReactComponent as PlusSvg } from "../../../images/icons/Plus.svg";
import { IAccessControlItem, IRecipientDetails } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import RecipientForm from "./RecipientForm";

type NotificationRecipientsProps = {
	fileId: number;
	recipients: IRecipientDetails[];
	onChange: (value: IRecipientDetails[]) => void;
};

const NotificationRecipients: React.FC<NotificationRecipientsProps> = ({
	fileId,
	recipients,
	onChange,
}) => {
	const loadAvailableRecipients = async (): Promise<IAccessControlItem[]> => {
		if (!fileId) {
			return [];
		}

		return await Service.getFileAccessList(fileId);
	};
	const { isLoading, data } = useQuery(["availableRecipients", fileId], loadAvailableRecipients);

	const handleAddRecipient = (): void => {
		const recipientsCopy: IRecipientDetails[] = recipients.slice();

		recipientsCopy.push({
			email: null,
			companyName: null,
			firstName: null,
			lastName: null,
		});

		onChange(recipientsCopy);
	};

	const handleFormChange = (recipient: IRecipientDetails, index: number): void => {
		const recipientsCopy: IRecipientDetails[] = recipients.slice();

		recipientsCopy[index] = recipient;

		onChange(recipientsCopy);
	};

	if (isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				flex={1}
				sx={{
					color: "#ffffff",
				}}
			>
				<CircularProgress color="inherit" />
			</Box>
		);
	}

	const filteredAvailableRecipients: IAccessControlItem[] = data?.filter(
		(item) => !recipients?.some((r) => r.email === item.email)
	);

	return (
		<Box
			display="flex"
			flexDirection="column"
			sx={{
				backgroundColor: "#25293D",
				borderRadius: "8px",
				padding: "12px 16px",
				width: "655px",
			}}
		>
			<Typography variant="body2">
				{Texts.Dialogs.NotificationDialog.FormTitle} {`(${recipients.length})`}
			</Typography>

			{recipients.map((recipient, index) => (
				<RecipientForm
					key={`recipienForm-${index}`}
					availableRecipients={data}
					alreadySelectedRecipients={data?.filter((item) =>
						recipients?.some((r) => r.email === item.email)
					)}
					recipient={recipient}
					onChange={(rec) => handleFormChange(rec, index)}
					isLast={index === recipients.length - 1}
				/>
			))}

			{filteredAvailableRecipients?.length > 0 && (
				<Box
					display="flex"
					alignItems="center"
					sx={{
						color: "#06A3ED",
						textTransform: "uppercase",
						cursor: "pointer",
						width: "190px",
						fontSize: "14px",
						fontWeight: 600,
					}}
					onClick={handleAddRecipient}
				>
					<PlusSvg
						style={{
							marginRight: "8px",
						}}
					/>

					{Texts.Dialogs.NotificationDialog.AddRecipient}
				</Box>
			)}
		</Box>
	);
};

export default NotificationRecipients;
