import { Action, Reducer } from "redux";
import { IUser } from "../resources/Contracts";

export interface UserState {
	user: IUser;
	directFile?: string;
	directSection?: number;
}

interface SetUserAction {
	type: "SET_USER";
	payload: IUser;
}

interface SetFileAction {
	type: "SET_FILE";
	payload: string;
}

interface SetSectionAction {
	type: "SET_SECTION";
	payload: number;
}

type KnownAction = SetUserAction | SetFileAction | SetSectionAction;

export const actionCreators = {
	setUser: (value: IUser) =>
		({
			type: "SET_USER",
			payload: value,
	} as SetUserAction),

	setFile: (value?: string) =>
	({
		type: "SET_FILE",
		payload: value
	} as SetFileAction),

	setSectionId: (value: number) =>
	({
		type: "SET_SECTION",
		payload: value
    } as SetSectionAction)
};

export const reducer: Reducer<UserState> = (
	state: UserState | undefined,
	incomingAction: Action
) => {
	if (state === undefined) {
		return {
			user: null,
			directFile: undefined,
			directSection: undefined
		};
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "SET_USER":
			return { ...state, user: action.payload };

		case "SET_FILE":
			return { ...state, directFile: action.payload };

		case "SET_SECTION":
			return { ...state, directSection: action.payload };

		default:
			return state;
	}
};
