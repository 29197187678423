import React from "react";
import AdminDocumentsHeader from "../../components/documents/adminTable/AdminDocumentsHeader";
import AdminDocumentsTableContainer from "../../components/documents/adminTable/AdminDocumentsTableContainer";

type AdminDocumentsViewProps = {};

const AdminDocumentsView: React.FC<AdminDocumentsViewProps> = () => {
	return (
		<>
			<AdminDocumentsHeader />

			<AdminDocumentsTableContainer />
		</>
	);
};

export default AdminDocumentsView;
