import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useResizeDetector } from "react-resize-detector";
import { LightTooltip } from "../../../shared/Tooltip";

type KeywordsColumnProps = {
	keywords: string[];
	id: number;
	columnWidth: string;
};

const KeywordsColumn: React.FC<KeywordsColumnProps> = ({ id, keywords, columnWidth }) => {
	const { width, ref } = useResizeDetector();
	const [visibleKeywordsCount, setVisibleKeywordsCount] = React.useState<number>(
		keywords?.length
	);

	React.useEffect(() => {
		const elements: HTMLCollectionOf<Element> = document.getElementsByClassName(
			`${id}-keyword`
		);

		if (width && keywords?.length > 0) {
			let availableWidth: number = width;
			let visibleElements: number = 0;

			for (let i = 0; i < elements.length; i++) {
				const htmlElement = elements[i] as HTMLElement;

				if (availableWidth - htmlElement.clientWidth >= 0) {
					availableWidth = availableWidth - (htmlElement.clientWidth + 8);
					visibleElements = visibleElements + 1;
				} else {
					visibleElements = visibleElements - 1;
					break;
				}
			}

			setVisibleKeywordsCount(visibleElements);
		}
	}, [width, keywords, id]);

	return (
		<Box
			ref={ref}
			display="flex"
			justifyContent="start"
			alignItems="center"
			width={columnWidth}
			sx={{
				overflow: "hidden",
			}}
		>
			{keywords?.slice(0, visibleKeywordsCount).map((keyword, index) => (
				<Box
					className={`${id}-keyword`}
					key={`keyword-${index}`}
					sx={{
						padding: "1px 6px",
						backgroundColor: "#57607999",
						borderRadius: "2px",
						marginRight: "8px",
					}}
				>
					<Typography variant="tableItem" component="span">
						{keyword}
					</Typography>
				</Box>
			))}

			{keywords?.length > visibleKeywordsCount && (
				<LightTooltip
					title={keywords.slice(visibleKeywordsCount, keywords.length).join(", ")}
					arrow
					placement="top"
				>
					<Box
						sx={{
							padding: "1px 6px",
							backgroundColor: "#57607999",
							borderRadius: "2px",
							marginRight: "8px",
						}}
					>
						<Typography variant="tableItem" component="span">{`+${
							keywords.length - visibleKeywordsCount
						}`}</Typography>
					</Box>
				</LightTooltip>
			)}
		</Box>
	);
};

export default KeywordsColumn;
