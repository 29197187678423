import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as DateFunctions from "date-fns";
import React from "react";
import { connect } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import { ReactComponent as ExcelFileSvg } from "../../../../images/icons/ExcelFile.svg";
import { ReactComponent as PdfFileSvg } from "../../../../images/icons/PdfFile.svg";
import { ReactComponent as TextFileSvg } from "../../../../images/icons/TextFile.svg";
import { ReactComponent as WordFileSvg } from "../../../../images/icons/WordFile.svg";
import { IDocument } from "../../../../resources/Contracts";
import Texts from "../../../../resources/Texts";
import { actionCreators as DialogStoreActionCreators } from "../../../dialogs/DialogStore";
import { LightTooltip } from "../../../shared/Tooltip";

type TitleColumnProps = typeof DialogStoreActionCreators & {
	data: IDocument;
	columnWidth: string;
};

const TitleColumn: React.FC<TitleColumnProps> = ({
	data,
	columnWidth,
	setPreviewFileDialogState,
}) => {
	const { width, ref } = useResizeDetector();
	const [textOverflowing, setTextOverflowing] = React.useState<boolean>(false);

	React.useEffect(() => {
		const containerElement: HTMLElement = document.getElementById(`titleContainer-${data.id}`);

		if (containerElement) {
			const isOverflowing: boolean =
				containerElement.clientWidth < containerElement.scrollWidth;

			if (isOverflowing && !textOverflowing) {
				setTextOverflowing(true);
			} else if (!isOverflowing && textOverflowing) {
				setTextOverflowing(false);
			}
		}
	}, [width, data, textOverflowing]);

	const handleOpenFilePreview = () => {
		setPreviewFileDialogState({
			fileUniqueId: data.blobUnique,
			fileType: data.type,
			title: data.title,
			open: true,
		});
	};

	let documentIcon: JSX.Element;

	switch (data.type) {
		case "DOCX":
			documentIcon = <WordFileSvg />;
			break;

		case "XLSX":
			documentIcon = <ExcelFileSvg />;
			break;

		case "PDF":
			documentIcon = <PdfFileSvg />;
			break;

		case "TXT":
			documentIcon = <TextFileSvg />;
			break;

		default:
			documentIcon = <TextFileSvg />;
			break;
	}

	const now: Date = new Date();
	const oneMonthsAgo: Date = DateFunctions.addMonths(now, -1);
	const twentyTwoMonthsAgo: Date = DateFunctions.addMonths(now, -22);

	const isNew: boolean = DateFunctions.isAfter(new Date(data.uploaded), oneMonthsAgo);
	const isOld: boolean = DateFunctions.isBefore(new Date(data.uploaded), twentyTwoMonthsAgo);

	return (
		<Box
			display="flex"
			justifyContent="start"
			alignItems="center"
			width={columnWidth}
			ref={ref}
			overflow="hidden"
		>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				sx={{
					width: "28px",
					flexBasis: "28px",
					flexGrow: 0,
					flexShrink: 0,
					height: "28px",
					borderRadius: "50%",
					marginRight: "9px",
					backgroundColor: "#57607966",
				}}
			>
				{documentIcon}
			</Box>

			<Typography
				id={`titleContainer-${data.id}`}
				variant="tableItem"
				component="span"
				sx={{
					textDecoration: "underline",
					cursor: "pointer",
					marginRight: textOverflowing ? "0" : "8px",
					overflow: "hidden",
					whiteSpace: "nowrap",
				}}
				onClick={handleOpenFilePreview}
			>
				{data.title}
			</Typography>

			{textOverflowing && (
				<LightTooltip title={data.title} arrow placement="top">
					<Typography
						variant="tableItem"
						component="span"
						sx={{
							textDecoration: "underline",
							cursor: "pointer",
							marginRight: "8px",
						}}
					>
						...
					</Typography>
				</LightTooltip>
			)}

			{isNew && (
				<Box
					sx={{
						padding: "1px 6px",
						backgroundColor: "#57607999",
						borderRadius: "2px",
						marginRight: "8px",
					}}
				>
					<Typography variant="tableItem" component="span">
						{Texts.DocumentsTable.DocumentTags.New}
					</Typography>
				</Box>
			)}

			{isOld && (
				<Box
					sx={{
						padding: "1px 6px",
						backgroundColor: "#57607999",
						borderRadius: "2px",
						marginRight: "8px",
					}}
				>
					<Typography variant="tableItem" component="span">
						{Texts.DocumentsTable.DocumentTags.Old}
					</Typography>
				</Box>
			)}
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(DialogStoreActionCreators, dispatch);
};

export default connect(null, mapDispatchToProps)(TitleColumn);
