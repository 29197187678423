import { Action, Reducer } from "redux";
import { IDocumentType, IKeyword } from "../../../resources/Contracts";
import { DocumentsSortBy } from "../../../resources/Enums";

export interface DocumentsState {
	selectedStartDate: string;
	selectedEndDate: string;
	searchText: string;
	keywordFilter: IKeyword[];
	documentTypeFilter: IDocumentType[];
	sortBy: DocumentsSortBy;
	sortDir: "ASC" | "DESC" | null;
	documentsCount: number;
	rowsPerPage: number;
	selectedPage: number;
	selectedBlobUniques: string[];
}

interface SetStartDateAction {
	type: "SET_START_DATE";
	payload: string;
}

interface SetEndDateAction {
	type: "SET_END_DATE";
	payload: string;
}

interface SetSearchTextValueAction {
	type: "SET_SEARCH_VALUE";
	payload: string;
}

interface SetKeywordFilterAction {
	type: "SET_KEYWORD_FILTER";
	payload: IKeyword[];
}

interface SetDocumentTypeFilterAction {
	type: "SET_DOCUMENT_TYPE_FILTER";
	payload: IDocumentType[];
}

interface SetSortByAction {
	type: "SET_SORT_BY";
	payload: DocumentsSortBy;
}

interface SetSortDirAction {
	type: "SET_SORT_DIR";
	payload: "ASC" | "DESC" | null;
}

interface SetDocumentsCountAction {
	type: "SET_DOCUMENTS_COUNT";
	payload: number;
}

interface SetRowsPerPageAction {
	type: "SET_ROWS_PER_PAGE";
	payload: number;
}

interface SetSelectedPageAction {
	type: "SET_SELECTED_PAGE";
	payload: number;
}

interface SetSelectedBlobUniquesAction {
	type: "SET_SELECTED_BLOB_UNIQUES";
	payload: string[];
}

type KnownAction =
	| SetStartDateAction
	| SetEndDateAction
	| SetSearchTextValueAction
	| SetKeywordFilterAction
	| SetDocumentTypeFilterAction
	| SetSortByAction
	| SetSortDirAction
	| SetDocumentsCountAction
	| SetRowsPerPageAction
	| SetSelectedPageAction
	| SetSelectedBlobUniquesAction;

export const actionCreators = {
	setStartDate: (value: string) =>
		({
			type: "SET_START_DATE",
			payload: value,
		} as SetStartDateAction),
	setEndDate: (value: string) =>
		({
			type: "SET_END_DATE",
			payload: value,
		} as SetEndDateAction),
	setSearchTextValue: (value: string) =>
		({ type: "SET_SEARCH_VALUE", payload: value } as SetSearchTextValueAction),
	setKeywordFilter: (value: IKeyword[]) =>
		({ type: "SET_KEYWORD_FILTER", payload: value } as SetKeywordFilterAction),
	setDocumentTypeFilter: (value: IDocumentType[]) =>
		({ type: "SET_DOCUMENT_TYPE_FILTER", payload: value } as SetDocumentTypeFilterAction),
	setSortBy: (value: DocumentsSortBy) =>
		({ type: "SET_SORT_BY", payload: value } as SetSortByAction),
	setSortDir: (value: "ASC" | "DESC" | null) =>
		({ type: "SET_SORT_DIR", payload: value } as SetSortDirAction),
	setDocumentCount: (value: number) =>
		({ type: "SET_DOCUMENTS_COUNT", payload: value } as SetDocumentsCountAction),
	setRowsPerPage: (value: number) =>
		({ type: "SET_ROWS_PER_PAGE", payload: value } as SetRowsPerPageAction),
	setSelectedPage: (value: number) =>
		({ type: "SET_SELECTED_PAGE", payload: value } as SetSelectedPageAction),
	setSelectedBlobUniques: (value: string[]) =>
		({ type: "SET_SELECTED_BLOB_UNIQUES", payload: value } as SetSelectedBlobUniquesAction),
};

export const reducer: Reducer<DocumentsState> = (
	state: DocumentsState | undefined,
	incomingAction: Action
) => {
	if (state === undefined) {
		return {
			selectedStartDate: null,
			selectedEndDate: null,
			searchText: "",
			documentTypeFilter: [],
			keywordFilter: [],
			sortBy: DocumentsSortBy.Published,
			sortDir: "DESC",
			documentsCount: 0,
			rowsPerPage: 25,
			selectedPage: 1,
			selectedBlobUniques: [],
		};
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "SET_START_DATE":
			return { ...state, selectedStartDate: action.payload, selectedPage: 1 };

		case "SET_END_DATE":
			return { ...state, selectedEndDate: action.payload, selectedPage: 1 };

		case "SET_SEARCH_VALUE":
			return { ...state, searchText: action.payload, selectedPage: 1 };

		case "SET_KEYWORD_FILTER":
			return { ...state, keywordFilter: action.payload, selectedPage: 1 };

		case "SET_DOCUMENT_TYPE_FILTER":
			return { ...state, documentTypeFilter: action.payload, selectedPage: 1 };

		case "SET_SORT_BY":
			return { ...state, sortBy: action.payload, selectedPage: 1 };

		case "SET_SORT_DIR":
			return { ...state, sortDir: action.payload, selectedPage: 1 };

		case "SET_DOCUMENTS_COUNT":
			return { ...state, documentsCount: action.payload };

		case "SET_ROWS_PER_PAGE":
			return { ...state, rowsPerPage: action.payload };

		case "SET_SELECTED_PAGE":
			return { ...state, selectedPage: action.payload };

		case "SET_SELECTED_BLOB_UNIQUES":
			return { ...state, selectedBlobUniques: action.payload };

		default:
			return state;
	}
};
