import {
	CircularProgress,
	IconButton,
	InputAdornment,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
} from "@mui/material";
import { Box, SxProps } from "@mui/system";
import React from "react";
import { ReactComponent as CrossSvg } from "../../images/icons/Cross.svg";
import { ReactComponent as SearchSvg } from "../../images/icons/Search.svg";
import Texts from "../../resources/Texts";
import Checkbox from "./Checkbox";

const ITEM_HEIGHT: number = 48;
const ITEM_PADDING_TOP: number = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
			width: 230,
		},
	},
};

type MultiselectProps = {
	selectedItems: any[];
	items: any[];
	textField: string;
	valueField: string;
	label?: string;
	showLoadingIndicator?: boolean;
	showFilterInput?: boolean;
	hideCloseButton?: boolean;
	sx?: SxProps;
	disabled?: boolean;
	renderValue?: (value: any[]) => React.ReactNode;
	onChange: (value: any[]) => void;
	onSearchChange?: (value: string) => void;
	onClose?: () => void;
	multiple?: boolean;
};

const Multiselect: React.FC<MultiselectProps> = ({
	items,
	selectedItems,
	textField,
	valueField,
	label,
	showFilterInput,
	showLoadingIndicator,
	hideCloseButton,
	sx,
	disabled,
	renderValue,
	onChange,
	onSearchChange,
	onClose,
	multiple,
}) => {
	const [filteredItems, setFilteredItems] = React.useState<any[]>(items);
	const [searchValue, setSearchValue] = React.useState<string>("");

	React.useEffect(() => {
		setFilteredItems(items);
	}, [items]);

	const handleSelect = (vf: any): void => {
		if (selectedItems?.some((v) => v[valueField] === vf)) {
			onChange(selectedItems.filter((v) => v[valueField] !== vf));
		} else {
			const newValue = selectedItems?.slice() || [];

			newValue.push(items.find((i) => i[valueField] === vf));

			onChange(newValue);
		}
	};

	const handleSelectAll = (): void => {
		if (items?.length > 0) {
			onChange(items);
		}
	};

	const handleClear = (event: React.MouseEvent): void => {
		event.stopPropagation();

		onChange([]);
	};

	const handleClose = (event: React.MouseEvent): void => {
		event.stopPropagation();

		onClose?.();
	};

	const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const newValue: string = event.target.value;

		if (onSearchChange) {
			onSearchChange(newValue);
			setSearchValue(newValue);
		} else {
			if (newValue) {
				setFilteredItems(
					items.filter((item) =>
						(item[textField] as string).toLowerCase().startsWith(newValue.toLowerCase())
					)
				);
			} else {
				setFilteredItems(items);
			}

			setSearchValue(newValue);
			onSearchChange?.(newValue);
		}
	};

	return (
		<Select
			size="small"
			multiple
			value={selectedItems}
			MenuProps={{
				...MenuProps,
				disableAutoFocusItem: true,
				sx: {
					marginLeft: !hideCloseButton && "-18px",
				},
			}}
			disabled={disabled}
			renderValue={(value) =>
				renderValue?.(value) || (
					<>
						<Box
							component="span"
							sx={{
								fontWeight: 600,
							}}
						>
							{label}:{" "}
						</Box>
						<Box
							component="span"
							sx={{
								color: "#9AA0AF",
							}}
						>
							{Texts.MultiselectInput.Select}
						</Box>
					</>
				)
			}
			displayEmpty
			startAdornment={
				!hideCloseButton && (
					<InputAdornment position="start">
						<IconButton
							onClick={handleClose}
							edge="start"
							sx={{
								borderRadius: 0,
								borderRight: "1px solid #ffffff",
							}}
						>
							<CrossSvg />
						</IconButton>
					</InputAdornment>
				)
			}
			sx={{
				width: "230px",
				".MuiSelect-icon": {
					color: "#ffffff",
				},
				"& .Mui-disabled": {
					color: "#9AA0AF",
					WebkitTextFillColor: "#9AA0AF",
					borderColor: "#9AA0AF",
				},
				"& .MuiOutlinedInput-notchedOutline": {
					borderColor: disabled && "#9AA0AF !important",
				},
				...sx,
			}}
		>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				sx={{
					paddingTop: "2px",
				}}
			>
				{showFilterInput && (
					<OutlinedInput
						value={searchValue}
						size="small"
						label="Name"
						onChange={handleSearchInputChange}
						onKeyDown={(e) => {
							e.stopPropagation();
						}}
						placeholder={Texts.DocumentsTable.SearchFieldLabel}
						startAdornment={
							<InputAdornment position="start">
								<IconButton edge="start">
									<SearchSvg />
								</IconButton>
							</InputAdornment>
						}
						endAdornment={
							showLoadingIndicator && (
								<InputAdornment position="end">
									<Box
										sx={{
											marginLeft: "12px",
											color: "#ffffff",
										}}
									>
										<CircularProgress size={15} color="inherit" />
									</Box>
								</InputAdornment>
							)
						}
						notched={false}
						sx={{
							color: "#ffffff",
							width: "calc(100% - 36px)",
						}}
					/>
				)}

				{multiple && (
					<MenuItem
						disableRipple
						sx={{
							width: "100%",
							height: "30px",
							borderBottom: "1px solid #576079",
						}}
						onClick={handleSelectAll}
					>
						<Checkbox
							checked={items?.length > 0 && selectedItems.length === items.length}
						/>

						<Box display="flex" justifyContent="space-between" flex={1}>
							<ListItemText primary={Texts.MultiselectInput.SelectAll} />

							<Box
								component="span"
								sx={{
									color: "#06A3ED",
									textTransform: "uppercase",
								}}
								onClick={handleClear}
							>
								{Texts.DocumentsTable.ClearFilter}
							</Box>
						</Box>
					</MenuItem>
				)}

				{filteredItems?.map((item) => (
					<MenuItem
						disableRipple
						key={item[valueField]}
						value={item[valueField]}
						sx={{
							width: "100%",
							height: "30px",
						}}
						onClick={() => {
							handleSelect(item[valueField]);
						}}
					>
						<Checkbox checked={selectedItems.indexOf(item) > -1} />
						<ListItemText primary={item[textField]} />
					</MenuItem>
				))}
			</Box>
		</Select>
	);
};

export default Multiselect;
