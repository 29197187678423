import { Box, CircularProgress } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { IKeyword } from "../../../../resources/Contracts";
import Texts from "../../../../resources/Texts";
import { Service } from "../../../../services/Service";
import { ApplicationState } from "../../../../store";
import Multiselect from "../../../shared/Multiselect";
import * as AdminDocumentsStore from "../AdminDocumentsStore";

type AdminKeywordFilterProps = AdminDocumentsStore.AdminDocumentsState &
	typeof AdminDocumentsStore.actionCreators & {
		onClose: () => void;
	};

const AdminKeywordFilter: React.FC<AdminKeywordFilterProps> = ({
	keywordFilter,
	setKeywordFilter,
	onClose,
}) => {
	const { isLoading, data } = useQuery("keywordsData", () => Service.getKeywords());

	const handleChange = (value: IKeyword[]): void => {
		setKeywordFilter(value);
	};

	const handleClose = (): void => {
		setKeywordFilter([]);

		onClose();
	};

	if (isLoading) {
		return (
			<Box
				sx={{
					marginLeft: "12px",
					color: "#ffffff",
				}}
			>
				<CircularProgress size={15} color="inherit" />
			</Box>
		);
	}

	return (
		<Multiselect
			items={data}
			selectedItems={keywordFilter}
			textField="keyword"
			valueField="id"
			label={Texts.DocumentsTable.KeywordFilterLabel}
			onChange={handleChange}
			onClose={handleClose}
			showFilterInput={true}
			sx={{
				marginLeft: "12px",
			}}
		/>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.adminDocuments;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(AdminDocumentsStore.actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminKeywordFilter);
