import { Action, Reducer } from "redux";
import { INavigationItem } from "../../../resources/Contracts";

export interface SideBarState {
	open: boolean;
	selectedNavItem: INavigationItem;
}

interface SetSideBarOpenAction {
	type: "SET_SIDEBAR_OPEN";
	payload: boolean;
}

interface SetSideBarSelectAction {
	type: "SET_SIDEBAR_SELECT";
	payload: INavigationItem;
}

type KnownAction = SetSideBarOpenAction | SetSideBarSelectAction;

export const actionCreators = {
	setSideBarOpen: (open: boolean) =>
		({ type: "SET_SIDEBAR_OPEN", payload: open } as SetSideBarOpenAction),
	setSideBarSelectedItem: (selectedNavItem: INavigationItem) =>
		({ type: "SET_SIDEBAR_SELECT", payload: selectedNavItem } as SetSideBarSelectAction),
};

export const reducer: Reducer<SideBarState> = (
	state: SideBarState | undefined,
	incomingAction: Action
) => {
	if (state === undefined) {
		return { open: true, selectedNavItem: null };
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "SET_SIDEBAR_OPEN":
			return { ...state, open: action.payload };

		case "SET_SIDEBAR_SELECT":
			return { ...state, selectedNavItem: action.payload };

		default:
			return state;
	}
};
