import * as SideBarStore from "../components/layout/sideBar/SideBarStore";
import * as DocumentsStore from "../components/documents/documentsTable/DocumentsStore";
import * as AdminDocumentsStore from "../components/documents/adminTable/AdminDocumentsStore";
import * as DialogStore from "../components/dialogs/DialogStore";
import * as UserStore from "./UserStore";
import * as AlertStore from "./AlertStore";
import * as DocumentUploadStore from "../components/uploadDocument/DocumentUploadStore";
import * as UserManagementStore from "../components/userManagement/UserManagementStore";
import * as AddUserStore from "../components/dialogs/addUserDialog/AddUserStore";

export interface ApplicationState {
	sideBar: SideBarStore.SideBarState;
	documents: DocumentsStore.DocumentsState;
	adminDocuments: AdminDocumentsStore.AdminDocumentsState;
	userState: UserStore.UserState;
	alerts: AlertStore.AlertState;
	dialogs: DialogStore.DialogState;
	documentUpload: DocumentUploadStore.DocumentUploadState;
	userManagement: UserManagementStore.UserManagementState;
	addUser: AddUserStore.AddUserState;
}

export const reducers = {
	sideBar: SideBarStore.reducer,
	documents: DocumentsStore.reducer,
	adminDocuments: AdminDocumentsStore.reducer,
	userState: UserStore.reducer,
	alerts: AlertStore.reducer,
	dialogs: DialogStore.reducer,
	documentUpload: DocumentUploadStore.reducer,
	userManagement: UserManagementStore.reducer,
	addUser: AddUserStore.reducer,
};

export interface AppThunkAction<TAction> {
	(dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
