import { Box, useTheme } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { ReactComponent as TextFileSvg } from "../../../images/icons/TextFile.svg";
import { ReactComponent as UserSvg } from "../../../images/icons/User.svg";
import { Routing } from "../../../resources/Routes";
import Texts from "../../../resources/Texts";
import { ApplicationState } from "../../../store";
import { SideBarState } from "./SideBarStore";

const linkStyle: React.CSSProperties = {
	display: "flex",
	color: "#ffffff",
	textDecoration: "none",
	alignItems: "center",
};

type AdminSideBarNavigationProps = SideBarState;

const AdminSideBarNavigation: React.FC<AdminSideBarNavigationProps> = ({ open }) => {
	const theme = useTheme();
	const location = useLocation();

	return (
		<Box>
			<Box
				display="flex"
				alignItems="center"
				sx={{
					cursor: "pointer",
					height: "40px",
					width: "100%",
					boxSizing: "border-box",
					paddingLeft: "18px",
					borderLeftColor:
						location.pathname === Routing.Routes.HomePage
							? "#06A3ED"
							: theme.components.MuiDrawer.styleOverrides.paper.backgroundColor,
					borderLeftStyle: "solid",
					borderLeftWidth: "5px",
					backgroundColor:
						location.pathname === Routing.Routes.HomePage
							? "#434a61"
							: theme.components.MuiDrawer.styleOverrides.paper.backgroundColor,
				}}
			>
				<Link to={Routing.Routes.HomePage} style={linkStyle}>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						sx={{
							marginRight: "13px",
							svg: {
								width: "24px",
								height: "24px",
							},
						}}
					>
						<TextFileSvg />
					</Box>

					{open && (
						<span color={theme.palette.text.primary}>
							{Texts.SideBarNavigation.Items.Documents}
						</span>
					)}
				</Link>
			</Box>

			<Box
				display="flex"
				alignItems="center"
				sx={{
					cursor: "pointer",
					height: "40px",
					width: "100%",
					boxSizing: "border-box",
					paddingLeft: "18px",
					borderLeftColor:
						location.pathname === Routing.Routes.Admin.UserManagement
							? "#06A3ED"
							: theme.components.MuiDrawer.styleOverrides.paper.backgroundColor,
					borderLeftStyle: "solid",
					borderLeftWidth: "5px",
					backgroundColor:
						location.pathname === Routing.Routes.Admin.UserManagement
							? "#434a61"
							: theme.components.MuiDrawer.styleOverrides.paper.backgroundColor,
				}}
			>
				<Link to={Routing.Routes.Admin.UserManagement} style={linkStyle}>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						sx={{
							marginRight: "13px",
						}}
					>
						<UserSvg />
					</Box>

					{open && (
						<span color={theme.palette.text.primary}>
							{Texts.SideBarNavigation.Items.UserManagement}
						</span>
					)}
				</Link>
			</Box>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.sideBar;
};

export default connect(mapStateToProps)(AdminSideBarNavigation);
