import { Box, Button, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/system";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as FileSaver from "file-saver";
import React from "react";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import { ReactComponent as DownloadSvg } from "../../images/icons/Download.svg";
import { ReactComponent as PlusSvg } from "../../images/icons/Plus.svg";
import { IUserManagement, IUserManagementFile } from "../../resources/Contracts";
import Texts from "../../resources/Texts";
import { ApplicationState } from "../../store";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import { handleErrorMessage } from "../../utils/Utils";
import { actionCreators as DialogStoreActionCreators } from "../dialogs/DialogStore";
import * as UserManagementStore from "./UserManagementStore";

type UserManagementBatchActionsProps = UserManagementStore.UserManagementState &
	typeof DialogStoreActionCreators &
	typeof UserManagementStore.actionCreators &
	typeof AlertStoreActionCreators;

const UserManagementBatchActions: React.FC<UserManagementBatchActionsProps> = ({
	usersCount,
	userItems,
	setAddUserDialogState,
	addErrorAlert,
}) => {
	const theme = useTheme();
	const [exportInProgress, setExportInProgress] = React.useState<boolean>(false);

	const exportToExcel = async (inputData): Promise<void> => {
		const padZero = (num: number, pad: number) => num.toString().padStart(pad, "0");
		const date = new Date();
		const timeStamp =
			date.getFullYear() +
			"" +
			padZero(date.getMonth() + 1, 2) +
			"" +
			padZero(date.getDate(), 2) +
			"" +
			padZero(date.getHours(), 2) +
			"" +
			padZero(date.getMinutes(), 2) +
			"" +
			padZero(date.getSeconds(), 2);

		const fileType =
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		const fileExtension = ".xlsx";

		const fileName = "UserManagement_Export-" + timeStamp;

		const ws = XLSX.utils.json_to_sheet(inputData);
		const wb = { Sheets: { "User Management": ws }, SheetNames: ["User Management"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });

		FileSaver.saveAs(data, fileName + fileExtension);
	};

	const handleAddUserClick = async (): Promise<void> => {
		setAddUserDialogState({
			open: true,
		});
	};

	const handleExportClick = async (): Promise<void> => {
		setExportInProgress(true);

		let inputData = [];
		userItems.forEach((user: IUserManagement) => {
			user.files.forEach((file: IUserManagementFile) => {
				inputData.push({
					Name: user.userName,
					Company: user.companyName,
					Email: user.email,
					LastAction: user.lastAction,
					FileName: file.fileName,
					FileLink: file.fileLink,
				});
			});
		});

		try {
			await exportToExcel(inputData);
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}

		setExportInProgress(false);
	};

	return (
		<Box>
			<>
				<Button
					variant="text"
					sx={{
						borderColor: "#ffffff",
						color: "#06A3ED",
						fontWeight: "600",
						mr: "12px",
					}}
					onClick={handleAddUserClick}
				>
					<PlusSvg
						style={{
							marginRight: "8px",
						}}
					/>
					{`${Texts.UserManagement.Actions.AddUser}`}
				</Button>

				<Button
					variant="contained"
					sx={{
						color: theme.palette.text.secondary,
						backgroundColor: "#FFFFFF",
						fontWeight: "600",
						"&:hover": {
							backgroundColor: "#9AA0AF",
						},
						"&.Mui-disabled": {
							backgroundColor: "#9AA0AF",
						},
					}}
					onClick={handleExportClick}
				>
					<DownloadSvg
						style={{
							fill: theme.palette.text.secondary,
							marginRight: "8px",
						}}
					/>
					{Texts.UserManagement.Actions.Export} {!isNaN(usersCount) && `(${usersCount})`}
					{exportInProgress && <CircularProgress size={15} color="inherit" />}
				</Button>
			</>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return {
		...state.userManagement,
		...state.dialogs,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...UserManagementStore.actionCreators,
			...DialogStoreActionCreators,
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementBatchActions);
