import { Action, Reducer } from "redux";
import { IUserManagement } from "../../resources/Contracts";
import { UsersSortBy } from "../../resources/Enums";

export interface UserManagementState {
	searchText: string;
	sortBy: UsersSortBy;
	sortDir: "ASC" | "DESC" | null;
	usersCount: number;
	rowsPerPage: number;
	selectedPage: number;
	userItems: IUserManagement[];
}

interface SetSearchTextValueAction {
	type: "SET_USERS_SEARCH_VALUE";
	payload: string;
}

interface SetSortByAction {
	type: "SET_USERS_SORT_BY";
	payload: UsersSortBy;
}

interface SetSortDirAction {
	type: "SET_USERS_SORT_DIR";
	payload: "ASC" | "DESC" | null;
}

interface SetUsersCountAction {
	type: "SET_USERS_COUNT";
	payload: number;
}

interface SetRowsPerPageAction {
	type: "SET_USERS_ROWS_PER_PAGE";
	payload: number;
}

interface SetSelectedPageAction {
	type: "SET_USERS_SELECTED_PAGE";
	payload: number;
}

interface SetUserItemsAction {
	type: "SET_USER_ITEMS";
	payload: IUserManagement[];
}

type KnownAction =
	| SetSearchTextValueAction
	| SetSortByAction
	| SetSortDirAction
	| SetUsersCountAction
	| SetRowsPerPageAction
	| SetSelectedPageAction
	| SetUserItemsAction;

export const actionCreators = {
	setSearchTextValue: (value: string) =>
		({ type: "SET_USERS_SEARCH_VALUE", payload: value } as SetSearchTextValueAction),
	setSortBy: (value: UsersSortBy) =>
		({ type: "SET_USERS_SORT_BY", payload: value } as SetSortByAction),
	setSortDir: (value: "ASC" | "DESC" | null) =>
		({ type: "SET_USERS_SORT_DIR", payload: value } as SetSortDirAction),
	setUsersCount: (value: number) =>
		({ type: "SET_USERS_COUNT", payload: value } as SetUsersCountAction),
	setRowsPerPage: (value: number) =>
		({ type: "SET_USERS_ROWS_PER_PAGE", payload: value } as SetRowsPerPageAction),
	setSelectedPage: (value: number) =>
		({ type: "SET_USERS_SELECTED_PAGE", payload: value } as SetSelectedPageAction),
	setUserItems: (value: IUserManagement[]) =>
		({ type: "SET_USER_ITEMS", payload: value } as SetUserItemsAction),
};

export const reducer: Reducer<UserManagementState> = (
	state: UserManagementState | undefined,
	incomingAction: Action
) => {
	if (state === undefined) {
		return {
			searchText: "",
			sortBy: UsersSortBy.Email,
			sortDir: "ASC",
			usersCount: 0,
			rowsPerPage: 25,
			selectedPage: 1,
			userItems: [],
		};
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "SET_USERS_SEARCH_VALUE":
			return { ...state, searchText: action.payload, selectedPage: 1 };

		case "SET_USERS_SORT_BY":
			return { ...state, sortBy: action.payload, selectedPage: 1 };

		case "SET_USERS_SORT_DIR":
			return { ...state, sortDir: action.payload, selectedPage: 1 };

		case "SET_USERS_COUNT":
			return { ...state, usersCount: action.payload };

		case "SET_USERS_ROWS_PER_PAGE":
			return { ...state, rowsPerPage: action.payload };

		case "SET_USERS_SELECTED_PAGE":
			return { ...state, selectedPage: action.payload };

		case "SET_USER_ITEMS":
			return { ...state, userItems: action.payload };

		default:
			return state;
	}
};
