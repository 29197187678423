import {
	Box,
    Typography,
} from "@mui/material";
import * as React from "react";
import Texts from "../../../resources/Texts";
import AddUserCompanyFilter from "./AddUserCompanyFilter";
import AddUserUserFilter from "./AddUserUserFilter";

type AddUserContentProps = {};

const AddUserContent: React.FC<AddUserContentProps> = ({}) => {
	return (
		<Box
			sx={{
				backgroundColor: "#25293D",
				borderRadius: "8px",
				padding: "12px 16px",
			}}
		>
			<Box display="flex" flexDirection="column">
				<Box display="flex" flexDirection="column" sx={{ mt: "10px" }}>
					<Typography variant="body1">{Texts.Dialogs.AddUserDialog.FormLabels.Customer}</Typography>
					<AddUserCompanyFilter />
				</Box>
				<Box display="flex" flexDirection="column">
					<Box display="flex" flexDirection="column" sx={{ mt: "10px" }}>
						<Typography variant="body1">{Texts.Dialogs.AddUserDialog.FormLabels.User}</Typography>
						<AddUserUserFilter />
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default AddUserContent;
