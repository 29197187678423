import React from "react";
import UploadDocumentHeader from "../../components/uploadDocument/UploadDocumentHeader";
import UploadDocumentContainer from "../../components/uploadDocument/UploadDocumentContainer";

type DocumentUploadViewProps = {};

const DocumentUploadView: React.FC<DocumentUploadViewProps> = () => {
	return (
		<>
			<UploadDocumentHeader />

			<UploadDocumentContainer />
		</>
	);
};

export default DocumentUploadView;
