import { Box } from "@mui/material";
import * as React from "react";
import { ReactComponent as PlusSvg } from "../../../images/icons/Plus.svg";
import Texts from "../../../resources/Texts";
import AccessControlSelect from "./AccessControlSelect";

type AccessControlAddInputProps = {};

const AccessControlAddInput: React.FC<AccessControlAddInputProps> = () => {
	const [inputVisible, setInputVisible] = React.useState<boolean>(false);

	const handleAddClick = (): void => {
		setInputVisible(true);
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			sx={{
				marginTop: "20px",
			}}
		>
			<Box
				display="flex"
				alignItems="center"
				sx={{
					color: inputVisible ? "#005A85" : "#0078B1",
					textTransform: "uppercase",
					textDecoration: "underline",
					cursor: "pointer",
					width: "190px",
					fontSize: "14px",
				}}
				onClick={handleAddClick}
			>
				<PlusSvg
					style={{
						marginRight: "8px",
					}}
				/>

				{Texts.Dialogs.AccessControl.AddInput.AddPermitedViewer}
			</Box>

			{inputVisible && <AccessControlSelect />}
		</Box>
	);
};

export default AccessControlAddInput;
