import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as DoubleArrowLeftSvg } from "../../../images/icons/DoubleArrowLeft.svg";
import { ReactComponent as DoubleArrowRightSvg } from "../../../images/icons/DoubleArrowRight.svg";
import { ReactComponent as LogoSvg } from "../../../images/logo.svg";
import { ReactComponent as LogoWithNameSvg } from "../../../images/logoWithName.svg";
import { Routing } from "../../../resources/Routes";
import { ApplicationState } from "../../../store";
import { UserState } from "../../../store/UserStore";
import AdminSideBarNavigation from "./AdminSideBarNavigation";
import SideBarNavigation from "./SideBarNavigation";
import * as SideBarStore from "./SideBarStore";
import { SideBarDrawer } from "./SideBarStyledComponents";

type SideBarProps = SideBarStore.SideBarState & UserState & typeof SideBarStore.actionCreators;

const SideBar: React.FC<SideBarProps> = ({ open, user, setSideBarOpen }) => {
	const handleOpenChange = (): void => {
		setSideBarOpen(!open);
	};

	return (
		<SideBarDrawer
			variant="permanent"
			open={open}
			sx={{
				boxSizing: "border-box",
			}}
		>
			<Box paddingTop="20px" paddingLeft="25px" marginBottom="40px">
				<Link to={Routing.getHomeViewUrl()}>
					{open ? <LogoWithNameSvg /> : <LogoSvg />}
				</Link>
			</Box>

			<Box paddingLeft="24px" marginBottom="12px">
				<IconButton
					onClick={handleOpenChange}
					sx={{
						padding: 0,
					}}
				>
					{open ? <DoubleArrowLeftSvg /> : <DoubleArrowRightSvg />}
				</IconButton>
			</Box>

			{user.isAdmin ? <AdminSideBarNavigation /> : <SideBarNavigation />}
		</SideBarDrawer>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return { ...state.sideBar, ...state.userState };
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(SideBarStore.actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
