import { Box, Button, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/system";
import { bindActionCreators } from "@reduxjs/toolkit";
import { PDFDocument, PDFPage } from "pdf-lib";
import printJS from "print-js";
import React from "react";
import { connect } from "react-redux";
import { ReactComponent as PrintSvg } from "../../../images/icons/Print.svg";
import { ReactComponent as PrintDisabledSvg } from "../../../images/icons/PrintDisabled.svg";
import { IFileDownloadDataResult } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { ApplicationState } from "../../../store";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import { handleErrorMessage } from "../../../utils/Utils";
import * as AdminDocumentsStore from "./AdminDocumentsStore";

type AdminBatchActionsProps = AdminDocumentsStore.AdminDocumentsState &
	typeof AlertStoreActionCreators;

const AdminBatchActions: React.FC<AdminBatchActionsProps> = ({
	selectedBlobUniques,
	addErrorAlert,
}) => {
	const theme = useTheme();
	const [printInProgress, setPrintInProgress] = React.useState<boolean>(false);

	const handlePrintClick = async (): Promise<void> => {
		setPrintInProgress(true);

		try {
			if (selectedBlobUniques?.length === 1) {
				const filePdfData: IFileDownloadDataResult = await Service.getPdfFile(
					selectedBlobUniques[0],
					Texts.Dialogs.Actions.Print
				);

				if (filePdfData) {
					printJS({
						printable: decodeURI(filePdfData.fileLink),
						type: "pdf",
						showModal: false,
					});
				}
			} else {
				// merge pdfs
				const mergedPdf: PDFDocument = await PDFDocument.create();

				selectedBlobUniques.forEach(async (blobUnique: string, index: number) => {
					const filePdfData: Uint8Array = await Service.getBytesPdfFile(blobUnique);

					if (filePdfData) {
						const pdf: PDFDocument = await PDFDocument.load(filePdfData, {
							ignoreEncryption: true,
						});

						const copiedPages: PDFPage[] = await mergedPdf.copyPages(
							pdf,
							pdf.getPageIndices()
						);

						copiedPages.forEach((page) => {
							mergedPdf.addPage(page);
						});

						const pdfBytes: Uint8Array = await mergedPdf.save();

						if (index === selectedBlobUniques.length - 1) {
							const blob: Blob = new Blob([pdfBytes], { type: "application/pdf" });
							const url: string = window.URL.createObjectURL(blob);
							//window.open(url);
							printJS({ printable: url, type: "pdf", showModal: false });
						}
					}
				});
			}
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}

		setPrintInProgress(false);
	};

	const printDisabled: boolean =
		!selectedBlobUniques || selectedBlobUniques.length <= 0 || printInProgress;

	return (
		<Box>
			{
				<Button
					variant="outlined"
					sx={{
						borderColor: "#ffffff",
						color: theme.palette.text.primary,
						fontWeight: "600",
						mr: "12px",
						"&:hover": {
							borderColor: "#9AA0AF",
							color: "#9AA0AF",
						},
						"&.Mui-disabled": {
							backgroundColor: "#57607999",
							color: "#57607999",
						},
					}}
					onClick={handlePrintClick}
					disabled={printDisabled}
				>
					{printDisabled ? (
						<PrintDisabledSvg
							style={{
								marginRight: "8px",
							}}
						/>
					) : (
						<PrintSvg
							style={{
								marginRight: "8px",
							}}
						/>
					)}
					{Texts.DocumentsTable.BatchActions.Print}{" "}
					{!printDisabled && `(${selectedBlobUniques.length})`}
					{printInProgress && <CircularProgress size={15} color="inherit" />}
				</Button>
			}
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.adminDocuments;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(AlertStoreActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBatchActions);
