import { Box, IconButton, Typography } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { ReactComponent as SelectedSortAscSvg } from "../../../../images/icons/SelectedSortAsc.svg";
import { ReactComponent as SelectedSortDescSvg } from "../../../../images/icons/SelectedSortDesc.svg";
import { ReactComponent as UnselectedSortSvg } from "../../../../images/icons/UnselectedSort.svg";
import { IDocument, IDocumentsRequest, IDocumentsResponse } from "../../../../resources/Contracts";
import { DocumentsSortBy } from "../../../../resources/Enums";
import Texts from "../../../../resources/Texts";
import { Service } from "../../../../services/Service";
import { ApplicationState } from "../../../../store";
import { SideBarState } from "../../../layout/sideBar/SideBarStore";
import Checkbox from "../../../shared/Checkbox";
import * as DocumentsStore from "../DocumentsStore";

type DocumentsTableHeaderProps = DocumentsStore.DocumentsState &
	SideBarState &
	typeof DocumentsStore.actionCreators;

const DocumentsTableHeader: React.FC<DocumentsTableHeaderProps> = ({
	selectedStartDate,
	selectedEndDate,
	documentTypeFilter,
	keywordFilter,
	searchText,
	sortBy,
	sortDir,
	rowsPerPage,
	selectedPage,
	selectedBlobUniques,
	selectedNavItem,
	setSortBy,
	setSortDir,
	setSelectedBlobUniques,
	setDocumentCount,
}) => {
	const loadDocuments = async (): Promise<IDocument[]> => {
		if (
			!rowsPerPage ||
			!selectedPage ||
			!selectedStartDate ||
			!selectedEndDate ||
			!selectedNavItem
		) {
			return [];
		}

		const request: IDocumentsRequest = {
			itemsPerPage: rowsPerPage,
			pageNumber: selectedPage,
			orderBy: sortBy,
			order: sortDir || "ASC",
			searchString: searchText?.length ? searchText : null,
			sectionId: selectedNavItem.id,
			filters: {
				publishedFrom: selectedStartDate,
				publishedTo: selectedEndDate,
				keywords: keywordFilter?.length > 0 ? keywordFilter.map((k) => k.id) : null,
				typeIds:
					documentTypeFilter?.length > 0 ? documentTypeFilter.map((dt) => dt.id) : null,
			},
		};

		const response: IDocumentsResponse = await Service.getDocuments(request);

		setDocumentCount(response.itemsCount);

		return response.itemsData;
	};

	const { isLoading, data, isFetching } = useQuery(
		[
			"documentsData",
			selectedStartDate,
			selectedEndDate,
			documentTypeFilter,
			keywordFilter,
			searchText,
			sortBy,
			sortDir,
			rowsPerPage,
			selectedPage,
			selectedNavItem,
		],
		loadDocuments
	);

	React.useEffect(() => {
		setSelectedBlobUniques([]);
	}, [selectedNavItem, setSelectedBlobUniques]);

	const handleSortChange = (newSortBy: DocumentsSortBy): void => {
		if (sortBy === newSortBy) {
			if (sortDir === "DESC") {
				setSortBy(null);
				setSortDir(null);
			} else {
				setSortDir("DESC");
			}
		} else {
			setSortBy(newSortBy);
			setSortDir("ASC");
		}
	};

	const handleSelectAll = (selectedValue: boolean): void => {
		if (selectedValue) {
			const unselectedDocuments: IDocument[] = data.filter(
				(d) => !selectedBlobUniques.some((sid) => sid === d.blobUnique)
			);

			setSelectedBlobUniques(
				selectedBlobUniques.concat(unselectedDocuments.map((d) => d.blobUnique))
			);
		} else {
			setSelectedBlobUniques(
				selectedBlobUniques.filter((sid) => !data.some((d) => d.blobUnique === sid))
			);
		}
	};

	const sortByNameSelected: boolean = sortBy === DocumentsSortBy.Title;
	const sortByDateSelected: boolean = sortBy === DocumentsSortBy.Published;

	return (
		<Box
			display="flex"
			flexDirection="row"
			alignItems="center"
			sx={{
				height: "34px",
				border: "1px solid #576079",
				borderRadius: "8px",
				marginBottom: "4px",
				boxSizing: "border-box",
				paddingRight: "35px",
			}}
		>
			<Box display="flex" justifyContent="end" width="40px">
				<Checkbox
					disabled={isLoading || isFetching}
					checked={
						(data?.length > 0 &&
							data.every((d) =>
								selectedBlobUniques.some((sid) => sid === d.blobUnique)
							)) ||
						false
					}
					onChange={handleSelectAll}
				/>
			</Box>

			<Box display="flex" justifyContent="start" alignItems="center" width="36%">
				<Typography
					variant="body2"
					sx={{
						marginRight: "5px",
					}}
				>
					{Texts.DocumentsTable.ColumnTitles.DocumentNameAndType}
				</Typography>

				<IconButton onClick={() => handleSortChange(DocumentsSortBy.Title)}>
					{sortByNameSelected ? (
						sortDir === "ASC" ? (
							<SelectedSortAscSvg />
						) : (
							<SelectedSortDescSvg />
						)
					) : (
						<UnselectedSortSvg />
					)}
				</IconButton>
			</Box>

			<Box display="flex" justifyContent="start" alignItems="center" width="230px">
				<Typography
					variant="body2"
					sx={{
						marginRight: "5px",
					}}
				>
					{Texts.DocumentsTable.ColumnTitles.CreationDate}
				</Typography>

				<IconButton onClick={() => handleSortChange(DocumentsSortBy.Published)}>
					{sortByDateSelected ? (
						sortDir === "ASC" ? (
							<SelectedSortAscSvg />
						) : (
							<SelectedSortDescSvg />
						)
					) : (
						<UnselectedSortSvg />
					)}
				</IconButton>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width="calc(100% - 40px - 36% - 230px - 134px)"
			>
				<Typography variant="body2">
					{Texts.DocumentsTable.ColumnTitles.Keywords}
				</Typography>
			</Box>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return { ...state.documents, ...state.sideBar };
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(DocumentsStore.actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTableHeader);
