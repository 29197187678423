import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { connect } from "react-redux";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { ApplicationState } from "../../../store";
import Dropdown from "../../shared/Dropdown";
import * as DialogStore from "../DialogStore";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import { handleErrorMessage } from "../../../utils/Utils";
import { IAccessControlUser } from "../../../resources/Contracts";

type AccessControlSelectProps = DialogStore.DialogState & typeof AlertStoreActionCreators & {};

const AccessControlSelect: React.FC<AccessControlSelectProps> = ({
	accessControlDialog,
	addErrorAlert,
}) => {
	const queryClient = useQueryClient();

	const [searchValue, setSearchValue] = React.useState<string>("");
	const [inputValue, setInputValue] = React.useState<IAccessControlUser>();
	const [addInProgress, setAddInProgress] = React.useState<boolean>(false);

	const loadUploaders = async (): Promise<IAccessControlUser[]> => {
		try {
			if (searchValue.length > 0) {
				const users = await Service.getUsers(searchValue);

				return users.map((user) => {
					return {
						email: user.mail,
						displayName: user.displayName,
						firstName: user.givenName,
						lastName: user.surname,
					};
				});
			} else {
				return [];
			}
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const { isLoading, data } = useQuery(["uploadersData", searchValue], loadUploaders);

	const handleChange = (value: IAccessControlUser): void => {
		setInputValue(value);
	};

	const handleAddButtonClick = async (): Promise<void> => {
		setAddInProgress(true);

		try {
			await Service.addFileAccessList(accessControlDialog.fileId, inputValue);

			queryClient.invalidateQueries("accessControlData");
			setInputValue(null);
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}

		setAddInProgress(false);
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			sx={{
				mt: "12px",
			}}
		>
			<Typography variant="body1">{Texts.Dialogs.AccessControl.AddInput.Label}</Typography>

			<Box display="flex">
				<Dropdown
					items={data}
					selectedItem={inputValue}
					textField="displayName"
					valueField="email"
					showFilterInput
					showLoadingIndicator={isLoading}
					placeholder={Texts.Dialogs.AccessControl.AddInput.Placeholder}
					sx={{
						ml: "0",
						mt: "2px",
						width: "350px",
					}}
					onChange={handleChange}
					onSearchChange={setSearchValue}
				/>

				{inputValue && (
					<Button
						onClick={handleAddButtonClick}
						sx={{
							background: "#FFFFFF",
							borderRadius: "4px",
							color: "#151724",
							marginLeft: "12px",
							"&:hover": {
								backgroundColor: "#9AA0AF",
							},
						}}
						disabled={addInProgress}
					>
						{Texts.Dialogs.Actions.Ok}

						{addInProgress && (
							<CircularProgress color="inherit" size="15px" sx={{ ml: "4px" }} />
						)}
					</Button>
				)}
			</Box>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.dialogs;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...DialogStore.actionCreators,
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessControlSelect);
