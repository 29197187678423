import { CircularProgress } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { ReactComponent as CameraSvg } from "../../../images/icons/Camera.svg";
import { ReactComponent as MaskSvg } from "../../../images/icons/Mask.svg";
import { ReactComponent as ShieldSvg } from "../../../images/icons/Shield.svg";
import { ReactComponent as TextFileSvg } from "../../../images/icons/TextFile.svg";
import { INavigationItem, INavSection } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { ApplicationState } from "../../../store";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import { UserState } from "../../../store/UserStore";
import { handleErrorMessage } from "../../../utils/Utils";
import { actionCreators as DocumentsStoreActionCreators } from "../../documents/documentsTable/DocumentsStore";
import * as SideBarStore from "./SideBarStore";

type SideBarNavigationProps = SideBarStore.SideBarState &
	UserState &
	typeof SideBarStore.actionCreators &
	typeof AlertStoreActionCreators &
	typeof DocumentsStoreActionCreators;

const SideBarNavigation: React.FC<SideBarNavigationProps> = ({
	open,
	user,
	directSection,
	selectedNavItem,
	setSideBarSelectedItem,
	addErrorAlert,
	setSelectedPage,
}) => {
	const theme = useTheme();

	const [navigationItems, setNavigationItems] = React.useState<INavigationItem[]>([]);

	const loadNavItems = async (): Promise<void> => {
		try {
			const result: INavSection[] = await Service.getNavSections();
			const navItems: INavigationItem[] = [];

			result.forEach((item) => {
				const navItem: INavigationItem = {
					id: item.id,
					icon: null,
					text:
						item.id === 1
							? user.company !== null && user.company !== ""
								? user.company
								: item.sectionName
							: item.sectionName,
				};

				switch (item.sectionName) {
					case Texts.SideBarNavigation.Items.Astro:
						navItem.icon = <ShieldSvg />;
						break;

					case Texts.SideBarNavigation.Items.GlobalHackingTrends:
						navItem.icon = <CameraSvg />;
						break;

					case Texts.SideBarNavigation.Items.GlobalVideoPiracy:
						navItem.icon = <MaskSvg />;
						break;

					default:
						navItem.icon = <TextFileSvg />;
				}

				navItems.push(navItem);
			});

			if (navItems?.length > 0) {
				if (directSection) {
					const navItem: INavigationItem =
						navItems.find((navItem: INavigationItem) => (directSection === navItem.id));

					setSideBarSelectedItem(navItem);
				} else {
					setSideBarSelectedItem(navItems[0]);
				}
			}

			setNavigationItems(navItems);
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const { isLoading } = useQuery(["navItemsData"], loadNavItems);

	const handleNavigationClick = (navItem: INavigationItem): void => {
		setSideBarSelectedItem(navItem);
		setSelectedPage(1);
	};

	if (isLoading) {
		return (
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				sx={{
					color: "#ffffff",
				}}
			>
				<CircularProgress size={15} color="inherit" />
			</Box>
		);
	}

	return (
		<Box>
			{navigationItems.map((navItem, index) => {
				return (
					<Box
						key={`navItem-${index}`}
						display="flex"
						alignItems="center"
						sx={{
							cursor: "pointer",
							height: "40px",
							width: "100%",
							boxSizing: "border-box",
							paddingLeft: "18px",
							borderLeftColor:
								selectedNavItem.id === navItem.id
									? "#06A3ED"
									: theme.components.MuiDrawer.styleOverrides.paper
											.backgroundColor,
							borderLeftStyle: "solid",
							borderLeftWidth: "5px",
							backgroundColor:
								selectedNavItem.id === navItem.id
									? "#434a61"
									: theme.components.MuiDrawer.styleOverrides.paper
											.backgroundColor,
						}}
						onClick={() => handleNavigationClick(navItem)}
					>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="center"
							width="14px"
							sx={{
								marginRight: "13px",
							}}
						>
							{navItem.icon}
						</Box>

						{open && <span color={theme.palette.text.primary}>{navItem.text}</span>}
					</Box>
				);
			})}
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return { ...state.sideBar, ...state.userState };
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...SideBarStore.actionCreators,
			...AlertStoreActionCreators,
			...DocumentsStoreActionCreators,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarNavigation);
