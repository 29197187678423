import { Alert, Stack } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { actionCreators as AlertStoreActionCreators, AlertState } from "../../store/AlertStore";

type AlertOverlayProps = AlertState & typeof AlertStoreActionCreators;

const AlertOverlay: React.FC<AlertOverlayProps> = ({ removeAlert, alerts }) => {
	React.useEffect(() => {
		if (alerts.length > 0) {
			setTimeout(() => {
				removeAlert();
			}, 5000);
		}
	}, [alerts, removeAlert]);

	return (
		<Box
			className="alertOverlay"
			display="flex"
			justifyContent="center"
			position="absolute"
			top={80}
			left={0}
			right={0}
			zIndex={9999}
		>
			<Stack sx={{ width: "50%" }} spacing={2}>
				{alerts?.map((alert, index) => (
					<Alert severity={alert.type} key={index}>
						{alert.text}
					</Alert>
				))}
			</Stack>
		</Box>
	);
};

export default connect(
	(state: ApplicationState) => state.alerts,
	AlertStoreActionCreators
)(AlertOverlay);
