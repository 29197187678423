import { Checkbox as MuiCheckBox } from "@mui/material";
import React from "react";
import { ReactComponent as CheckedCheckboxSvg } from "../../images/icons/CheckedCheckbox.svg";
import { ReactComponent as UncheckedCheckboxSvg } from "../../images/icons/UncheckedCheckbox.svg";

type CheckBoxProps = {
	checked: boolean;
	disabled?: boolean;
	onChange?: (value: boolean) => void;
};

const Checkbox: React.FC<CheckBoxProps> = ({ checked, disabled, onChange }) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		onChange?.(event.target.checked);
	};

	return (
		<MuiCheckBox
			checked={checked}
			disabled={disabled}
			disableRipple
			icon={<UncheckedCheckboxSvg />}
			checkedIcon={<CheckedCheckboxSvg />}
			onChange={handleChange}
		/>
	);
};

export default Checkbox;
