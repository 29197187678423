import { Box, CircularProgress } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import { IFileDownloadData } from "../../../resources/Contracts";
import { Service } from "../../../services/Service";
import * as AlertStore from "../../../store/AlertStore";
import { handleErrorMessage } from "../../../utils/Utils";

type FilePreviewDialogContentProps = typeof AlertStore.actionCreators & {
	fileUniqueId: string;
};

const FilePreviewDialogContent: React.FC<FilePreviewDialogContentProps> = ({
	fileUniqueId,
	addErrorAlert,
}) => {
	const [fileLink, setFileLink] = React.useState<string>(null);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);

	const loadFileLink = React.useCallback(async (): Promise<void> => {
		try {
			const fileDownloadData: IFileDownloadData[] = await Service.getFileDownloadLinks(
				[fileUniqueId],
				"View"
			);

			if (fileDownloadData?.length > 0) {
				setFileLink(fileDownloadData[0].result.fileLink);
			}
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}

		setIsLoading(false);
	}, [fileUniqueId, addErrorAlert]);

	React.useEffect(() => {
		loadFileLink();
	}, [loadFileLink]);

	if (isLoading) {
		return (
			<Box
				sx={{
					width: "532px",
					height: "654px",
					color: "#ffffff",
				}}
				display="flex"
				justifyContent="center"
				alignItems="center"
			>
				<CircularProgress color="inherit" />
			</Box>
		);
	}

	return (
		<>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				sx={{
					width: "532px",
				}}
			>
				<Box>
					<video width="500" controls>
						<source src={fileLink} type="video/mp4" />
					</video>
				</Box>
			</Box>
		</>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(AlertStore.actionCreators, dispatch);
};

export default connect(null, mapDispatchToProps)(FilePreviewDialogContent);
