export enum DateRangeOption {
	All,
	LastThirtyDays,
	LastSixtyDays,
	Custom,
}

export enum DocumentsSortBy {
	Title = "Title",
	Published = "PublishedDate",
	Category = "Category",
}

export enum UsersSortBy {
	Name = "UserName",
	Email = "Email",
}
