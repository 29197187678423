import { Box } from "@mui/material";
import * as React from "react";
import { ReactComponent as ExclamationMarkSvg } from "../../../images/icons/ExclamationMark.svg";
import Texts from "../../../resources/Texts";

type FailedUploadDialogContentProps = { errorMessage?: string };

const FailedUploadDialogContent: React.FC<FailedUploadDialogContentProps> = ({ errorMessage }) => {
	return (
		<Box display="flex" flexDirection="row">
			<Box width="20px" marginRight="1em">
				<ExclamationMarkSvg />
			</Box>

			<Box width="428px">
				{errorMessage ?
					<Box>{errorMessage}</Box>
					:
					<Box>
						<Box>{Texts.Dialogs.FailedUploadDialog.Content1}</Box>
						<Box>{Texts.Dialogs.FailedUploadDialog.Content2}</Box>
					</Box>
				}
			</Box>
		</Box>
	);
};

export default FailedUploadDialogContent;
