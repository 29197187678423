import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import LoginBackground from "../../images/LoginIllustration.png";
import Texts from "../../resources/Texts";
import { Service } from "../../services/Service";
import FailedLoginDialog from "../../components/dialogs/failedLoginDialog/FailedLoginDialog";
import * as UserStore from "../../store/UserStore";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";

type LoginViewProps = UserStore.UserState & { };

const LoginView: React.FC<LoginViewProps> = ({ directFile, directSection }) => {
	const theme = useTheme();

	const handleLoginExternal = (): void => {
		Service.loginExternal(directFile, directSection);
	};

	const handleLoginInternal = (): void => {
		Service.loginInternal(directFile, directSection);
	};

	return (
		<Box
			display="flex"
			flex={1}
			sx={{
				backgroundColor: "#25293D",
				backgroundImage: `url(${LoginBackground})`,
				backgroundRepeat: "no-repeat",
				position: "relative",
			}}
		>
			<Box
				display="flex"
				flexDirection="column"
				sx={{
					position: "absolute",
					top: "50%",
					left: {
						xs: "0",
						sm: "15%",
						md: "25%",
						xl: "55%",
					},
					padding: "20px",
					backgroundColor: "#25293D",
				}}
			>
				<Button
					variant="contained"
					sx={{
						margin: "16px 0",
						color: theme.palette.text.secondary,
						fontWeight: 600,
						fontSize: "14px",
						backgroundColor: "#ffffff",
						width: "440px",
						maxWidth: "100%",
						"&:hover": {
							backgroundColor: "#9AA0AF",
						},
					}}
					onClick={handleLoginExternal}
				>
					{Texts.LoginView.LoginExternal}
				</Button>

				<Button
					variant="contained"
					sx={{
						margin: "16px 0",
						color: theme.palette.text.secondary,
						fontWeight: 600,
						fontSize: "14px",
						backgroundColor: "#ffffff",
						width: "440px",
						maxWidth: "100%",
						"&:hover": {
							backgroundColor: "#9AA0AF",
						},
					}}
					onClick={handleLoginInternal}
				>
					{Texts.LoginView.LoginInternal}
				</Button>
			</Box>
			<FailedLoginDialog />
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.userState;
};

export default connect(mapStateToProps)(LoginView);