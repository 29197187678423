import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { INotification } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { ApplicationState } from "../../../store";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import { UserState } from "../../../store/UserStore";
import { handleErrorMessage } from "../../../utils/Utils";
import Notification from "./Notification";

type NotificationListProps = UserState & typeof AlertStoreActionCreators;

const NotificationList: React.FC<NotificationListProps> = ({ user, addErrorAlert }) => {
	const loadNotifications = async (): Promise<INotification[]> => {
		try {
			return await Service.getNotifications(user.loginName);
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const { isLoading, data, isFetching } = useQuery("userNotificationData", loadNotifications);

	const loadActive: boolean = isLoading || isFetching;

	return (
		<Box display="flex" flexDirection="column" flex={1} height="100%">
			<Box
				display="flex"
				alignItems="center"
				height="40px"
				sx={{
					borderBottom: "1px solid #576079",
					pl: "25px",
				}}
			>
				<Typography variant="h4">{Texts.NotificationList.Title}</Typography>
			</Box>

			{!loadActive && (
				<Box
					display="flex"
					flex={1}
					height="calc(100% - 40px)"
					alignItems="center"
					flexDirection="column"
					sx={{
						overflow: "auto",
						pl: "25px",
						pr: "25px",
					}}
				>
					{data.map((n, index) => (
						<Notification notification={n} key={`notification-${index}`} />
					))}
				</Box>
			)}

			{loadActive && (
				<Box
					display="flex"
					flex={1}
					height="calc(100% - 40px)"
					alignItems="center"
					justifyContent="center"
					sx={{
						color: "#ffffff",
					}}
				>
					<CircularProgress color="inherit" />
				</Box>
			)}
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return { ...state.userState };
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(AlertStoreActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
