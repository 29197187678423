import { Box, Button, useTheme } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import { ReactComponent as DownloadSvg } from "../../../images/icons/Download.svg";
import { ReactComponent as PrintSvg } from "../../../images/icons/Print.svg";
import { IFileDownloadDataResult, IFileDownloadData } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { ApplicationState } from "../../../store";
import DialogBase from "../DialogBase";
import * as DialogStore from "../DialogStore";
import FilePreviewDialogContent from "./FilePreviewDialogContent";
import VideoFilePlayer from "./VideoFilePlayer";
import printJS from "print-js";
import * as AlertStore from "../../../store/AlertStore";
import { handleErrorMessage } from "../../../utils/Utils";

type FilePreviewDialogProps = DialogStore.DialogState &
	typeof DialogStore.actionCreators &
	typeof AlertStore.actionCreators;

const FilePreviewDialog: React.FC<FilePreviewDialogProps> = ({
	filePreviewDialog,
	setPreviewFileDialogState,
	addErrorAlert,
}) => {
	const theme = useTheme();

	const handleClose = (): void => {
		setPreviewFileDialogState(null);
	};

	const handleDownloadClick = async (): Promise<void> => {
		try {
			const fileDownloadData: IFileDownloadData[] = await Service.getFileDownloadLinks(
				[filePreviewDialog.fileUniqueId],
				Texts.DocumentsTable.BatchActions.Download
			);

			fileDownloadData.forEach((link) => {
				if (link.result.fileLink) {
					const aElement: HTMLAnchorElement = document.createElement("a");
					aElement.href = link.result.fileLink;
					aElement.download = link.result.fileName;

					document.body.appendChild(aElement);

					aElement.click();
					aElement.remove();
				}
			});
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const handlePrintClick = async (): Promise<void> => {
		try {
			const filePdfData: IFileDownloadDataResult = await Service.getPdfFile(
				filePreviewDialog.fileUniqueId,
				Texts.Dialogs.Actions.Print
			);

			if (filePdfData) {
				printJS({
					printable: decodeURI(filePdfData.fileLink),
					type: "pdf",
					showModal: false,
				});
			}
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	if (!filePreviewDialog) {
		return null;
	}

	let contentType: "video" | "document";

	if (filePreviewDialog.fileType === "MP4") {
		contentType = "video";
	} else {
		contentType = "document";
	}

	const customDialogButtons: React.ReactNode = (
		<Box flex={1} display="flex" flexDirection="row" justifyContent="space-between">
			{contentType !== "video" ? (
				<Button
					variant="outlined"
					sx={{
						borderColor: "#ffffff",
						color: theme.palette.text.primary,
						fontWeight: "600",
						mr: "12px",
						"&:hover": {
							borderColor: "#9AA0AF",
							color: "#9AA0AF",
						},
					}}
					onClick={handlePrintClick}
				>
					<PrintSvg
						style={{
							marginRight: "8px",
						}}
					/>
					{Texts.Dialogs.Actions.Print}
				</Button>
			) : (
				<Box></Box>
			)}

			<Box display="flex" flexDirection="row">
				<Button
					onClick={handleClose}
					sx={{
						fontWeight: "600",
					}}
				>
					{Texts.Dialogs.Actions.Close}
				</Button>

				<Button
					onClick={handleDownloadClick}
					variant="contained"
					sx={{
						color: theme.palette.text.secondary,
						backgroundColor: "#FFFFFF",
						fontWeight: "600",
						"&:hover": {
							backgroundColor: "#9AA0AF",
						},
						"&.Mui-disabled": {
							backgroundColor: "#9AA0AF",
						},
					}}
				>
					<DownloadSvg
						style={{
							fill: theme.palette.text.secondary,
							marginRight: "8px",
						}}
					/>
					{Texts.Dialogs.Actions.Download}
				</Button>
			</Box>
		</Box>
	);

	return (
		<DialogBase
			open={filePreviewDialog.open}
			dialogTitle={
				<>
					{Texts.Dialogs.FilePreviewDialog.Title}
					<Box
						component="span"
						sx={{
							margin: "0 4px",
						}}
					>
						-
					</Box>
					<Box
						component="span"
						sx={{
							fontWeight: "600",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
							overflow: "hidden",
						}}
						width="550px"
					>
						{filePreviewDialog.title}
					</Box>
				</>
			}
			customButtons={customDialogButtons}
			onCancel={handleClose}
		>
			{contentType === "document" && (
				<FilePreviewDialogContent fileUniqueId={filePreviewDialog.fileUniqueId} />
			)}

			{contentType === "video" && (
				<VideoFilePlayer fileUniqueId={filePreviewDialog.fileUniqueId} />
			)}
		</DialogBase>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.dialogs;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ ...DialogStore.actionCreators, ...AlertStore.actionCreators },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(FilePreviewDialog);
