import { IconButton, Menu, MenuItem } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ReactComponent as RowMenuSvg } from "../../../../images/icons/RowMenu.svg";
import { IDocument } from "../../../../resources/Contracts";
import Texts from "../../../../resources/Texts";
import * as DialogStore from "../../../dialogs/DialogStore";

type RowMenuProps = typeof DialogStore.actionCreators & {
	data: IDocument;
};

const RowMenu: React.FC<RowMenuProps> = ({
	data,
	setAccessDialogState,
	setNotificationDialogState,
	setDeleteFileDialogState,
	setViewingStatsDialogState,
}) => {
	const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement>(null);

	const filterMenuOpen: boolean = Boolean(menuAnchorEl);

	const handleMenuOpenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleMenuClose = (): void => {
		setMenuAnchorEl(null);
	};

	const handleViewingStatsSelect = (): void => {
		setViewingStatsDialogState({
			fileId: data.id,
			title: data.title,
			open: true,
		});
	};

	const handleAccessControlSelect = (): void => {
		setAccessDialogState({
			fileId: data.id,
			title: data.title,
			open: true,
		});
	};

	const handleNotifyClientSelect = (): void => {
		setNotificationDialogState({
			fileId: data.id,
			title: data.title,
			open: true,
		});
	};

	const handleDeleteSelect = (): void => {
		setDeleteFileDialogState({
			fileBlobUniques: [data.blobUnique],
			fileName: data.title,
			open: true,
		});
	};

	return (
		<>
			<IconButton onClick={handleMenuOpenClick}>
				<RowMenuSvg />
			</IconButton>

			<Menu anchorEl={menuAnchorEl} open={filterMenuOpen} onClose={handleMenuClose}>
				<MenuItem
					onClick={handleViewingStatsSelect}
					sx={{
						padding: "6px 8px",
						"&:hover": {
							backgroundColor: "#57607999",
						},
					}}
				>
					{Texts.DocumentsTable.RowMenuOptions.ViewingStats}
				</MenuItem>

				<MenuItem
					onClick={handleNotifyClientSelect}
					sx={{
						padding: "6px 8px",
						"&:hover": {
							backgroundColor: "#57607999",
						},
					}}
				>
					{Texts.DocumentsTable.RowMenuOptions.NotifyClient}
				</MenuItem>

				<MenuItem
					onClick={handleAccessControlSelect}
					sx={{
						padding: "6px 8px",
						"&:hover": {
							backgroundColor: "#57607999",
						},
					}}
				>
					{Texts.DocumentsTable.RowMenuOptions.AccessControl}
				</MenuItem>

				<MenuItem
					onClick={handleDeleteSelect}
					sx={{
						padding: "6px 8px",
						"&:hover": {
							backgroundColor: "#57607999",
						},
					}}
				>
					{Texts.DocumentsTable.RowMenuOptions.Delete}
				</MenuItem>
			</Menu>
		</>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...DialogStore.actionCreators,
		},
		dispatch
	);
};

export default connect(null, mapDispatchToProps)(RowMenu);
