import { Box, Typography } from "@mui/material";
import * as React from "react";
import { IPermissionTableItem } from "../../../../resources/Contracts";
import { LightTooltip } from "../../../shared/Tooltip";

type PermissionsColumnProps = {
    id: number;
	data: IPermissionTableItem[];
    columnWidth: string;
};

const PermissionsColumn: React.FC<PermissionsColumnProps> = ({ id, data, columnWidth }) => {
	const sortedData: IPermissionTableItem[] = data.sort((first, second) => 0 - (first.count < second.count ? -1 : 1));

	const RenderPermissions: React.FC<{ items: IPermissionTableItem[] }> = ({ items }) => {
		if (items?.length > 2) {
			return (
				<LightTooltip
					arrow
					placement="top"
					title={items?.slice(2, items?.length).map((p) => `${p.company} (${p.count})`).join(", ")}
				>
					<Typography
						variant="tableItem"
						component="span"
						sx={{
							overflow: "visible",
							textOverflow: "ellipsis",
							whiteSpace: "normal",
						}}
						width={columnWidth}
					>
						{items?.slice(0, 2).map((p) => `${p.company} (${p.count})`).join(", ")}, +{items?.length - 2}
					</Typography>
				</LightTooltip>
			);
        }

		return (
			<Typography
				variant="tableItem"
				component="span"
				sx={{
					overflow: "visible",
					textOverflow: "ellipsis",
					whiteSpace: "normal",
				}}
				width={columnWidth}
			>
				{items?.map((p) => `${p.company} (${p.count})`).join(", ")}
			</Typography>
		);
	};

	return (
		<Box
			display="flex"
			justifyContent="start"
			alignItems="center"
			width={columnWidth}
		>
			<RenderPermissions items={sortedData} />
			{/*<Typography
				variant="tableItem"
				component="span"
				sx={{
					overflow: "visible",
					textOverflow: "ellipsis",
					whiteSpace: "normal",
				}}
				width={columnWidth}
			>
				{sortedData?.map((p) => `${p.company} (${p.count})`).join(", ")}
			</Typography>*/}
		</Box>
	);
};

export default PermissionsColumn;