import { IconButton, Menu, MenuItem } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ReactComponent as RowMenuSvg } from "../../../images/icons/RowMenu.svg";
import { IUserManagement } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import * as DialogStore from "../../dialogs/DialogStore";

type RowMenuProps = typeof DialogStore.actionCreators & {
	data: IUserManagement;
};

const RowMenu: React.FC<RowMenuProps> = ({ data, setUserDeleteDialogState }) => {
	const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement>(null);

	const filterMenuOpen: boolean = Boolean(menuAnchorEl);

	const handleMenuOpenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setMenuAnchorEl(null);
	};

	const handleDeleteUserSelect = () => {
		setUserDeleteDialogState({
			open: true,
			user: data,
		});
	};

	return (
		<>
			<IconButton onClick={handleMenuOpenClick}>
				<RowMenuSvg />
			</IconButton>

			<Menu anchorEl={menuAnchorEl} open={filterMenuOpen} onClose={handleMenuClose}>
				<MenuItem
					onClick={handleDeleteUserSelect}
					sx={{
						padding: "6px 8px",
						"&:hover": {
							backgroundColor: "#57607999",
						},
					}}
				>
					{Texts.UserManagement.RowMenu.RemoveUser}
				</MenuItem>
			</Menu>
		</>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...DialogStore.actionCreators,
		},
		dispatch
	);
};

export default connect(null, mapDispatchToProps)(RowMenu);
