import { Box } from "@mui/material";
import React from "react";
import UserManagementTableHeader from "./UserManagementTableHeader";
import UserManagementTablePagination from "./UserManagementTablePagination";
import UserManagementTableRows from "./UserManagementTableRows";

type UserManagementTableProps = {};

const UserManagementTable: React.FC<UserManagementTableProps> = () => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			flex={1}
			height="calc(100% - 103px)"
			sx={{
				boxSizing: "border-box",
			}}
		>
			<UserManagementTableHeader />

			<UserManagementTableRows />

			<UserManagementTablePagination />
		</Box>
	);
};

export default UserManagementTable;
