import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useResizeDetector } from "react-resize-detector";
import { IUserManagement } from "../../../resources/Contracts";
import { LightTooltip } from "../../shared/Tooltip";

type NameColumnProps = {
	data: IUserManagement;
	columnWidth: string;
};

const NameColumn: React.FC<NameColumnProps> = ({ data, columnWidth }) => {
	const { width, ref } = useResizeDetector();
	const [textOverflowing, setTextOverflowing] = React.useState<boolean>(false);

	React.useEffect(() => {
		const containerElement = document.getElementById(`titleContainer-${data.email}`);
		const isOverflowing: boolean = containerElement.clientWidth < containerElement.scrollWidth;

		if (isOverflowing && !textOverflowing) {
			setTextOverflowing(true);
		} else if (!isOverflowing && textOverflowing) {
			setTextOverflowing(false);
		}
	}, [width, data, textOverflowing]);

	return (
		<Box
			display="flex"
			justifyContent="start"
			alignItems="center"
			width={columnWidth}
			ref={ref}
		>
			<Typography
				id={`titleContainer-${data.email}`}
				variant="tableItem"
				component="span"
				sx={{
					marginRight: textOverflowing ? "0" : "8px",
					overflow: "hidden",
					whiteSpace: "nowrap",
				}}
			>
				{data.userName}
			</Typography>

			{textOverflowing && (
				<LightTooltip title={data.userName} arrow placement="top">
					<Typography
						variant="tableItem"
						component="span"
						sx={{
							textDecoration: "underline",
							cursor: "pointer",
							marginRight: "8px",
						}}
					>
						...
					</Typography>
				</LightTooltip>
			)}
		</Box>
	);
};

export default NameColumn;
