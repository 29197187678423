import { bindActionCreators } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import DialogBase from "../DialogBase";
import * as DialogStore from "../DialogStore";
import Texts from "../../../resources/Texts";
import { Box, Button, CircularProgress } from "@mui/material";
import DeleteUserDialogContent from "./DeleteUserDialogContent";
import { Service } from "../../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import { useQueryClient } from "react-query";
import { handleErrorMessage } from "../../../utils/Utils";

type DeleteUserDialogProps = DialogStore.DialogState &
	typeof DialogStore.actionCreators &
	typeof AlertStoreActionCreators & {};

const DeleteUserDialog: React.FC<DeleteUserDialogProps> = ({
	userDeleteDialog,
	setUserDeleteDialogState,
	addErrorAlert,
}) => {
	const queryClient = useQueryClient();
	const [deleteInProgress, setDeleteInProgress] = React.useState<boolean>(false);

	const handleClose = (): void => {
		setUserDeleteDialogState({
			open: false,
			user: null,
		});
	};

	const handleSubmit = async (): Promise<void> => {
		setDeleteInProgress(true);

		try {
			await Service.deleteUser(userDeleteDialog.user.email);

			queryClient.invalidateQueries("userManagementData");

			handleClose();
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}

		setDeleteInProgress(false);
	};

	if (!userDeleteDialog) {
		return null;
	}

	return (
		<DialogBase
			open={userDeleteDialog.open}
			dialogTitle={
				<Box
					component="span"
					sx={{
						fontWeight: "600",
					}}
				>
					{Texts.Dialogs.UserDeleteDialog.Title}
				</Box>
			}
			topBorderColor="#FFC41C"
			onCancel={handleClose}
			customButtons={
				<>
					<Button
						onClick={handleClose}
						sx={{
							fontWeight: "600",
						}}
					>
						{Texts.Dialogs.Actions.Cancel}
					</Button>

					<Button
						onClick={handleSubmit}
						sx={{
							background: "#FFFFFF",
							borderRadius: "4px",
							color: "#151724",
							"&:hover": {
								backgroundColor: "#9AA0AF",
							},
						}}
					>
						{Texts.Dialogs.Actions.Ok}

						{deleteInProgress && (
							<CircularProgress color="inherit" size="15px" sx={{ ml: "4px" }} />
						)}
					</Button>
				</>
			}
		>
			<DeleteUserDialogContent userName={userDeleteDialog.user?.userName} />
		</DialogBase>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.dialogs;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...DialogStore.actionCreators,
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserDialog);
