import {
	Box,
	CircularProgress,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { IEmailTemplate } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import { handleErrorMessage } from "../../../utils/Utils";

type TemplateSelectorProps = typeof AlertStoreActionCreators & {
	selectedTemplateId: number;
	customEmailContent: string;
	onChange: (value: number) => void;
	onCustomEmailChange: (value: string) => void;
};

const TemplateSelector: React.FC<TemplateSelectorProps> = ({
	customEmailContent,
	selectedTemplateId,
	onChange,
	onCustomEmailChange,
	addErrorAlert,
}) => {
	const loadTemplates = async (): Promise<IEmailTemplate[]> => {
		try {
			const result: IEmailTemplate[] = await Service.getEmailTemplates();

			if (!selectedTemplateId && result?.length > 0) {
				onChange(result[0].id);
			}

			return result;
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const { isLoading, data } = useQuery("emailTemplates", loadTemplates);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
		onChange(Number(value));
		onCustomEmailChange(null);
	};

	const handleCustomEmailChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	): void => {
		onCustomEmailChange(event.target.value);
	};

	const selectedTemplate: IEmailTemplate = data?.find((x) => x.id === selectedTemplateId);
	const isCustomSelected: boolean = selectedTemplate?.title === "Custom";

	return (
		<Box
			display="flex"
			flexDirection="column"
			sx={{
				backgroundColor: "#25293D",
				borderRadius: "8px",
				padding: "12px 16px",
				mt: "12px",
			}}
		>
			<Typography variant="body2">
				{Texts.Dialogs.NotificationDialog.TemplatesTitle}
			</Typography>

			{isLoading && (
				<Box
					display="flex"
					flexDirection="column"
					sx={{ color: "#ffffff" }}
					alignItems="center"
					justifyContent="center"
				>
					<CircularProgress color="inherit" />
				</Box>
			)}

			{data && (
				<>
					<RadioGroup value={selectedTemplateId} onChange={handleChange}>
						{data.map((item, index) => (
							<FormControlLabel
								key={`templateRadioButton-${index}`}
								value={item.id || 0}
								control={
									<Radio
										sx={{
											color: "#ffffff",
											"&.Mui-checked": {
												color: "#06A3ED",
											},
										}}
									/>
								}
								label={item.title}
							/>
						))}
					</RadioGroup>

					{isCustomSelected && (
						<TextField
							multiline
							value={customEmailContent || ""}
							size="small"
							fullWidth
							sx={{
								"& .MuiInputBase-root": {
									width: "100%",
									"& .MuiInputBase-input": {
										minHeight: "130px",
									},
								},
							}}
							placeholder={Texts.Dialogs.NotificationDialog.CustomTemplatePlaceholder}
							onChange={handleCustomEmailChange}
						/>
					)}
				</>
			)}
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(AlertStoreActionCreators, dispatch);
};

export default connect(null, mapDispatchToProps)(TemplateSelector);
