import { Box } from "@mui/material";
import React from "react";
import AdminDocumentsTableHeader from "./AdminDocumentsTableHeader";
import AdminDocumentsTablePagination from "./AdminDocumentsTablePagination";
import AdminDocumentsTableRows from "./AdminDocumentsTableRows";

type AdminDocumentsTableProps = {};

const AdminDocumentsTable: React.FC<AdminDocumentsTableProps> = () => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			flex={1}
			height="calc(100% - 103px)"
			sx={{
				boxSizing: "border-box",
			}}
		>
			<Box display="flex" flexDirection="column" flex={1} overflow="auto">
				<Box
					display="flex"
					flexDirection="column"
					flex={1}
					minWidth="1550px"
					height="calc(100% - 45px)"
				>
					<AdminDocumentsTableHeader />

					<AdminDocumentsTableRows />
				</Box>
			</Box>

			<AdminDocumentsTablePagination />
		</Box>
	);
};

export default AdminDocumentsTable;
