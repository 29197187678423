import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as DateFunctions from "date-fns";
import React from "react";
import { IUserManagement } from "../../../resources/Contracts";
import NameColumn from "./NameColumn";
import RowMenu from "./RowMenu";

type UserManagementTableRowProps = {
	data: IUserManagement;
};

const UserManagementTableRow: React.FC<UserManagementTableRowProps> = ({ data }) => {
	const nameColumnWidth: string = "30%";
	const clientColumnWidth: string = "170px";
	const emailColumnWidth: string = "320px";
	const accessPermissionColumnWidth: string = "330px";
	const lastActionColumnWidth: string = "260px";
	const actionsColumn: string = "20px";

	return (
		<Box
			display="flex"
			flexDirection="row"
			alignItems="center"
			sx={{
				height: "46px",
				border: "1px solid transparent",
				borderRadius: "8px",
				boxSizing: "border-box",
				paddingRight: "20px",
				paddingLeft: "10px",
				marginTop: "8px",
				backgroundColor: "#57607933",
				"&:hover": {
					borderColor: "#005A85",
				},
			}}
		>
			<NameColumn data={data} columnWidth={nameColumnWidth} />

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={clientColumnWidth}
			>
				<Typography variant="tableItem" component="span">
					{data.companyName}
				</Typography>
			</Box>

			<Box display="flex" justifyContent="start" alignItems="center" width={emailColumnWidth}>
				<Typography variant="tableItem" component="span">
					{data.email}
				</Typography>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={accessPermissionColumnWidth}
			>
				<Typography variant="tableItem" component="span">
					{ data.accessPermissionsInfo > 0 ? `${data.accessPermissionsInfo} Documents` : "" }
				</Typography>
			</Box>

			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				width={lastActionColumnWidth}
			>
				<Typography variant="tableItem" component="span">
					{data.lastAction && DateFunctions.format(new Date(data.lastAction), "d MMM, hh:mm:ss a")}
				</Typography>
			</Box>

			<Box display="flex" justifyContent="start" alignItems="center" width={actionsColumn}>
				<RowMenu data={data} />
			</Box>
		</Box>
	);
};

export default UserManagementTableRow;
