import { bindActionCreators } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import DialogBase from "../DialogBase";
import * as DialogStore from "../DialogStore";
import Texts from "../../../resources/Texts";
import { Box } from "@mui/material";
import FailedUploadDialogContent from "./FailedUploadDialogContent";

type FailedUploadDialogProps = DialogStore.DialogState & typeof DialogStore.actionCreators & {};

const FailedUploadDialog: React.FC<FailedUploadDialogProps> = ({
	failedUploadDialog,
	setFailedUploadDialogState,
}) => {
	const handleClose = (): void => {
		setFailedUploadDialogState({
			errorMessage: null,
			open: false,
		});
	};

	if (!failedUploadDialog) {
		return null;
	}

	return (
		<DialogBase
			open={failedUploadDialog.open}
			dialogTitle={
				<Box
					component="span"
					sx={{
						fontWeight: "600",
					}}
				>
					{Texts.Dialogs.FailedUploadDialog.Title}
				</Box>
			}
			primaryButtonText={Texts.Dialogs.Actions.Ok}
			topBorderColor="#FF3333"
			onCancel={handleClose}
			onSubmit={handleClose}
		>
			<FailedUploadDialogContent errorMessage={failedUploadDialog.errorMessage} />
		</DialogBase>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.dialogs;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...DialogStore.actionCreators,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(FailedUploadDialog);
