import { Action, Reducer } from "redux";
import { IUserManagement } from "../../resources/Contracts";

interface IFileRelatedDialogState {
	fileId: number;
	title: string;
	open: boolean;
}

interface IFileDeleteDialogState {
	fileBlobUniques: string[];
	fileName?: string;
	open: boolean;
}

interface IUserDeleteDialogState {
	user: IUserManagement;
	open: boolean;
}

interface IFilePreviewDialogState {
	fileUniqueId: string;
	fileType: string;
	title: string;
	open: boolean;
}

interface IFailedUploadDialogState {
	errorMessage?: string;
	open: boolean;
}

interface IFailedLoginDialogState {
	errorMessage?: string;
	open: boolean;
}

interface IAddUserDialogState {
	open: boolean;
}

export interface DialogState {
	accessControlDialog: IFileRelatedDialogState;
	notificationDialog: IFileRelatedDialogState;
	deleteFileDialog: IFileDeleteDialogState;
	viewingStatsDialog: IFileRelatedDialogState;
	filePreviewDialog: IFilePreviewDialogState;
	failedUploadDialog: IFailedUploadDialogState;
	userDeleteDialog: IUserDeleteDialogState;
	failedLoginDialog: IFailedLoginDialogState;
	addUserDialog: IAddUserDialogState;
}

interface SetAccessDialogStateAction {
	type: "SET_ACCESS_DIALOG_STATE";
	payload: IFileRelatedDialogState;
}

interface SetNotificationDialogStateAction {
	type: "SET_NOTIFICATION_DIALOG_STATE";
	payload: IFileRelatedDialogState;
}

interface SetDeleteFileDialogStateAction {
	type: "SET_DELETE_FILE_DIALOG_STATE";
	payload: IFileDeleteDialogState;
}

interface SetViewingStatsDialogStateAction {
	type: "SET_VIEWING_STATS_DIALOG_STATE";
	payload: IFileRelatedDialogState;
}

interface SetPreviewFileDialogStateAction {
	type: "SET_PREVIEW_FILE_DIALOG_STATE";
	payload: IFilePreviewDialogState;
}

interface SetFailedUploadDialogStateAction {
	type: "SET_FAILED_UPLOAD_DIALOG_STATE";
	payload: IFailedUploadDialogState;
}

interface SetUserDeleteDialogStateAction {
	type: "SET_USER_DELETE_DIALOG_STATE";
	payload: IUserDeleteDialogState;
}

interface SetFailedLoginDialogStateAction {
	type: "SET_FAILED_LOGIN_DIALOG_STATE";
	payload: IFailedLoginDialogState;
}

interface SetAddUserDialogStateAction {
	type: "SET_ADD_USER_DIALOG_STATE";
	payload: IAddUserDialogState;
}

type KnownAction =
	| SetAccessDialogStateAction
	| SetNotificationDialogStateAction
	| SetDeleteFileDialogStateAction
	| SetViewingStatsDialogStateAction
	| SetFailedUploadDialogStateAction
	| SetPreviewFileDialogStateAction
	| SetUserDeleteDialogStateAction
	| SetFailedLoginDialogStateAction
	| SetAddUserDialogStateAction;

export const actionCreators = {
	setAccessDialogState: (value: IFileRelatedDialogState) =>
		({
			type: "SET_ACCESS_DIALOG_STATE",
			payload: value,
		} as SetAccessDialogStateAction),
	setNotificationDialogState: (value: IFileRelatedDialogState) =>
		({
			type: "SET_NOTIFICATION_DIALOG_STATE",
			payload: value,
		} as SetNotificationDialogStateAction),
	setDeleteFileDialogState: (value: IFileDeleteDialogState) =>
		({
			type: "SET_DELETE_FILE_DIALOG_STATE",
			payload: value,
		} as SetDeleteFileDialogStateAction),
	setViewingStatsDialogState: (value: IFileRelatedDialogState) =>
		({
			type: "SET_VIEWING_STATS_DIALOG_STATE",
			payload: value,
		} as SetViewingStatsDialogStateAction),
	setPreviewFileDialogState: (value: IFilePreviewDialogState) =>
		({
			type: "SET_PREVIEW_FILE_DIALOG_STATE",
			payload: value,
		} as SetPreviewFileDialogStateAction),
	setFailedUploadDialogState: (value: IFailedUploadDialogState) =>
		({
			type: "SET_FAILED_UPLOAD_DIALOG_STATE",
			payload: value,
		} as SetFailedUploadDialogStateAction),
	setUserDeleteDialogState: (value: IUserDeleteDialogState) =>
		({
			type: "SET_USER_DELETE_DIALOG_STATE",
			payload: value,
		} as SetUserDeleteDialogStateAction),

	setFailedLoginDialogState: (value: IFailedLoginDialogState) =>
		({
			type: "SET_FAILED_LOGIN_DIALOG_STATE",
			payload: value
	} as SetFailedLoginDialogStateAction),

	setAddUserDialogState: (value: IAddUserDialogState) =>
		({
			type: "SET_ADD_USER_DIALOG_STATE",
			payload: value
		} as SetAddUserDialogStateAction)
};

export const reducer: Reducer<DialogState> = (
	state: DialogState | undefined,
	incomingAction: Action
) => {
	if (state === undefined) {
		return {
			accessControlDialog: {
				fileId: null,
				title: null,
				open: false,
			},
			notificationDialog: {
				fileId: null,
				title: null,
				open: false,
			},
			deleteFileDialog: {
				fileBlobUniques: [],
				title: null,
				open: false,
			},
			viewingStatsDialog: {
				fileId: null,
				title: null,
				open: false,
			},
			filePreviewDialog: {
				fileUniqueId: null,
				fileType: null,
				title: null,
				open: false,
			},
			failedUploadDialog: {
				errorMessage: null,
				open: false,
			},
			userDeleteDialog: {
				user: null,
				open: false,
			},
			failedLoginDialog: {
				errorMessage: null,
				open: false
			},
			addUserDialog: {
				open: false
            }
		};
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "SET_ACCESS_DIALOG_STATE":
			return { ...state, accessControlDialog: action.payload };

		case "SET_NOTIFICATION_DIALOG_STATE":
			return { ...state, notificationDialog: action.payload };

		case "SET_DELETE_FILE_DIALOG_STATE":
			return { ...state, deleteFileDialog: action.payload };

		case "SET_VIEWING_STATS_DIALOG_STATE":
			return { ...state, viewingStatsDialog: action.payload };

		case "SET_PREVIEW_FILE_DIALOG_STATE":
			return { ...state, filePreviewDialog: action.payload };

		case "SET_FAILED_UPLOAD_DIALOG_STATE":
			return { ...state, failedUploadDialog: action.payload };

		case "SET_USER_DELETE_DIALOG_STATE":
			return { ...state, userDeleteDialog: action.payload };

		case "SET_FAILED_LOGIN_DIALOG_STATE":
			return { ...state, failedLoginDialog: action.payload };

		case "SET_ADD_USER_DIALOG_STATE":
			return { ...state, addUserDialog: action.payload };

		default:
			return state;
	}
};
