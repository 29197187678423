import { Box } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import TextSeparator from "../../shared/TextSeparator";
import DialogBase from "../DialogBase";
import * as DialogStore from "../DialogStore";
import Texts from "../../../resources/Texts";
import ViewingStatsTable from "./ViewingStatsTable";

type ViewingStatsDialogProps = DialogStore.DialogState & typeof DialogStore.actionCreators & {};

const ViewingStatsDialog: React.FC<ViewingStatsDialogProps> = ({
	viewingStatsDialog,
	setViewingStatsDialogState,
}) => {
	const handleClose = (): void => {
		setViewingStatsDialogState(null);
	};

	if (!viewingStatsDialog) {
		return null;
	}

	return (
		<DialogBase
			open={viewingStatsDialog.open}
			dialogTitle={
				<>
					<Box
						component="span"
						sx={{
							fontWeight: "600",
						}}
					>
						{viewingStatsDialog.title}
					</Box>
					<TextSeparator />
					{Texts.Dialogs.ViewingStatsDialog.Title}
				</>
			}
			onCancel={handleClose}
		>
			<ViewingStatsTable fileId={viewingStatsDialog.fileId} />
		</DialogBase>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.dialogs;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...DialogStore.actionCreators,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewingStatsDialog);
