import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import { ApplicationState, reducers } from "./";

export default function createStore(initialState?: ApplicationState) {
	const middleware = [thunk];

	const rootReducer = combineReducers({
		...reducers,
	});

	const enhancers: any[] = [];
	const windowIfDefined = typeof window === "undefined" ? null : (window as any);
	if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
		enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
	}

	const store = configureStore({
		reducer: rootReducer,
		enhancers: enhancers,
		middleware: middleware,
	});

	return store;
}
