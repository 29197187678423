import { AppBar as MuiAppBar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import Texts from "../../../resources/Texts";
import AppBarActions from "./AppBarActions";

type AppBarProps = {};

const AppBar: React.FC<AppBarProps> = () => {
	return (
		<MuiAppBar
			position="static"
			sx={{
				height: "76px",
				alignItems: "center",
				flexDirection: "row",
				paddingLeft: "25px",
				paddingRight: "20px",
				justifyContent: "space-between",
			}}
		>
			<Box>
				<Typography variant="h1">{Texts.AppTitle} </Typography>
			</Box>

			<AppBarActions />
		</MuiAppBar>
	);
};

export default AppBar;
