import { Box } from "@mui/material";
import React from "react";
import DocumentsTableHeader from "./DocumentsTableHeader";
import DocumentsTablePagination from "./DocumentsTablePagination";
import DocumentsTableRows from "./DocumentsTableRows";

type DocumentsTableProps = {};

const DocumentsTable: React.FC<DocumentsTableProps> = () => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			flex={1}
			height="calc(100% - 103px)"
			sx={{
				boxSizing: "border-box",
			}}
		>
			<Box display="flex" flexDirection="column" flex={1} overflow="auto">
				<Box
					display="flex"
					flexDirection="column"
					flex={1}
					minWidth="1550px"
					height="calc(100% - 45px)"
				>
					<DocumentsTableHeader />

					<DocumentsTableRows />
				</Box>
			</Box>

			<DocumentsTablePagination />
		</Box>
	);
};

export default DocumentsTable;
