import { Action, Reducer } from "redux";

export interface IAlert {
	text: string;
	type: "error" | "warning" | "info" | "success";
}

export interface AlertState {
	alerts: IAlert[];
}

export interface SetAlertAction {
	type: "ADD_ALERT";
	payload: IAlert;
}

export interface RemoveAlertAction {
	type: "REMOVE_ALERT";
}

export type KnownAction = SetAlertAction | RemoveAlertAction;

export const actionCreators = {
	addAlert: (alert: IAlert) => ({ type: "ADD_ALERT", payload: alert } as SetAlertAction),
	addErrorAlert: (errorMessage: string) =>
		({ type: "ADD_ALERT", payload: { type: "error", text: errorMessage } } as SetAlertAction),
	removeAlert: () => ({ type: "REMOVE_ALERT" } as RemoveAlertAction),
};

export const reducer: Reducer<AlertState> = (
	state: AlertState | undefined,
	incomingAction: Action
) => {
	if (state === undefined) {
		return { alerts: [] };
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "ADD_ALERT":
			const alertsCopy: IAlert[] = state.alerts.slice();

			alertsCopy.push(action.payload);

			return { alerts: alertsCopy };

		case "REMOVE_ALERT":
			const alertsRemove: IAlert[] = state.alerts.slice();
			alertsRemove.shift();

			return { alerts: alertsRemove };

		default:
			return state;
	}
};
