import * as React from "react";
import AccessControlDialog from "./accessControlDialog/AccessControlDialog";
import NotificationDialog from "./notificationDialog/NotificationDialog";
import DeleteFileDialog from "./deleteFileDialog/DeleteFileDialog";
import FailedUploadDialog from "./failedUploadDialog/FailedUploadDialog";
import FilePreviewDialog from "./filePreviewDialog/FilePreviewDialog";
import DeleteUserDialog from "./deleteUserDialog/DeleteUserDialog";
import ViewingStatsDialog from "./viewingStatsDialog/ViewingStatsDialog";

type DialogsContainerProps = {};

const DialogsContainer: React.FC<DialogsContainerProps> = () => {
	return (
		<>
			<AccessControlDialog />

			<NotificationDialog />

			<DeleteFileDialog />

			<FailedUploadDialog />

			<FilePreviewDialog />

			<DeleteUserDialog />

			<ViewingStatsDialog />
		</>
	);
};

export default DialogsContainer;
