import { Box } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { ICompany } from "../../resources/Contracts";
import Texts from "../../resources/Texts";
import { Service } from "../../services/Service";
import { ApplicationState } from "../../store";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import { handleErrorMessage } from "../../utils/Utils";
import Multiselect from "../shared/Multiselect";
import * as DocumentUploadStore from "./DocumentUploadStore";

type AccessControlCompanyProps = DocumentUploadStore.DocumentUploadState &
	typeof DocumentUploadStore.actionCreators &
	typeof AlertStoreActionCreators & {};

const AccessControlCompany: React.FC<AccessControlCompanyProps> = ({
	selectedCompanies,
	setSelectedCompanies,
	setPermittedUsers,
	sectionId,
	addErrorAlert,
}) => {
	const [searchValue, setSearchValue] = React.useState<string>("");

	const loadCompanies = async (): Promise<ICompany[]> => {
		try {
			if (searchValue.length > 0) {
				return await Service.getCompanies(searchValue);
			} else {
				return [];
			}
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const { isLoading, data } = useQuery(["uploadDocumentCompanyData", searchValue], loadCompanies);

	const handleCompanyChange = (value: ICompany[]): void => {
		setPermittedUsers([]);

		function getDifference<T>(a: T[], b: T[]): T[] {
			return a.filter((element) => {
				return !b.includes(element);
			});
		}
		const diff = getDifference(value, selectedCompanies);

		if (diff.length === 0) {
			setSelectedCompanies(value);
		} else {
			setSelectedCompanies(diff);
		}
	};

	const renderCompanyValue = (companies: ICompany[]): React.ReactNode => {
		return companies?.length === 1 ? (
			<Box>{companies[0].companyName}</Box>
		) : (
			<Box>{Texts.UploadView.Fields.CompanyPlaceholder}</Box>
		);
	};

	return (
		<Box sx={{ height: "50px" }}>
			<Multiselect
				items={data}
				multiple={false}
				selectedItems={selectedCompanies}
				textField="companyName"
				valueField="id"
				onChange={handleCompanyChange}
				onSearchChange={setSearchValue}
				showFilterInput
				hideCloseButton
				showLoadingIndicator={isLoading}
				renderValue={renderCompanyValue}
				sx={{ width: "340px", mt: "4px" }}
				disabled={sectionId !== 1}
			/>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return {
		...state.addUser,
		...state.documentUpload,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...DocumentUploadStore.actionCreators,
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessControlCompany);
