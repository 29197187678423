import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: "#151724",
		boxShadow: theme.shadows[1],
		fontSize: "14px",
		lineHeight: "20px",
		fontWeight: "400",
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.white,
	},
}));
