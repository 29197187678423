import {
	CircularProgress,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
} from "@mui/material";
import { Box, SxProps } from "@mui/system";
import React from "react";
import { ReactComponent as SearchSvg } from "../../images/icons/Search.svg";
import Texts from "../../resources/Texts";
import Checkbox from "./Checkbox";

const ITEM_HEIGHT: number = 48;
const ITEM_PADDING_TOP: number = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
			width: 230,
		},
	},
};

type DropdownProps = {
	selectedItem: any;
	items: any[];
	textField: string;
	valueField: string;
	showLoadingIndicator?: boolean;
	showFilterInput?: boolean;
	placeholder?: string;
	sx?: SxProps;
	label?: string;
	disabled?: boolean;
	renderValue?: (value: any[]) => React.ReactNode;
	onChange: (value: any) => void;
	onSearchChange?: (value: string) => void;
	renderTextField?: (value: any) => React.ReactNode;
};

const Dropdown: React.FC<DropdownProps> = ({
	items,
	selectedItem,
	textField,
	valueField,
	showFilterInput,
	showLoadingIndicator,
	placeholder,
	sx,
	label,
	disabled,
	renderValue,
	onChange,
	onSearchChange,
	renderTextField,
}) => {
	const [filteredItems, setFilteredItems] = React.useState<any[]>(items);
	const [searchValue, setSearchValue] = React.useState<string>("");

	React.useEffect(() => {
		setFilteredItems(items);
	}, [items]);

	const handleSelect = (vf: any): void => {
		const newValue = items.find((i) => i[valueField] === vf);

		onChange(newValue);
	};

	const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const newValue: string = event.target.value;

		if (onSearchChange) {
			onSearchChange(newValue);
			setSearchValue(newValue);
		} else {
			if (newValue) {
				setFilteredItems(
					items.filter((item) =>
						(item[textField] as string).toLowerCase().startsWith(newValue.toLowerCase())
					)
				);
			} else {
				setFilteredItems(items);
			}

			setSearchValue(newValue);
			onSearchChange?.(newValue);
		}
	};

	return (
		<FormControl fullWidth>
			{label && <InputLabel id={`${label}-id`}>{label}</InputLabel>}
			<Select
				labelId={label && `${label}-id`}
				size="small"
				value={selectedItem || {}}
				MenuProps={{ ...MenuProps, disableAutoFocusItem: true }}
				label={label}
				displayEmpty
				disabled={disabled}
				renderValue={(value) =>
					renderValue?.(value) || (
						<Box
							component="span"
							sx={{
								color: selectedItem ? "#ffffff" : "#9AA0AF",
							}}
						>
							{selectedItem ? selectedItem[textField] : placeholder}
						</Box>
					)
				}
				sx={{
					width: "230px",
					".MuiSelect-icon": {
						color: "#ffffff",
					},
					"& .Mui-disabled": {
						color: "#9AA0AF",
						WebkitTextFillColor: "#9AA0AF",
						borderColor: "#9AA0AF",
					},
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: disabled && "#9AA0AF !important",
					},
					...sx,
				}}
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					sx={{
						padding: "10px",
					}}
				>
					{showFilterInput && (
						<OutlinedInput
							value={searchValue}
							size="small"
							label="Name"
							onChange={handleSearchInputChange}
							onKeyDown={(e) => {
								e.stopPropagation();
							}}
							placeholder={Texts.DocumentsTable.SearchFieldLabel}
							startAdornment={
								<InputAdornment position="start">
									<IconButton edge="start">
										<SearchSvg />
									</IconButton>
								</InputAdornment>
							}
							endAdornment={
								showLoadingIndicator && (
									<InputAdornment position="end">
										<Box
											sx={{
												marginLeft: "12px",
												color: "#ffffff",
											}}
										>
											<CircularProgress size={15} color="inherit" />
										</Box>
									</InputAdornment>
								)
							}
							notched={false}
							sx={{
								color: "#ffffff",
								width: "100%",
							}}
						/>
					)}

					{filteredItems?.map((item) => (
						<MenuItem
							disableRipple
							key={item[valueField]}
							value={item[valueField]}
							sx={{
								width: "100%",
								height: "30px",
							}}
							onClick={() => {
								handleSelect(item[valueField]);
							}}
						>
							<Checkbox checked={item === selectedItem} />
							<ListItemText
								primary={renderTextField ? renderTextField(item) : item[textField]}
							/>
						</MenuItem>
					))}
				</Box>
			</Select>
		</FormControl>
	);
};

export default Dropdown;
