import {
	Box,
	CircularProgress,
	SxProps,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import * as DateFunctions from "date-fns";
import * as React from "react";
import { useQuery } from "react-query";
import { IViewingStatsItem } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";

const tableCellStyle: SxProps = {
	fontWeight: "normal",
	paddingLeft: 0,
	paddingTop: "12px",
	paddingBottom: "12px",
	fontSize: "14px",
};

type ViewingStatsTableProps = { fileId: number };

const ViewingStatsTable: React.FC<ViewingStatsTableProps> = ({ fileId }) => {
	const loadDocuments = async (): Promise<IViewingStatsItem[]> => {
		if (!fileId) {
			return [];
		}

		return await Service.getViewingStats(fileId);
	};
	const { isLoading, data } = useQuery(["viewingStatsData", fileId], loadDocuments);

	if (isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				flex={1}
				sx={{
					color: "#ffffff",
				}}
			>
				<CircularProgress color="inherit" />
			</Box>
		);
	}

	return (
		<Box
			sx={{
				backgroundColor: "#25293D",
				borderRadius: "8px",
				padding: "12px 16px",
			}}
		>
			<TableContainer>
				<Table sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow
							sx={{
								"& td, & th": {
									borderColor: "#576079",
								},
							}}
						>
							<TableCell sx={tableCellStyle}>
								{Texts.Dialogs.ViewingStatsDialog.Table.ColumnTitles.AccessedBy}
							</TableCell>
							<TableCell sx={tableCellStyle}>
								{Texts.Dialogs.ViewingStatsDialog.Table.ColumnTitles.DateTime}
							</TableCell>
							<TableCell sx={tableCellStyle}>
								{Texts.Dialogs.ViewingStatsDialog.Table.ColumnTitles.Actions}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.map((row) => (
							<TableRow
								key={row.email}
								sx={{
									"& td, & th": {
										borderColor: "#576079",
									},
									"&:last-child td, &:last-child th": { border: 0 },
								}}
							>
								<TableCell sx={tableCellStyle}>{row.email}</TableCell>
								<TableCell sx={tableCellStyle}>
									{row.date &&
										DateFunctions.format(
											new Date(row.date),
											"d MMM, hh:mm:ss a"
										)}
								</TableCell>
								<TableCell sx={tableCellStyle}>{row.action}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default ViewingStatsTable;
