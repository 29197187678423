import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { ReactComponent as FileUploadSvg } from "../../images/icons/FileUpload.svg";
import { ReactComponent as TextFileSvg } from "../../images/icons/TextFile.svg";
import Texts from "../../resources/Texts";
import { ApplicationState } from "../../store";
import * as DocumentUploadStore from "./DocumentUploadStore";

type FileUploadProps = DocumentUploadStore.DocumentUploadState &
	typeof DocumentUploadStore.actionCreators;

const FileUpload: React.FC<FileUploadProps> = ({ file, setFile }) => {
	const onDrop = (acceptedFiles: Array<any>): void => {
		acceptedFiles.forEach((file) => {
			setFile(file);
		});
	};

	const { getRootProps, getInputProps } = useDropzone({
		maxFiles: 1,
		multiple: false,
		onDrop: onDrop,
		accept: {
			"text/plain": [".txt"],
			"application/pdf": [".pdf"],
			"application/msword": [".doc"],
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
			"application/vnd.ms-excel": [".xls"],
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
			"application/vnd.ms-powerpoint": [".ppt"],
			"application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
			"video/mp4": [".mp4"],
		},
	});

	return (
		<Box
			display="flex"
			flexDirection="column"
			sx={{
				mt: "12px",
				minHeight: "294px",
				backgroundColor: "#25293D",
				borderRadius: "8px",
			}}
		>
			<Box
				display="flex"
				alignItems="center"
				sx={{
					height: "45px",
					borderBottom: "1px solid #334162",
					pl: "16px",
				}}
			>
				<Typography variant="h4">{Texts.UploadView.FileUploadTitle}</Typography>
			</Box>

			<Box
				display="flex"
				flexDirection="column"
				sx={{
					pt: "16px",
					pl: "16px",
				}}
			>
				<Typography variant="body1" sx={{ mb: "12px" }}>
					{Texts.UploadView.Fields.SelectFileLabel}
				</Typography>

				<Box
					{...getRootProps()}
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					sx={{
						width: "660px",
						maxWidth: "100%",
						height: "185px",
						cursor: "pointer",
						border: "1px dashed #C2C2C2",
						borderRadius: "4px",
					}}
				>
					<input {...getInputProps()} />

					<FileUploadSvg />
					<Typography variant="body1">
						{Texts.UploadView.Fields.FileUploadLabel}
					</Typography>

					<Typography variant="body1">
						{file && (
							<TextFileSvg
								style={{
									marginRight: "4px",
								}}
							/>
						)}
						{file && file.name}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.documentUpload;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(DocumentUploadStore.actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
