import "core-js/features/url-search-params";

export class Routing {
	public static Routes = {
		HomePage: "/",
		Admin: {
			Documents: "/Documents",
			UserManagement: "/UserManagement",
			Upload: "/Upload",
		},
		AccessDenied: "/AccessDenied",
	};

	/**
	 * Create full url with params, e.g. /Home?foo=bar
	 * @param base base of url e.g. /Home
	 * @param params object of params, where object key/value pair corresponds to query params
	 * @returns full url with params
	 */
	private static constructFullUrl(base: string, params?: any) {
		let result: string = base;
		let query: URLSearchParams;

		if (params) {
			query = new URLSearchParams();

			Object.keys(params).forEach((paramKey) => {
				query.append(paramKey, params[paramKey]);
			});

			result = `${result}?${query.toString()}`;
		}

		return result;
	}

	public static getHomeViewUrl(): string {
		return Routing.constructFullUrl(Routing.Routes.HomePage);
	}

	public static getUploadViewUrl(): string {
		return Routing.constructFullUrl(Routing.Routes.Admin.Upload);
	}
}
