import { Box, Typography } from "@mui/material";
import * as React from "react";
import { ReactComponent as WarningTriangleSvg } from "../../../images/icons/WarningTriangle.svg";
import Texts from "../../../resources/Texts";

type DeleteUserDialogContentProps = { userName: string };

const DeleteUserDialogContent: React.FC<DeleteUserDialogContentProps> = ({ userName }) => {
	return (
		<Box display="flex" flexDirection="row">
			<Box width="20px" marginRight="1em">
				<WarningTriangleSvg />
			</Box>

			<Box width="428px">
				<Typography sx={{ marginBottom: "1em" }}>
					{Texts.Dialogs.UserDeleteDialog.WarningContent1}
					<Box component="span" sx={{ fontWeight: 600 }}>
						{userName}
					</Box>
					{Texts.Dialogs.UserDeleteDialog.WarningContent2}
				</Typography>

				<Typography>{Texts.Dialogs.UserDeleteDialog.DeleteConfirmation}</Typography>
			</Box>
		</Box>
	);
};

export default DeleteUserDialogContent;
