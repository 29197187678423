import { createTheme } from "@mui/material";
import { TypographyOptions, TypographyStyleOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		tableItem: true;
	}
}

interface ITypographyOptions extends TypographyOptions {
	tableItem: TypographyStyleOptions;
}

export const theme = createTheme({
	palette: {
		text: {
			primary: "#ffffff",
			secondary: "#151724",
		},
	},
	typography: {
		allVariants: {
			color: "#ffffff",
		},
		h1: {
			fontSize: "28px",
			lineHeight: "30px",
			fontWeight: "600",
			fontStyle: "normal",
		},
		h2: {
			fontSize: "22px",
			lineHeight: "28px",
			fontWeight: "600",
			fontStyle: "normal",
		},
		h3: {
			fontSize: "22px",
			fontWeight: "400",
			lineHeight: "28px",
			color: "#ffffff",
		},
		h4: {
			fontSize: "17px",
			fontWeight: "600",
			lineHeight: "22px",
			color: "#ffffff",
		},
		body1: {
			fontSize: "14px",
			lineHeight: "20px",
			fontWeight: "400",
		},
		body2: {
			fontSize: "12px",
			lineHeight: "15px",
			fontWeight: "600",
		},
		fontFamily: "Source Sans Pro",
		tableItem: {
			color: "#ffffff",
			fontSize: "14px",
			lineHeight: "20px",
			fontWeight: "400",
			fontFamily: "Source Sans Pro",
		},
	} as ITypographyOptions,
	components: {
		MuiDrawer: {
			styleOverrides: {
				paper: {
					backgroundColor: "#25293d",
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: "#334162",
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				list: {
					backgroundColor: "#151724",
					borderColor: "#576079",
					borderWidth: "1px",
					borderStyle: "solid",
					borderRadius: "4px",
					paddingTop: 0,
					paddingBottom: 0,
				},
				paper: {
					backgroundColor: "transparent !important",
					border: "none",
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					width: "200px",
					fieldset: {
						borderColor: "#9AA0AF",
					},
				},
			},
		},
	},
});
