import { Box } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { IAccessControlUser, IUploader } from "../../resources/Contracts";
import Texts from "../../resources/Texts";
import { Service } from "../../services/Service";
import { ApplicationState } from "../../store";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import { handleErrorMessage } from "../../utils/Utils";
import Multiselect from "../shared/Multiselect";
import * as DocumentUploadStore from "./DocumentUploadStore";

type AccessControlUserProps = DocumentUploadStore.DocumentUploadState &
	typeof DocumentUploadStore.actionCreators &
	typeof AlertStoreActionCreators;

const AccessControlUser: React.FC<AccessControlUserProps> = ({
	selectedCompanies,
	permittedUsers,
	setPermittedUsers,
	sectionId,
	addErrorAlert,
}) => {
	const [searchValue, setSearchValue] = React.useState<string>("");

	const loadUsers = async (): Promise<IAccessControlUser[]> => {
		try {
			if (searchValue.length > 0) {
				let users = await Service.getUsersByCompanyId(searchValue, selectedCompanies[0].id);

				return users.map((user) => {
					return {
						email: user.mail,
						displayName: `${user.givenName} ${user.surname} (${user.mail})`,
						firstName: user.givenName,
						lastName: user.surname,
					};
				});
			} else {
				return [];
			}
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const { isLoading, data } = useQuery(["uploadDocumentUserData", searchValue], loadUsers);

	const handleUserChange = (value: IAccessControlUser[]): void => {
		setPermittedUsers(value);
	};

	const renderUserValue = (users: IAccessControlUser[]): React.ReactNode => {
		return users.length === 0 ?
			(
				<Box>
					{Texts.UploadView.Fields.UserPlaceHolder}
				</Box>
			) : users.length === 1 ?
				(
					<Box>
						{users[0].displayName}
					</Box>
				) : (
					<Box>
						Selected {users.length} viewers
					</Box>
				);
	};

	return (
		<Box sx={{ height: "50px" }}>
			<Multiselect
				items={data}
				selectedItems={permittedUsers}
				textField="displayName"
				valueField="email"
				onChange={handleUserChange}
				onSearchChange={setSearchValue}
				showLoadingIndicator={isLoading}
				hideCloseButton
				showFilterInput
				renderValue={renderUserValue}
				sx={{ width: "340px" }}
				disabled={sectionId !== 1 || selectedCompanies.length === 0}
			/>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return {
		...state.addUser,
		...state.documentUpload
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...DocumentUploadStore.actionCreators,
			...AlertStoreActionCreators
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessControlUser);
