import { Box } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import TextSeparator from "../../shared/TextSeparator";
import DialogBase from "../DialogBase";
import * as DialogStore from "../DialogStore";
import AccessControlAddInput from "./AccessControlAddInput";
import AccessControlTable from "./AccessControlTable";
import Texts from "../../../resources/Texts";

type AccessControlDialogProps = DialogStore.DialogState & typeof DialogStore.actionCreators & {};

const AccessControlDialog: React.FC<AccessControlDialogProps> = ({
	accessControlDialog,
	setAccessDialogState,
}) => {
	const handleClose = (): void => {
		setAccessDialogState(null);
	};

	if (!accessControlDialog) {
		return null;
	}

	return (
		<DialogBase
			open={accessControlDialog.open}
			dialogTitle={
				<>
					<Box
						component="span"
						sx={{
							fontWeight: "600",
						}}
					>
						{accessControlDialog.title}
					</Box>
					<TextSeparator />
					{Texts.Dialogs.AccessControl.Title}
				</>
			}
			onCancel={handleClose}
		>
			<AccessControlTable fileId={accessControlDialog.fileId} />

			<AccessControlAddInput />
		</DialogBase>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.dialogs;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...DialogStore.actionCreators,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessControlDialog);
