import { Box, CircularProgress } from "@mui/material";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { IUserManagementDataRequest, IUserManagementResponse } from "../../../resources/Contracts";
import { Service } from "../../../services/Service";
import { ApplicationState } from "../../../store";
import * as UserManagementStore from "../UserManagementStore";
import * as DialogStore from "../../dialogs/DialogStore";
import UserManagementTableRow from "./UserManagementTableRow";

type UserManagementTableRowsProps = UserManagementStore.UserManagementState &
	DialogStore.DialogState &
	typeof UserManagementStore.actionCreators & {};

const UserManagementTableRows: React.FC<UserManagementTableRowsProps> = ({
	searchText,
	sortBy,
	sortDir,
	rowsPerPage,
	selectedPage,
	setUsersCount,
	setUserItems,
	addUserDialog
}) => {
	React.useEffect(() => {
		if (addUserDialog === null)
			loadUsers();

	}, [addUserDialog]);

	const loadUsers = async () => {

		if (!rowsPerPage || !selectedPage) {
			return [];
		}

		const request: IUserManagementDataRequest = {
			itemsPerPage: rowsPerPage,
			pageNumber: selectedPage,
			orderBy: sortBy,
			order: sortDir || "ASC",
			searchString: searchText?.length ? searchText : null,
		};

		const response: IUserManagementResponse = await Service.getUserManagementData(request);

		setUsersCount(response.count);
		setUserItems(response.items);

		return response.items;
	};

	const { isLoading, data, isFetching } = useQuery(
		["userManagementData", searchText, sortBy, sortDir, rowsPerPage, selectedPage],
		loadUsers
	);

	if (isLoading || isFetching) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				flex={1}
				sx={{
					color: "#ffffff",
				}}
			>
				<CircularProgress color="inherit" />
			</Box>
		);
	}

	return (
		<Box
			display="block"
			sx={{
				overflowY: "auto",
				height: "calc(100% - 38px)",
			}}
		>
			<Box display="flex" flexDirection="column" flex={1}>
				{data?.map((d, index) => (
					<UserManagementTableRow key={`userRow-${index}`} data={d} />
				))}
			</Box>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return {
		...state.userManagement,
		...state.dialogs
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		...UserManagementStore.actionCreators,
		...DialogStore.actionCreators
	}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementTableRows);
