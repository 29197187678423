import { InputAdornment, OutlinedInput } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ReactComponent as SearchSvg } from "../../../images/icons/Search.svg";
import Texts from "../../../resources/Texts";
import { ApplicationState } from "../../../store";
import * as DocumentsStore from "../documentsTable/DocumentsStore";

type DocumentsTableTitleProps = DocumentsStore.DocumentsState &
	typeof DocumentsStore.actionCreators;

const DocumentsTableTitle: React.FC<DocumentsTableTitleProps> = ({
	searchText,
	setSearchTextValue,
}) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setSearchTextValue(event.target.value);
	};

	return (
		<OutlinedInput
			value={searchText}
			onChange={handleChange}
			label={Texts.DocumentsTable.SearchFieldLabel}
			placeholder={Texts.DocumentsTable.SearchFieldLabel}
			size="small"
			notched={false}
			endAdornment={
				<InputAdornment position="end">
					<IconButton edge="end">
						<SearchSvg />
					</IconButton>
				</InputAdornment>
			}
			sx={{
				marginRight: "12px",
			}}
		/>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.documents;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(DocumentsStore.actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTableTitle);
