import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { INavSection } from "../../resources/Contracts";
import Texts from "../../resources/Texts";
import { Service } from "../../services/Service";
import { ApplicationState } from "../../store";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import { handleErrorMessage } from "../../utils/Utils";
import Dropdown from "../shared/Dropdown";
import AccessControlCompany from "./AccessControlCompany";
import AccessControlUser from "./AccessControlUser";
import * as DocumentUploadStore from "./DocumentUploadStore";

type AccessControlProps = DocumentUploadStore.DocumentUploadState &
	typeof DocumentUploadStore.actionCreators &
	typeof AlertStoreActionCreators & {};

const AccessControl: React.FC<AccessControlProps> = ({
	sectionId,
	setPermittedUsers,
	setSectionId,
	addErrorAlert,
}) => {
	const [accessControlValue, setAccessControlValue] = React.useState<number>(1);

	const loadNavItems = async (): Promise<INavSection[]> => {
		try {
			const result: INavSection[] = await Service.getNavSections();

			return result;
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const sectionsQuery = useQuery("uploadSectionsQuery", loadNavItems);

	const handleRadioButtonChange = (event, value: string): void => {
		const valueAsNumber: number = Number(value);

		setAccessControlValue(valueAsNumber);

		if (valueAsNumber === 1) {
			setSectionId(null);
			setPermittedUsers([]);
		} else {
			setSectionId(sectionsQuery.data?.find((s) => !s.public)?.id);
		}
	};

	const handleSectionChange = (value: INavSection): void => {
		setSectionId(value?.id);
	};

	const renderSectionValue = (id: number): React.ReactNode => {
		return id === null || id === 1 ? (
			<Box>{Texts.UploadView.Fields.SectionPlaceholder}</Box>
		) : (
			<Box>{sectionsQuery.data?.find((s) => s.id === id).sectionName}</Box>
		);
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			sx={{
				mt: "12px",
				minHeight: "177px",
				backgroundColor: "#25293D",
				borderRadius: "8px",
			}}
		>
			<Box
				display="flex"
				alignItems="center"
				sx={{
					height: "45px",
					borderBottom: "1px solid #334162",
					pl: "16px",
				}}
			>
				<Typography variant="h4">{Texts.UploadView.AccessControlTitle}</Typography>
			</Box>

			<Box
				display="flex"
				flex={1}
				sx={{
					mt: "12px",
					pl: "16px",
				}}
			>
				<Box display="flex" flexDirection="column" sx={{ width: "200px" }}>
					<RadioGroup value={accessControlValue} onChange={handleRadioButtonChange}>
						<FormControlLabel
							value={1}
							control={
								<Radio
									sx={{
										color: "#ffffff",
										"&.Mui-checked": {
											color: "#06A3ED",
										},
									}}
								/>
							}
							label={Texts.UploadView.Fields.RadioButtonGeneral}
							sx={{
								height: "50px",
							}}
						/>
						<FormControlLabel
							value={2}
							control={
								<Radio
									sx={{
										color: "#ffffff",
										"&.Mui-checked": {
											color: "#06A3ED",
										},
									}}
								/>
							}
							label={Texts.UploadView.Fields.RadioButtonPermittedViewers}
							sx={{
								height: "50px",
							}}
						/>
					</RadioGroup>
				</Box>

				<Box display="flex" flexDirection="column">
					<Box sx={{ height: "50px", mt: "10px" }}>
						<Dropdown
							items={sectionsQuery.data?.filter((s) => s.public)}
							selectedItem={sectionsQuery.data?.find((s) => s.id === sectionId)}
							textField="sectionName"
							valueField="id"
							showLoadingIndicator={sectionsQuery.isLoading}
							sx={{ width: "340px" }}
							renderValue={() => renderSectionValue(sectionId)}
							onChange={handleSectionChange}
							disabled={accessControlValue === 2}
						/>
					</Box>

					<AccessControlCompany />

					<AccessControlUser />
				</Box>
			</Box>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.documentUpload;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ ...DocumentUploadStore.actionCreators, ...AlertStoreActionCreators },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessControl);
