import { Menu } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { ReactComponent as NotificationBellSvg } from "../../../images/icons/NotificationBell.svg";
import { ApplicationState } from "../../../store";
import { UserState } from "../../../store/UserStore";
import NotificationList from "./NotificationList";

type AppBarNotificationsProps = UserState & {};

const AppBarNotifications: React.FC<AppBarNotificationsProps> = ({ user }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open: boolean = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
	};

	if (!user.isAdmin) {
		return null;
	}

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				sx={{
					width: "24px",
					height: "24px",
					marginRight: "12px",
					cursor: "pointer",
				}}
				onClick={handleClick}
			>
				<NotificationBellSvg />
			</Box>

			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				sx={{
					"& .MuiPaper-root": {
						width: "540px",
						height: "340px",
						borderTop: "8px solid #06A3ED",
						backgroundColor: "#151724 !important",
					},
					"& .MuiMenu-list": {
						border: 0,
						height: "100%",
					},
				}}
			>
				<NotificationList />
			</Menu>
		</>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return { ...state.userState };
};

export default connect(mapStateToProps)(AppBarNotifications);
