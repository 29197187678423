import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import Texts from "../../resources/Texts";
import { ApplicationState } from "../../store";
import * as DocumentUploadStore from "./DocumentUploadStore";

type DocumentTitleAndKeywordsProps = DocumentUploadStore.DocumentUploadState &
	typeof DocumentUploadStore.actionCreators;

const DocumentTitleAndKeywords: React.FC<DocumentTitleAndKeywordsProps> = ({
	documentName,
	keywords,
	setDocumentKeywords,
	setDocumentName,
}) => {
	const handleNameChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	): void => {
		setDocumentName(event.target.value);
	};

	const handleKeywordsChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	): void => {
		setDocumentKeywords(event.target.value);
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			sx={{
				minHeight: "152px",
				backgroundColor: "#25293D",
				borderRadius: "8px",
			}}
		>
			<Box
				display="flex"
				alignItems="center"
				sx={{
					height: "45px",
					borderBottom: "1px solid #334162",
					pl: "16px",
				}}
			>
				<Typography variant="h4">{Texts.UploadView.UploadDocumentTitle}</Typography>
			</Box>

			<Box
				display="flex"
				alignItems="center"
				flex={1}
				sx={{
					mt: "12px",
					pl: "16px",
					"& .MuiInputBase-root": {
						width: "100%",
					},
				}}
			>
				<Box
					display="flex"
					sx={{
						width: "360px",
						height: "60px",
						mr: "40px",
					}}
				>
					<TextField
						value={documentName || ""}
						size="small"
						label={Texts.UploadView.Fields.NameTitle}
						placeholder={Texts.UploadView.Fields.NamePlaceholder}
						InputLabelProps={{ shrink: true }}
						fullWidth
						onChange={handleNameChange}
					/>
				</Box>

				<Box
					display="flex"
					flexDirection="column"
					sx={{
						width: "360px",
						height: "60px",
					}}
				>
					<TextField
						value={keywords || ""}
						size="small"
						label={Texts.UploadView.Fields.KeywordsLabel}
						placeholder={Texts.UploadView.Fields.KeywordsPlaceholder}
						InputLabelProps={{ shrink: true }}
						fullWidth
						onChange={handleKeywordsChange}
					/>

					<Typography variant="body1">{Texts.UploadView.Fields.KeywordsHint}</Typography>
				</Box>
			</Box>
		</Box>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return state.documentUpload;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(DocumentUploadStore.actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTitleAndKeywords);
