import { Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import Texts from "../../../resources/Texts";
import { ApplicationState } from "../../../store";
import { AdminDocumentsState } from "./AdminDocumentsStore";

type AdminDocumentsTableTitleProps = AdminDocumentsState;

const AdminDocumentsTableTitle: React.FC<AdminDocumentsTableTitleProps> = ({
	documentsCount,
	selectedTabId,
}) => {
	let tabTitle: string = "";

	const selectedTabElement: HTMLElement = document.getElementById("companyTab-" + selectedTabId);

	if (selectedTabElement) {
		tabTitle = selectedTabElement.innerText?.split("(")[0].trimStart();
	}

	return (
		<Typography variant="h3">
			{`${tabTitle} ${Texts.DocumentsTable.Titles.Documents}`}

			{documentsCount ? ` (${documentsCount})` : null}
		</Typography>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return { ...state.adminDocuments };
};

export default connect(mapStateToProps)(AdminDocumentsTableTitle);
