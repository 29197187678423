import { Action, Reducer } from "redux";
import { IAccessControlUser, ICompany } from "../../resources/Contracts";

export interface DocumentUploadState {
	file: File;
	documentName: string;
	keywords: string;
	sectionId: number;
	selectedCompanies: ICompany[];
	permittedUsers: IAccessControlUser[];
}

interface SetDocumentNameStateAction {
	type: "SET_DOCUMENT_NAME_STATE";
	payload: string;
}

interface SetDocumentKeywordsStateAction {
	type: "SET_DOCUMENT_KEYWORDS_STATE";
	payload: string;
}

interface SetSectionIdStateAction {
	type: "SET_SECTION_ID_STATE";
	payload: number;
}

interface SetPermittedUsersStateAction {
	type: "SET_PERMITTED_USERS_STATE";
	payload: IAccessControlUser[];
}

interface SetFileStateAction {
	type: "SET_UPLOAD_FILE_STATE";
	payload: File;
}

interface SetSelectedCompaniesAction {
	type: "SET_SELECTED_COMPANIES_STATE";
	payload: ICompany[];
}

interface ClearDocumentStateAction {
	type: "CLEAR_DOCUMENT_STATE";
}

type KnownAction =
	| SetDocumentNameStateAction
	| SetDocumentKeywordsStateAction
	| SetSectionIdStateAction
	| SetPermittedUsersStateAction
	| SetFileStateAction
	| SetSelectedCompaniesAction
	| ClearDocumentStateAction;

export const actionCreators = {
	setDocumentName: (value: string) =>
		({
			type: "SET_DOCUMENT_NAME_STATE",
			payload: value,
		} as SetDocumentNameStateAction),
	setDocumentKeywords: (value: string) =>
		({
			type: "SET_DOCUMENT_KEYWORDS_STATE",
			payload: value,
		} as SetDocumentKeywordsStateAction),
	setSectionId: (value: number) =>
		({
			type: "SET_SECTION_ID_STATE",
			payload: value,
		} as SetSectionIdStateAction),
	setPermittedUsers: (value: IAccessControlUser[]) =>
		({
			type: "SET_PERMITTED_USERS_STATE",
			payload: value,
		} as SetPermittedUsersStateAction),
	setFile: (value: File) =>
		({
			type: "SET_UPLOAD_FILE_STATE",
			payload: value,
	} as SetFileStateAction),
	setSelectedCompanies: (value: ICompany[]) =>
	({
		type: "SET_SELECTED_COMPANIES_STATE",
		payload: value
    } as SetSelectedCompaniesAction),
	clearDocument: () =>
		({
			type: "CLEAR_DOCUMENT_STATE",
		} as ClearDocumentStateAction),
};

export const reducer: Reducer<DocumentUploadState> = (
	state: DocumentUploadState | undefined,
	incomingAction: Action
) => {
	if (state === undefined) {
		return {
			file: null,
			documentName: null,
			keywords: null,
			sectionId: null,
			selectedCompanies: [],
			permittedUsers: [],
		};
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "SET_DOCUMENT_NAME_STATE":
			return { ...state, documentName: action.payload };

		case "SET_DOCUMENT_KEYWORDS_STATE":
			return { ...state, keywords: action.payload };

		case "SET_SECTION_ID_STATE":
			return { ...state, sectionId: action.payload };

		case "SET_PERMITTED_USERS_STATE":
			return { ...state, permittedUsers: action.payload };

		case "SET_UPLOAD_FILE_STATE":
			return { ...state, file: action.payload };

		case "SET_SELECTED_COMPANIES_STATE":
			return { ...state, selectedCompanies: action.payload };

		case "CLEAR_DOCUMENT_STATE":
			return {
				file: null,
				documentName: null,
				keywords: null,
				sectionId: null,
				selectedCompanies: [],
				permittedUsers: [],
			};

		default:
			return state;
	}
};
